import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
export default function ExitLandingPage() {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    window.location.href = window.location.protocol + "//" + window.location.host
  };

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          You have left the session
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thank you for participating!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleHomeClick}
          sx={{ mt: 2 }}
        >
          Return to Home Page
        </Button>
      </Box>
    </Box>
  );
}