import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";

type SessionLocation = {
  latitude: number;
  longitude: number;
};

type Session = {
  sessionId: string;
  hostUserId: string;
  guests: string[];
  public: boolean;
  allowExplicit: boolean;
  allowedGenres: string[] | null;
  mode: string | null;
  url: string;
  startTime: string;
  location: SessionLocation | null; // Can be null
  hostNickname: string | null; // Host's nickname
  friendName: string | null; // Friend’s name
};

export default function JoinSessionByFriendsLocation() {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const fetchFriendsSessions = async () => {
    const userId = Cookies.get("userID");
    if (!userId) {
      setErrorMessage("User ID not found");
      return;
    }

    try {
      const response = await fetch(`/api/get-friends-sessions`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          userId: userId,
        },
      });

      if (response.ok) {
        const friendSessions = await response.json();
        setSessions(friendSessions);
      } else {
        setErrorMessage("Failed to fetch friends sessions");
      }
    } catch (error) {
      console.error("Error fetching friends sessions:", error);
      setErrorMessage("Error fetching friends sessions");
    }
  };

  const buttonStyle = {
    fontSize: "0.875rem",
    padding: "8px 16px",
    width: "100%",
    maxWidth: "300px",
    alignSelf: "center",
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={fetchFriendsSessions}
        sx={{ textTransform: "none", mb: 2, buttonStyle }}
      >
        Find Friends' Sessions
      </Button>

      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}

      {sessions.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Friends' Sessions
          </Typography>
          {sessions.map((session) => (
            <Card key={session.sessionId} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  <strong>Host Nickname:</strong>{" "}
                  {session.hostNickname || "Unknown"}
                </Typography>
                <Typography variant="body1">
                  <strong>Friend Name:</strong>{" "}
                  {session.friendName || "Unknown"}
                </Typography>
                <Typography variant="body1">
                  <strong>Session ID:</strong> {session.sessionId}
                </Typography>
                <Typography variant="body1">
                  <strong>Public:</strong> {session.public ? "Yes" : "No"}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/join?code=" + session.sessionId)}
                >
                  Go to Session
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}
