import React, { Dispatch, SetStateAction, useCallback, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { SpotifyApi } from '@spotify/web-api-ts-sdk';
import SpotifyPlayer, { SpotifyWebPlaybackErrorType } from 'spotify-web-playback';
import { useSearchParams } from 'react-router-dom';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import * as auth from '@tidal-music/auth';
import * as player from '@tidal-music/player';
import { CredentialsProvider } from '@tidal-music/common';
import * as catalogue from '@tidal-music/catalogue';
import { Client } from 'openapi-fetch';
import { NOT_PLAYING_SONG, PTASong } from '../PTASong';
import YouTubePlayer from 'youtube-player';
import { YouTubePlayer as YouTubePlayerType } from 'youtube-player/dist/types';
import PlayerStates from 'youtube-player/dist/constants/PlayerStates';
import Cookies from 'js-cookie';
import { NOT_PLAYING_STATE, PlaybackMode, updatePlaybackState } from '../PlaybackState';

const PTAYTPlayer = forwardRef<PTAPlayerHandle>(function PTAYTPlayer(props, ref) {
  const [iframe, setiframe] = useState<HTMLIFrameElement>();
  const [player, setPlayer] = useState<YouTubePlayerType | null>(null);
	const currentSongRef = useRef<PTASong>(NOT_PLAYING_SONG);
  useEffect(() => {
    console.warn(iframe);
  }, [iframe]);
	const sessionID = useRef<string | null>(null);

	useEffect(() => {
		const sessID = Cookies.get("sessionID");
		if (sessID !== undefined) sessionID.current = sessID;
	}, [document.cookie]);

	const playSong = useCallback(async (newSong: PTASong) => {
		// setiframe(<><iframe width="300" height="169" src={"https://www.youtube.com/embed/" + newSong.songId} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen={true}></iframe></>)
    if (player === null) {
			const plyr = YouTubePlayer('video-player');
			setPlayer(plyr);
			plyr.addEventListener("onStateChange", (state) => {
				if (sessionID.current !== null) {
					let pbmode: PlaybackMode = PlaybackMode.STOP;
					if (state.detail === -1) pbmode = PlaybackMode.STOP;
					else if (state.detail === 0) pbmode = PlaybackMode.STOP;
					else if (state.detail === 1) pbmode = PlaybackMode.PLAY;
					else if (state.detail === 2) pbmode = PlaybackMode.PAUSE;
					updatePlaybackState({
						sessionID: sessionID.current,
						state: {
							current: newSong,
							currentTimeMillis: 1,
							playing: pbmode
						}
					});
				}
			});
		}
    setiframe(await player?.getIframe());
    await player?.pauseVideo();
    await player?.loadVideoById(newSong.songId);
    await player?.playVideo();
    console.log("YouTube playing", newSong.songId);
		if (sessionID.current !== null) {
			updatePlaybackState({
				sessionID: sessionID.current,
				state: {
					current: newSong,
					currentTimeMillis: 1,
					playing: PlaybackMode.PLAY
				}
			})
		}
    // if (sessionID.current !== null) {
		// 	updatePlaybackState({
		// 		sessionID: sessionID.current,
		// 		state: NOT_PLAYING_STATE
		// 	}).then((val) => {
		// 		updatePlaybackState({
		// 			sessionID: sessionID.current!,
		// 			state: {
		// 				current: newSong,
		// 				playing: PlaybackMode.PLAY,
		// 				currentTimeMillis: 0
		// 			}
		// 		});
		// 	});
		// }
	}, [player, iframe]);
	const isPlaying = useCallback(async () => {
    const state = await player?.getPlayerState();
    return state === PlayerStates.PLAYING || state === PlayerStates.BUFFERING;
	}, [player]);
	const togglePlaying = useCallback(async () => {
		if (player !== null) {
			if (await player?.getPlayerState() === PlayerStates.PLAYING) {
				player?.pauseVideo();
				return false;
			} else {
				player?.playVideo();
				return true;
			}
		} else return false;
	}, [player]);
	const play = useCallback(async () => {
		player?.playVideo();
	}, [player]);
	const pause = useCallback(async () => {
		player?.pauseVideo();
	}, [player]);
	const seek = useCallback(async (pos: number) => {
		player?.seekTo(pos / 1000, true);
		return;
	}, [player]);
	const next = useCallback(async () => {
    /* impossible to implement */
		return;
	}, [player]);
	const previous = useCallback(async () => {
		/* impossible to implement */
    return;
	}, [player]);
	const getPosition = useCallback(async () => {
    const pl = await player?.getCurrentTime();
		return (pl === undefined ? 0 : pl) * 1000;
	}, [player]);
	const getCurrentSong = useCallback(() => {
		return currentSongRef.current;
	}, []);
	const setVolume = useCallback(async (level: number) => {
		if (volume.current !== level) {
			volume.current = level;
			player?.setVolume(level);
		}
	}, [player]);
	const volume = useRef(100.0);
	const getVolume = useCallback(() => {
		return volume.current;
	}, []);

	useImperativeHandle(ref, () => {
		return {
			playSong,
			isPlaying,
			togglePlaying,
			play,
			pause,
			seek,
			next,
			previous,
			getPosition,
			getCurrentSong,
			setVolume,
			getVolume
		};
	});

	return <div id='video-player'></div>;
});

export default PTAYTPlayer;
