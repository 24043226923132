import React, { Dispatch, forwardRef, SetStateAction, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { PlaybackMode, PlaybackState, updatePlaybackState } from '../PlaybackState';
import { NOT_PLAYING_SONG, PTASong } from '../PTASong';

const convertMKPBStoPTAPBM = (playbackState: MusicKit.PlaybackStates) => {
	switch (playbackState) {
		case MusicKit.PlaybackStates.ended:
		case MusicKit.PlaybackStates.stopped:
		case MusicKit.PlaybackStates.completed:
			return PlaybackMode.STOP;
		case MusicKit.PlaybackStates.paused:
			return PlaybackMode.PAUSE;
		default:
			return PlaybackMode.PLAY;
	}
}

const PTAAppleMusicPlayer = forwardRef<PTAPlayerHandle>(function PTAAppleMusicPlayer(props, ref) {
	const [music, setMusic]: [MusicKit.MusicKitInstance | null, Dispatch<SetStateAction<MusicKit.MusicKitInstance | null>>] = useState<MusicKit.MusicKitInstance | null>(MusicKit.getInstance());
	const navigate = useNavigate();
	const currentSongRef = useRef<PTASong>(NOT_PLAYING_SONG);
	const [sessionID, setSessionID] = useState<string | null>(null);
	useEffect(() => {
		const sessID = Cookies.get("sessionID");
		if (sessID !== undefined) setSessionID(sessID);
	}, [document.cookie]);
	useEffect(() => {
		if (music !== null) {
			console.log("Attempting");
			music.authorize().then((musicUserToken: string) => {
				console.log("Got token: " + musicUserToken);
				const str = import.meta.env.VITE_API_BASE_URL + "/api/amauth?mut=" + encodeURIComponent(musicUserToken)
				console.warn(str);
				fetch(str).then((res) => {
					console.log(res);
					if (Cookies.get("mode") !== "apple") {
						Cookies.set("mode", "apple");
						navigate(window.location.pathname);
					}
				}, (reason) => {
					console.error(reason);
				});
			}).catch((error: string) => {
				console.error("Authorization error: " + error);
			}).finally(() => {
				console.warn("Done");
			});
		} else {
			console.warn("music is null");
		}
	}, [music]);
	const updateAppleMusicPlaybackState = useCallback((amstate: MusicKit.PlaybackStates) => {
		const sessionID = Cookies.get("sessionID");
		if (sessionID !== undefined && music !== null) {
			const ptastate = convertMKPBStoPTAPBM(amstate);
			if (ptastate === PlaybackMode.STOP && currentSongRef.current === NOT_PLAYING_SONG) {
				// console.log("Do not need to repeat");
			} else {
				if (amstate !== MusicKit.PlaybackStates.seeking) {
					const pbState = {
						sessionID: sessionID,
						state: {
							currentTimeMillis: ptastate === PlaybackMode.STOP ? 0 : music.currentPlaybackTime * 1000,
							playing: ptastate,
							current: ptastate === PlaybackMode.STOP ? NOT_PLAYING_SONG : currentSongRef.current
						}
					};
					// console.log("Updating state", amstate, ptastate);
					if (ptastate === PlaybackMode.STOP) {
						currentSongRef.current = NOT_PLAYING_SONG;
						// console.log("Setting currentSongRef");
					}
					updatePlaybackState(pbState);
				}
			}
			// console.warn(pbState);
		}
	}, [music]);
	const updateAppleMusicPlaybackTime = useCallback(() => {
		const sessionID = Cookies.get("sessionID");
		if (sessionID !== undefined && music !== null) {
			const ptastate = convertMKPBStoPTAPBM(music.playbackState);
			if (ptastate !== PlaybackMode.STOP && !(music.currentPlaybackTime === 0 && music.playbackState === MusicKit.PlaybackStates.seeking)) {
				const pbState = {
					sessionID: sessionID,
					state: {
						currentTimeMillis: music.currentPlaybackTime * 1000,
						playing: ptastate,
						current: currentSongRef.current
					}
				};
				// console.log("Updating time", music.currentPlaybackTime, music.playbackState);
				updatePlaybackState(pbState);
			}
		} else {
			console.error("Error during Apple Music playback time change listener: no sessionID cookie");
		}
	}, [music]);
	useEffect(() => {
    if (music !== null) {
        const handlePlaybackStateChange = (event: any) => {
            updateAppleMusicPlaybackState(event.state);
        };

        const handlePlaybackTimeChange = () => {
            updateAppleMusicPlaybackTime();
        };

        // Attach the event listeners with the latest callbacks
        music.addEventListener("playbackStateDidChange", handlePlaybackStateChange);
        music.addEventListener("playbackTimeDidChange", handlePlaybackTimeChange);

        // Cleanup: remove listeners when `currentSong` changes or the component unmounts
        return () => {
						// @ts-expect-error
            music.removeEventListener("playbackStateDidChange", handlePlaybackStateChange);
            music.removeEventListener("playbackTimeDidChange", handlePlaybackTimeChange);
        };
    }
	}, [music, updateAppleMusicPlaybackState, updateAppleMusicPlaybackTime]);

	const playSong = useCallback(async (newSong: PTASong) => {
		if (sessionID !== null) {
			updatePlaybackState({
				state: {
					currentTimeMillis: 0,
					playing: PlaybackMode.PLAY,
					current: newSong
				},
				sessionID
			});
		}
		music?.setQueue({ song: newSong.songId }).then(() => {
			currentSongRef.current = newSong;
			console.warn("Updated current song");
			console.warn(newSong);
			music.play();
		});
		return;
	}, [music, sessionID]);
	const isPlaying = useCallback(async () => {
		if (music !== null) {
			return music.isPlaying;
		} else return false;
	}, [music]);
	const togglePlaying = useCallback(async () => {
		if (music !== null) {
			if (music.isPlaying) {
				music.pause();
				return false;
			} else {
				music.play();
				return true;
			}
		} else return false;
	}, [music]);
	const play = useCallback(async () => {
		music?.play();
	}, [music]);
	const pause = useCallback(async () => {
		music?.pause();
	}, [music]);
	const seek = useCallback(async (position: number) => {
		music?.seekToTime(position);
	}, [music]);
	const next = useCallback(async () => {
		music?.skipToNextItem();
	}, [music]);
	const previous = useCallback(async () => {
		music?.skipToPreviousItem();
	}, [music]);
	const getPosition = useCallback(async () => {
		if (music === undefined) console.error("3 music is undefined");
		if (music?.currentPlaybackTime === undefined) console.error("3 cbt is undefined");
		if (music !== null) return music?.currentPlaybackTime * 1000;
		else return -1;
	}, [music]);
	const getCurrentSong = useCallback(() => {
		return currentSongRef.current;
	}, []);
	const setVolume = useCallback(async (level: number) => {
		if (music && volume.current !== (level / 100.0)) {
			volume.current = level / 100.0;
			music.volume = level / 100.0;
		}
	}, []);
	const volume = useRef(100.0);
	const getVolume = useCallback(() => {
		return volume.current;
	}, []);

	useImperativeHandle(ref, () => {
		return {
			playSong,
			isPlaying,
			togglePlaying,
			play,
			pause,
			seek,
			next,
			previous,
			getPosition,
			getCurrentSong,
			setVolume,
			getVolume
		};
	});

	return <></>;
});

export default PTAAppleMusicPlayer;
