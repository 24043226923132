import React from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";

type LeaderboardProps = {
  data: { songsPlayed: number; name: string; rank: number }[];
};

const Leaderboard: React.FC<LeaderboardProps> = ({ data }) => {
  const getRankBackground = (rank: number) => {
    switch (rank) {
      case 1:
        return "#FFD700";
      case 2:
        return "#C0C0C0";
      case 3:
        return "#CD7F32";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: 4 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Leaderboard
          </Typography>
          <List>
            {data.map((user, index) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: getRankBackground(user.rank),
                  marginBottom: 1,
                  borderRadius: 1,
                }}
              >
                <ListItemText
                  primary={`${user.rank}. ${user.name}`}
                  secondary={`${user.songsPlayed} song(s) played`}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Leaderboard;
