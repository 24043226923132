import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import Signup from "./pages/Signup";
import AccountSettings from "./pages/AccountSettings";
import Confirmation from "./pages/Confirmation";
import Session from "./pages/Session";
import SpotifyErrorPage from "./pages/SpotifyErrorPage";
import SpotifySuccessPage from "./pages/SpotifySuccessPage";
import CreateSession from "./pages/CreateSession";
import SearchTestPage from "./pages/SearchTestPage";
import RecommendationsTestPage from "./pages/RecommendationsTestPage";
import JoinSession from "./pages/JoinSession";
import JoinSessionPage from "./pages/JoinSessionPage";
import Login from "./pages/Login";
import UserHome from "./pages/UserHome";
import Friends from "./pages/Friends";
import NewHome from "./pages/NewHome";
import ExitLandingPage from "./pages/ExitLandingPage";
import UserStatistics from "./pages/UserStatistics";
import SessionStatistics from "./pages/SessionStatistics";
import NotFound from "./pages/NotFound";
import About from "./pages/AboutPage";
import Layout from "./components/Layout";
//comment for pull request

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<NewHome />} />
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/accountsettings" element={<AccountSettings />} />
            <Route path="/confirmation" element={<Confirmation />}></Route>
            <Route path="/session" element={<Session />} />
            <Route path="/error/spotify" element={<SpotifyErrorPage />} />
            <Route path="/success/spotify" element={<SpotifySuccessPage />} />
            <Route path="/create-session" element={<CreateSession />} />
            <Route path="/login" element={<Login />}></Route>
            <Route path="/searchtest" element={<SearchTestPage />} />
            <Route path="/searchtest" element={<SearchTestPage />} />
            <Route
              path="/recommendationstest"
              element={<RecommendationsTestPage />}
            />
            <Route path="/join-session" element={<JoinSessionPage />} />
            <Route path="/join" element={<JoinSession />} />
            <Route path="/user-home" element={<UserHome />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/new-home" element={<NewHome />}></Route>
            <Route path="/exit-session" element={<ExitLandingPage />} />
            <Route path="/user-statistics" element={<UserStatistics />}></Route>
            <Route
              path="/session-statistics"
              element={<SessionStatistics />}
            ></Route>
            <Route path="/about" element={<About />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
