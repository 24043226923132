import React, { Dispatch, SetStateAction, useCallback, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { SpotifyApi } from '@spotify/web-api-ts-sdk';
import SpotifyPlayer, { SpotifyWebPlaybackErrorType } from 'spotify-web-playback';
import { useSearchParams } from 'react-router-dom';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import * as auth from '@tidal-music/auth';
import * as player from '@tidal-music/player';
import { CredentialsProvider } from '@tidal-music/common';
import * as catalogue from '@tidal-music/catalogue';
import { Client } from 'openapi-fetch';
import { NOT_PLAYING_SONG, PTASong } from '../PTASong';
import { NOT_PLAYING_STATE, PlaybackMode, updatePlaybackState } from '../PlaybackState';
import Cookies from 'js-cookie';

// declare global {
//   interface Window {
// 		onSpotifyWebPlaybackSDKReady: any;
//   }
// }

const clientId = 'it5itGcoxCK8wVu3';
const clientSecret = 'lffizIzqoidB3IMXkUGCLLHB3xiTQovG2VDV6lGgOX0='; // uum uh um uhhhhhhh. shh
const redirectUri = import.meta.env.VITE_API_BASE_URL + '/';

const authUrl = new URL("https://login.tidal.com/authorize")

const scope = "playlists.read entitlements.read collection.read user.read playback recommendations.read";
const scopes = scope.split(" ");

const PTATidalPlayer = forwardRef<PTAPlayerHandle>(function PTATidalPlayer(props, ref) {
  // const [searchParams] = useSearchParams();
	// const [sdk, setSdk]: [SpotifyApi | null, Dispatch<SetStateAction<SpotifyApi | null>>] = useState<SpotifyApi | null>(null);
	// const [accessToken, setAccessToken] = useState('');
	// const [expiresAt, setExpiresAt] = useState(0);
	// const [player, setPlayer]: [SpotifyPlayer | null, Dispatch<SetStateAction<SpotifyPlayer | null>>] = useState<SpotifyPlayer | null>(null);
	// const id = useRef('');
	const [credentialsProvider, setCredentialsProvider] = useState<CredentialsProvider | null>(null);
	const [catalog, setCatalog] = useState<Client<{}, `${string}/${string}`> | null>(null);
	const sessionID = useRef<string | null>(null);
	const currentSongRef = useRef<PTASong>(NOT_PLAYING_SONG);

	useEffect(() => {
		const sessID = Cookies.get("sessionID");
		if (sessID !== undefined) sessionID.current = sessID;
	}, [document.cookie]);

	useEffect(() => {
		if (credentialsProvider !== null) {
			player.setCredentialsProvider(credentialsProvider);
			player.bootstrap({
				outputDevices: true,
				players: [
					{
						itemTypes: ["track"],
						player: "native"
					}
				]
			});
			// player.startNativePlayer().then(() => {console.log("started native player")}).catch((res) => { console.error("Tidal player error", res)});
			console.log("set player");
			setCatalog(catalogue.createCatalogueClient(auth.credentialsProvider));
		}
	}, [credentialsProvider]);

	useEffect(() => {
		if (credentialsProvider === null) {
			auth.init({
				clientId,
				clientSecret,
				credentialsStorageKey: "passtheaux"
			}).then(() => {
				setCredentialsProvider(auth.credentialsProvider);
				console.log("Set credentials provider");
				// auth.initializeLogin({
				// 	redirectUri
				// }).then((url) => {
				// 	window.location.href = url;
				// })
			});
		}
	}, [credentialsProvider]);
	// useEffect(() => {
	// 	const code = searchParams.get('code');
	// 	if (code !== null) {
	// 		console.log(searchParams.toString());
	// 		auth.finalizeLogin(searchParams.toString())
	// 			.then(() => { console.log("Done attempting to get code"); setCredentialsProvider(auth.credentialsProvider) })
	// 			.catch((error: string) => { console.error("Did not get code!!" + error); });
	// 	}
	// }, [searchParams]);

	const playSong = useCallback(async (newSong: PTASong) => {
		if (sessionID.current !== null) {
			updatePlaybackState({
				sessionID: sessionID.current,
				state: NOT_PLAYING_STATE
			}).then((val) => {
				updatePlaybackState({
					sessionID: sessionID.current!,
					state: {
						current: newSong,
						playing: PlaybackMode.PLAY,
						currentTimeMillis: 0
					}
				});
			});
		}
		player.load({
			productId: newSong.songId,
			productType: "track",
			sourceId: newSong.albumId,
			sourceType: "ALBUM"
		});
		player.play();
		currentSongRef.current = newSong;
	}, [player]);
	const isPlaying = useCallback(async () => {
		if (player !== null) {
			return player.getPlaybackState() == "PLAYING";
		} else return false;
	}, [player]);
	const togglePlaying = useCallback(async () => {
		if (player !== null) {
			if (player.getPlaybackState() == "PLAYING") {
				player.pause();
				return false;
			} else {
				player.play();
				return true;
			}
		} else return false;
	}, [player]);
	const play = useCallback(async () => {
		player.play();
	}, [player]);
	const pause = useCallback(async () => {
		player.pause();
	}, [player]);
	const seek = useCallback(async (pos: number) => {
		player.seek(pos / 1000);
		return;
	}, [player]);
	const next = useCallback(async () => {
		let pos = player?.getPlaybackContext()?.actualDuration;
		if (pos !== undefined) {
			player.seek(pos);
		}
	}, [player]);
	const previous = useCallback(async () => {
		/* impossible to implement */
	}, [player]);
	const getPosition = useCallback(async () => {
		if (player !== null) return player.getAssetPosition() * 1000;
		else return -1;
	}, [player]);
	const getCurrentSong = useCallback(() => {
		return currentSongRef.current;
	}, []);
	const setVolume = useCallback(async (level: number) => {
		if (level !== volume.current) {
			volume.current = level;
			player.setVolumeLevel(level);
		}
	}, [player]);
	const volume = useRef(100.0);
	const getVolume = useCallback(() => {
		return volume.current;
	}, []);

	useImperativeHandle(ref, () => {
		return {
			playSong,
			isPlaying,
			togglePlaying,
			play,
			pause,
			seek,
			next,
			previous,
			getPosition,
			getCurrentSong,
			setVolume,
			getVolume
		};
	});

	return <></>;
});

export default PTATidalPlayer;
