import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

type AlertSeverity = "error" | "info" | "success" | "warning";

export default function Friends() {
  const [tabIndex, setTabIndex] = useState(0);
  const [friends, setFriends] = useState([]);
  const [friendGroups, setFriendGroups] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");
  const [newGroupName, setNewGroupName] = useState("");
  const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedFriendToAdd, setSelectedFriendToAdd] = useState("");
  const [openAddFriendDialog, setOpenAddFriendDialog] = useState(false);
  const [currentGroupFriends, setCurrentGroupFriends] = useState([]);
  const navigate = useNavigate();

  const username = Cookies.get("userID");

  useEffect(() => {
    fetchFriends();
    fetchFriendGroups();
    fetchRequests();
  }, [username]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchFriends = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/friends?user=${username}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setFriends(result);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  const fetchFriendGroups = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/get-friend-groups`,
        {
          headers: {
            userID: username || "",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setFriendGroups(result);
    } catch (error) {
      console.error("Error fetching friend groups:", error);
    }
  };

  const fetchRequests = async () => {
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/friend-requests?user=" +
          username
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setRequests(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRequestAcceptance = async (entry: string) => {
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/accept-friend-request?user=" +
          username +
          "&friend=" +
          entry
      );

      if (response.ok) {
        setRequests((prevData) => prevData.filter((item) => item !== entry));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRequestDecline = async (entry: string) => {
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/decline-friend-request?user=" +
          username +
          "&friend=" +
          entry
      );

      if (response.ok) {
        setRequests((prevData) => prevData.filter((item) => item !== entry));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handlers for Friends Tab
  const handleFriendRemoval = async (entry: never) => {
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/api/remove-friends?user=${username}&friend=${entry}`
      );
      if (response.ok) {
        setFriends((prevData) => prevData.filter((item) => item !== entry));
      }
    } catch (error) {
      console.error("Error removing friend:", error);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/api/send-friend-request?user=${username}&friend=${searchTerm}`
      );
      const message = await response.text();
      if (response.ok) {
        showSnackbar(message, "success");
      } else {
        showSnackbar(message, "error");
      }
      setSearchTerm("");
    } catch (error) {
      console.error("Error sending friend request:", error);
    }
  };

  // Handlers for Friend Groups Tab
  const handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setTabIndex(newValue);
  };

  const handleOpenCreateGroupDialog = () => {
    setNewGroupName("");
    setOpenCreateGroupDialog(true);
  };

  const handleCreateGroup = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/create-friend-group`,
        {
          method: "POST",
          headers: {
            userID: username || "",
            groupName: newGroupName || "",
          },
        }
      );
      if (response.ok) {
        fetchFriendGroups();
        setOpenCreateGroupDialog(false);
      }
    } catch (error) {
      console.error("Error creating friend group:", error);
    }
  };

  const handleDeleteGroup = async (groupName: string) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/delete-friend-group`,
        {
          method: "POST",
          headers: {
            userID: username || "",
            groupName: groupName || "",
          },
        }
      );
      if (response.ok) {
        fetchFriendGroups();
      }
    } catch (error) {
      console.error("Error deleting friend group:", error);
    }
  };

  const handleOpenAddFriendDialog = (
    groupName: React.SetStateAction<string>
  ) => {
    setSelectedGroup(groupName);
    setSelectedFriendToAdd("");
    setOpenAddFriendDialog(true);
  };

  const handleAddFriendToGroup = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/add-friend-to-group`,
        {
          method: "POST",
          headers: {
            userID: username || "",
            friendCreds: selectedFriendToAdd || "",
            groupName: selectedGroup || "",
          },
        }
      );
      if (response.ok) {
        fetchFriendGroups();
        setOpenAddFriendDialog(false);
      }
    } catch (error) {
      console.error("Error adding friend to group:", error);
    }
  };

  const handleRemoveFriendFromGroup = async (
    groupName: string,
    friendCreds: any
  ) => {
    console.log("Removing " + friendCreds + " from " + groupName);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_BASE_URL}/api/remove-friend-from-group`,
        {
          method: "POST",
          headers: {
            userID: username || "",
            friendCreds: friendCreds || "",
            groupName: groupName || "",
          },
        }
      );
      if (response.ok) {
        fetchFriendGroups();
      }
    } catch (error) {
      console.error("Error removing friend from group:", error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Paper square>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Friends" />
          <Tab label="Friend Groups" />
          <Tab label="Friend Requests" />
        </Tabs>
      </Paper>
      {tabIndex === 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Friends
          </Typography>
          {friends.length > 0 ? (
            <List>
              {friends.map((entry, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Tooltip title="Remove Friend" placement="top">
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() => handleFriendRemoval(entry)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemText primary={entry} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1">You Have No Friends...</Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Users..."
              variant="outlined"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              sx={{ ml: 2 }}
            >
              Send Friend Request
            </Button>
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Your Friend Groups
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateGroupDialog}
            sx={{ mb: 2 }}
          >
            Create Friend Group
          </Button>
          {Object.keys(friendGroups).length > 0 ? (
            Object.entries(friendGroups).map(([groupName, groupFriends]) => (
              <Box key={groupName} sx={{ mb: 4 }}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6">{groupName}</Typography>
                  <Tooltip title="Delete Friend Group" placement="top">
                    <IconButton
                      edge="end"
                      aria-label="delete group"
                      onClick={() => handleDeleteGroup(groupName)}
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleOpenAddFriendDialog(groupName)}
                    sx={{ ml: 2 }}
                  >
                    Add Friend
                  </Button>
                </Box>
                {groupFriends.length > 0 ? (
                  <List>
                    {(groupFriends as string[]).map((friendCreds, index) => (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() =>
                              handleRemoveFriendFromGroup(
                                groupName,
                                friendCreds
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={friendCreds} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    This group is empty.
                  </Typography>
                )}
              </Box>
            ))
          ) : (
            <Typography variant="body1">You have no friend groups.</Typography>
          )}
        </Box>
      )}

      {tabIndex == 2 && (
        <Box sx={{ padding: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Friend Requests
          </Typography>
          {requests.length > 0 ? (
            <List>
              {requests.map((entry, index) => (
                <ListItem key={index} divider>
                  <ListItemText primary={entry} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleRequestAcceptance(entry)}
                    sx={{ mr: 1 }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRequestDecline(entry)}
                  >
                    Decline
                  </Button>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1">You Have No Friend Requests</Typography>
          )}
        </Box>
      )}

      {/* Create Friend Group Dialog */}
      <Dialog
        open={openCreateGroupDialog}
        onClose={() => setOpenCreateGroupDialog(false)}
      >
        <DialogTitle>Create New Friend Group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateGroupDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreateGroup} disabled={!newGroupName}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Friend to Group Dialog */}
      <Dialog
        open={openAddFriendDialog}
        onClose={() => setOpenAddFriendDialog(false)}
      >
        <DialogTitle>Add Friend to Group "{selectedGroup}"</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Friend</InputLabel>
            <Select
              value={selectedFriendToAdd}
              onChange={(e) => setSelectedFriendToAdd(e.target.value)}
              label="Select Friend"
            >
              {friends.map((friend) => (
                <MenuItem key={friend} value={friend}>
                  {friend}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddFriendDialog(false)}>Cancel</Button>
          <Button
            onClick={handleAddFriendToGroup}
            disabled={!selectedFriendToAdd}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
