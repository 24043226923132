import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react';
import '../styles/CurrentSong.css';
import Cookies from 'js-cookie';
import { getPlaybackState, NOT_PLAYING_STATE, PlaybackMode, PlaybackState, updatePlaybackState } from '../PlaybackState';
import { NOT_PLAYING_SONG, PTASong } from '../PTASong';
import { Box, createTheme, Theme, Typography } from '@mui/material';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import { kMeansClustering, ThemeColors } from '../kmeansclustering';
import CurrentSongHost from './CurrentSongHost';
import CurrentSongGuest from './CurrentSongGuest';

export interface CurrentSongHandle {
  playbackState: React.MutableRefObject<PlaybackState>
};
export interface CurrentSongProps {
  player: React.MutableRefObject<PTAPlayerHandle | null>,
  setTheme: React.Dispatch<React.SetStateAction<Theme>>,
  viewLyrics: string
};

const LIGHT_MODE_THEME_COLORS: ThemeColors = {
  primary: "#38386b",
  primaryAlt: "#ffffff",
  secondary: "#b07bac",
  secondaryAlt: "#ffffff",
  background: "#f3eaf4",
  backgroundPaper: "#ffffff",
  text: "#1e1d1e",
  textSecondary: "#38386b"
};

const CurrentSong = forwardRef<CurrentSongHandle, CurrentSongProps>((props, ref) => {
  const [isHost, setIsHost] = useState<boolean>(false);
  const [song, setSong] = useState<PTASong>(NOT_PLAYING_SONG);
  const playbackState = useRef<PlaybackState>(NOT_PLAYING_STATE);
  const { player, setTheme, viewLyrics, ...otherProps } = props;
  const [themeColors, setThemeColors] = useState<ThemeColors>(LIGHT_MODE_THEME_COLORS);
  const modeRef = useRef("AUTOMATIC");

  useEffect(() => {
    setTheme(createTheme({
      palette: {
        mode: 'light',
        primary: {
          main: themeColors.primary, // Delft Blue
          contrastText: themeColors.primaryAlt, // White text on primary buttons
        },
        secondary: {
          main: themeColors.secondary, // African Violet
          contrastText: themeColors.secondaryAlt, // White text on secondary buttons
        },
        background: {
          default: themeColors.background, // Magnolia
          paper: themeColors.backgroundPaper,   // White for paper elements
        },
        text: {
          primary: themeColors.text, // Eerie Black
          secondary: themeColors.textSecondary, // Delft Blue or a derived color
        },
        // You can customize other palette colors if needed
      },
      typography: {
        fontFamily: 'CustomFont, Arial, sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400, // Standard
        fontWeightMedium: 700,  // Bold
        fontWeightBold: 900,    // Black (Extra Bold)
        h1: {
          fontWeight: 900, // Use Black font weight
        },
        h2: {
          fontWeight: 700, // Use Bold font weight
        },
        h3: {
          fontWeight: 700,
        },
        h4: {
          fontWeight: 700,
        },
        h5: {
          fontWeight: 400,
        },
        h6: {
          fontWeight: 400,
        },
        body1: {
          fontWeight: 400,
        },
        body2: {
          fontWeight: 400,
        },
        button: {
          fontWeight: 700,
          textTransform: 'none'
        }
      }
    }))
  }, [themeColors]);

  const updateAlbumTheme = useCallback(async (url: string) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Allow cross-origin images if needed
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        // Draw the image onto the canvas
        if (ctx === null) return;
        ctx.drawImage(img, 0, 0);

        // Get image data
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        const { data } = imageData;
        const rgbArray: number[][] = [];
        for (let i = 0; i < data.length; i += 4) {
            rgbArray.push([data[i], data[i + 1], data[i + 2]]); // Push R, G, B
        }
        setThemeColors(kMeansClustering(rgbArray));
    };
    img.src = url;
  }, []);

  const getMode = useCallback(async () => {
    try {
      const sessionId = Cookies.get("sessionID");
      const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-session", {
        headers: {
          sessionID: sessionId || "",
          userID: Cookies.get("userID") || "",
        },
      });
      if (response.ok) {
        const sessionData = await response.json();
        if (sessionData && sessionData.mode) {
          modeRef.current = sessionData.mode;
          console.log(sessionData.mode); 
        } else {
          console.log("Cant get session mode")
        }
        return;
      } else {
        console.log("Cant get session from fetch sesh")
      }
      return;
    } catch (error) {
      console.error("Error fetching queue:", error);
      return;
    }
  }, []);

    useEffect(() => {
      // Check host status only once when the component mounts
      const sessionId = Cookies.get("sessionID");
      const checkHostStatus = async () => {
        const userId = Cookies.get("userID");
        try {
          const response = await fetch(
            import.meta.env.VITE_API_BASE_URL + "/api/isHostView",
            {
              method: "POST", // Ensure the method is POST
              headers: {
                sessionID: sessionId || "",
                userID: userId || "",
              },
            }
          );
          if (response.ok) {
            const h = (await response.text()) === "true";
            setIsHost(h);
            if (!h) console.warn("C$" + response.text() + "$");
          } else {
            console.error("Failed to check host status");
          }
        } catch (error) {
          console.error("Error checking host status:", error);
        }
      };

      console.log("component is rendered");
      checkHostStatus();

      if (sessionId != null) {
        const intervalId = setInterval(() => {
          getPlaybackState(sessionId).then((state) => {
            if (state === null) {
              setSong(NOT_PLAYING_SONG);
              playbackState.current = NOT_PLAYING_STATE
            }
            else {
              setSong(state.current);
              playbackState.current = state;

              if (player.current?.getCurrentSong().songId !== state.current.songId) {
                if (state.current.songId !== NOT_PLAYING_SONG.songId) {
                  updateAlbumTheme(state.current.albumArtURL);
                  player.current?.playSong(state.current);
                  console.log("Playing next song", state.current);
                }
              }
            }
          });
          getMode();
        }, 1000);
        return () => clearInterval(intervalId); // Clean up interval on component unmount
      }
    }, []); // Only run once on component mount

    const nextSong = async () => {
      const sessionId = Cookies.get("sessionID");
      console.log("nextSong function called");
      if (sessionId != null) {
        console.log("updating playback state");
        updatePlaybackState({
          sessionID: sessionId,
          state: {
            current: song,
            currentTimeMillis: playbackState.current.currentTimeMillis,
            playing: PlaybackMode.STOP,
          },
        });
      }
    };

    useImperativeHandle(ref, () => {
      return {
        playbackState,
      };
    });

    return (
      <Box>  
        {isHost ? (
          <>
            <h1>
              {modeRef.current === "MANUAL"
                ? "Next Up"
                : isHost
                ? "Current Song"
                : "Current Song"}
            </h1>
            <CurrentSongHost
              song={song}
              nextSong={nextSong}
              viewLyrics={viewLyrics}
              manualMode={modeRef.current === "MANUAL" ? true : false}
            />
          </>
        ) : (
          <>
            <h1>
              {modeRef.current === "MANUAL"
                ? "Next Up"
                : isHost
                ? "Current Song"
                : "Current Song"}
            </h1>
            <CurrentSongGuest song={song} viewLyrics={viewLyrics} />
          </>
        )}
      </Box>
    );
  }
);

export default CurrentSong;
