export type PTASong = {
  	songId: string;
	title: string;
	artist: string;
	genre: string;
	url: string;
	albumArtURL: string;
	albumId: string;
	durationMs: number;
};

export const NOT_PLAYING_SONG: PTASong = {
	songId: "NotPlaying",
	title: "Not playing",
	artist: "Not playing",
	albumArtURL: "https://content.jcrm1.com/hotlink-ok/amongst-us.gif",
	genre: "Not playing",
	url: "https://content.jcrm1.com/hotlink-ok/amongst-us.gif",
	albumId: "Not playing",
	durationMs: 0
};
