import React, { useState } from "react";
import QrCodeScanner from "../components/QRCodeScanner";
import "../styles/LoginButtonsTestPage.css";
import Cookies from "js-cookie";
import { Box, Typography, Button } from "@mui/material";
import JoinSessionButton from "./JoinSessionButton";
import JoinSessionByLocationButton from "./JoinSessionByLocation";
import JoinSessionByFriendsLocation from "./JoinSessionByFriendsLocation";

export default function LoginButtonsTestPage() {
  const [isScannerVisible, setScannerVisibility] = useState(false);
  const userID = Cookies.get("userID");

  const handleQrScan = (data: string | null) => {
    if (data) {
      window.location.href = data;
    }
  };

  const handleScanButtonClick = () => {
    setScannerVisibility(true);
  };

  const buttonStyle = {
    fontSize: "0.875rem",
    padding: "8px 16px",
    width: "100%",
    maxWidth: "300px",
    alignSelf: "center",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 400,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Login Options
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
            alignItems: "center", // Centers the buttons
          }}
        >
          {/* Scan QR Code Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleScanButtonClick}
            sx={buttonStyle}
          >
            Scan QR Code
          </Button>

          {/* QR Code Scanner */}
          {isScannerVisible && (
            <Box sx={{ mt: 2 }}>
              <QrCodeScanner onScan={handleQrScan} />
            </Box>
          )}

          {/* Join Session with Code */}
          {/* <Button
            variant="contained"
            color="primary"
            sx={buttonStyle}
          >
            Join Session with Code
          </Button> */}
          <JoinSessionButton />

          {/* Find Session Near Me */}
          {/* <Button variant="contained" color="primary" sx={buttonStyle}>
            Find Session Near Me
          </Button> */}
          <JoinSessionByLocationButton />

          {/* Find Friends' Sessions */}
          {/* {userID && (
            <Button variant="contained" color="primary" sx={buttonStyle}>
              Find Friends' Sessions
            </Button>
          )} */}
          <JoinSessionByFriendsLocation />
        </Box>
      </Box>
    </Box>
  );
}
