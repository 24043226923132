import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AboutPage() {
  return (
    <Box sx={{ padding: 4 }}>
      {/* Brief Summary */}
      <Typography variant="h3" gutterBottom >
        About Pass The Aux
      </Typography>
      <Typography variant="body1" paragraph >
        Pass The Aux is a collaborative music platform designed to bring people together through shared music experiences. At its core, Pass The Aux allows users to host sessions where guests can recommend and vote on songs, ensuring that the music played reflects the preferences of the majority. Unlike other solutions where everyone controls the queue directly, Pass The Aux maintains order and harmony, preventing the chaos that can arise when multiple users have control over the playlist.
      </Typography>
      <Typography variant="body1" paragraph >
        Whether you're hosting a large gathering, a small get-together, or a professional event, Pass The Aux makes it easy to curate the perfect playlist collaboratively. DJs can use it to engage with the crowd, and friends can connect over their favorite tunes, all while enjoying a seamless and democratic music-sharing experience.
      </Typography>

      {/* Why Pass The Aux Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Why Pass The Aux</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Pass The Aux stands out by offering a structured yet flexible way for groups to share and enjoy music together. Unlike alternatives like Spotify Jams, which can become unwieldy with more than 10-15 participants, Pass The Aux scales gracefully for large gatherings. By allowing guests to recommend and vote on songs, the app ensures that the majority's preferences are always represented without the playlist descending into disarray.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            For DJs and event organizers, Pass The Aux is an invaluable tool. It enables DJs to gauge the crowd's musical preferences in real-time without being overwhelmed by individual requests. Guests can submit their recommendations through the app, and the most popular choices rise to the top, allowing the DJ to keep the energy high and the audience engaged.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            Pass The Aux brings order, democracy, and fun to group music listening, making it the ideal solution for any event where music is key.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Accounts Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Accounts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Creating an account with Pass The Aux unlocks the full potential of the platform. Accounts are designed with security in mind, offering two-factor authentication to protect your personal information and preferences. Your nickname, which is non-unique, serves as your display name, allowing you to express yourself freely within the community.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            The credentials associated with your account are used for sending notifications, keeping you informed about session activities, friend requests, and more. You can easily manage and update your account details in the Account Management section of the website. While an account is required to create sessions and access all features, guests can join sessions without an account, ensuring inclusivity and ease of access for all participants.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Friends Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Friends</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Pass The Aux enhances the social aspect of music sharing by allowing you to connect with friends. You can add friends based on their credentials and organize them into custom groups for easier management. The Friends tab on the website is your hub for viewing your friend list, creating and editing friend groups, and seeing which sessions your friends are participating in.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            Inviting friends to your sessions is seamless, and you can also join public sessions that your friends are part of. This connectivity fosters a collaborative environment where you and your friends can enjoy and influence the music together.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Sessions Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Sessions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Sessions are the heart of Pass The Aux, providing a space for users to collaboratively create and enjoy playlists. When you host a session, you have full control over its configuration, tailoring it to suit your event or gathering. Key features of sessions include:
          </Typography>
          <ul style={{ textAlign: 'left' }}>
            <li>
              <strong>Session Type:</strong> Choose between <em>Public</em> and <em>Private</em> sessions. Public sessions are open to anyone within a specified distance or friends of participants, while private sessions require an invite code or link to join.
            </li>
            <li>
              <strong>Session Mode:</strong> Select between <em>Automatic</em> and <em>Manual</em> modes. Automatic mode plays music directly through the app using a linked music service, managing the queue automatically. Manual mode is designed for DJs or hosts using external music players, where the host manages playback manually.
            </li>
            <li>
              <strong>Queue Management:</strong> Guests can recommend songs using the search bar, which accesses music databases corresponding to the linked music service in Automatic mode. Each song recommendation enters the queue, where participants can upvote or downvote songs.
            </li>
            <li>
              <strong>Voting System:</strong> Each user has one vote per song. The queue dynamically reorders based on the votes, ensuring that the songs most favored by the majority are played next. This democratic approach keeps the playlist engaging and reflective of the group's preferences.
            </li>
            <li>
              <strong>Host Controls:</strong> Hosts have the ability to manage the queue directly, allowing them to adjust song order or remove songs as needed. This control ensures that the session runs smoothly.
            </li>
            <li>
              <strong>Notifications:</strong> Participants receive real-time notifications about session updates, such as changes in the queue, new song additions, or when their recommended songs move up or down.
            </li>
            <li>
              <strong>Song Details:</strong> Users can view detailed information about songs, including lyrics, artist info, and more, enhancing the listening experience.
            </li>
          </ul>
          <Typography variant="body1" paragraph align="left">
            Sessions in Pass The Aux are designed to be interactive, engaging, and fun, making group music experiences more enjoyable than ever.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Linking Music Services Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Linking Music Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Pass The Aux integrates seamlessly with popular music services, including Spotify, Apple Music, Tidal, and YouTube. By linking your account with these services, you can leverage your existing music libraries and preferences within the app.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            In Automatic mode sessions, linking a music service allows the app to play songs directly from your account, providing high-quality playback and access to a vast library of music. The search functionality within sessions taps into the linked service's database, making it easy for guests to find and recommend songs.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            For security reasons, you must re-connect your music service each time you log in, unless your session is maintained through cookies. This ensures that your credentials remain secure while providing a smooth user experience.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Analytics Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Analytics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph align="left">
            Pass The Aux provides insightful analytics to enhance your music-sharing experience. As a host, you can view comprehensive statistics on your past sessions, including:
          </Typography>
          <ul style={{ textAlign: 'left' }}>
            <li>Total participants</li>
            <li>Most popular songs</li>
            <li>Voting patterns</li>
            <li>Session duration</li>
          </ul>
          <Typography variant="body1" paragraph align="left">
            These analytics help you understand your audience's preferences, allowing you to tailor future sessions for even greater engagement.
          </Typography>
          <Typography variant="body1" paragraph align="left">
            As a guest, you can access personal analytics that showcase your involvement in sessions. View the songs you've recommended, see how well they performed in terms of votes, and track your overall engagement. This personal insight adds a rewarding dimension to your participation.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}