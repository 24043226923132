import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Moon icon
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Sun icon
import { useThemeContext } from '../ThemeContext';

const Navbar: React.FC = () => {
  const theme = useTheme(); // Access the current theme
  const { mode, toggleMode } = useThemeContext(); // Context for theme mode

  return (
    <AppBar position="static" style={{ backgroundColor: theme.palette.primary.main }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="home"
          onClick={() => {window.location.href = window.location.protocol + "//" + window.location.host}}
          style={{ textDecoration: 'none' }}
        >
          <HomeIcon />
        </IconButton>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          style={{
            color: "#ffffff",
            textDecoration: 'none',
            marginLeft: theme.spacing(2),
          }}
        >
          PassTheAux
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={toggleMode} color="inherit">
            {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;