import React, { useEffect, useState } from 'react';
import '../styles/LoginButton.css'
import PTASpotifyPlayer from './PTASpotifyPlayer';
import { Button, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function SpotifyLoginButton() {
  const [am, setAm] = useState(<></>);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("at") && !(React.isValidElement(am) && am.type === PTASpotifyPlayer)) {
      setAm(<PTASpotifyPlayer />);
    } else {
      console.log(searchParams, am, am.type, PTASpotifyPlayer);
    }
  }, [am, searchParams]);
	const [isValid, setIsValid] = useState(false);
	useEffect(() => {
		if (Cookies.get("mode") === "spotify") setIsValid(true);
		else setIsValid(false);
	}, [document.cookie]);
  return (
    <>
      <Button
        onClick={() => setAm(<PTASpotifyPlayer />)}
        variant="contained"
        color="primary"
        size="large"
        sx={{
          textTransform: 'none',
          minWidth: 200, // Ensure consistent width with other buttons
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 16px', // Adjust padding as per your standard buttons
          borderRadius: 2, // Match border radius with other buttons
        }}
      >
        <Box
          component="img"
          src="/spotify_logo.svg"
          alt="Spotify logo"
          sx={{
            height: 24, // Adjust as needed to fit the button
            width: 'auto',
          }}
        />
      </Button>
      {am}
    </>
  );
}
