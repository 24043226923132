import React, { useState, useEffect, useCallback } from "react";
import { PTASong } from "../PTASong";
import Cookies from "js-cookie";
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';


const LastFewSongsPlayed = () => {
    const [songs, setSongs] = useState<PTASong[]>([]);
    const [vibe, setVibe] = useState<String>("");
    const [isVisible, setIsVisible] = useState<boolean>(false);

  // Fetch session data every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleGetPastSongs(); // Periodically update the list of recent songs
    }, 3000); // 3 seconds interval

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  const handleGetPastSongs = useCallback(async () => {
    try {
      const sessionId = Cookies.get("sessionID");
      const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-previous-songs", {
        headers: {
          "sessionID": sessionId || "",
        },
      });
      if (response.ok) {
        const lastFewSongs = await response.json();
        // console.log(lastFewSongs)
        if (lastFewSongs) {
          setSongs(lastFewSongs);
        }
      } else {
        console.error("Failed to fetch queue");
      }
    } catch (error) {
      console.error("Error fetching queue:", error);
    }

    try {
      const sessionId = Cookies.get("sessionID");
      const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-vibe", {
        headers: {
          "sessionID" : sessionId || "",
        }
      });

      if (response.ok) {
        const lastFewSongsPlayedObject = await response.json();
        // console.log(lastFewSongsPlayedObject.vibe)
        if (lastFewSongsPlayedObject.vibe) {
          setVibe(lastFewSongsPlayedObject.vibe);
        } else {
          //console.log("failed to get a valid object response");
        }
      }
    } catch (error) {
      console.error("Error fetching vibe: " + error);
    }
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" component="h2" gutterBottom>
          Session Vibe: {vibe || "No past songs"}
      </Typography>
      <Button variant="contained" color="primary" onClick={toggleVisibility} sx={{ mb: 2 }}>
        {isVisible ? "Hide Recently Played Songs" : "Show Recently Played Songs"}
      </Button>
      
      {isVisible && (
        <List>
        {songs.map((song, index) => (
          <ListItem key={index}>
            <ListItemText primary={`${song.title} by ${song.artist}`} />
          </ListItem>
        ))}
      </List>
      )}
    </Box>
  );
}

export default LastFewSongsPlayed
