import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// Import necessary MUI components
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Snackbar,
  Alert,
} from "@mui/material";
import { useRef, useState } from "react";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

type AlertSeverity = "error" | "info" | "success" | "warning";

export default function Login() {
  const navigate = useNavigate();
  const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authCode, setAuthCode] = useState("");
  const turnstile = useRef<TurnstileInstance | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const onSubmitLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var type = "";

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    if (!emailRegex.test(username) && !phoneRegex.test(username)) {
      showSnackbar("Email address or phone number is invalid", "error");
      return;
    }

    if (emailRegex.test(username)) {
      type = "el";
    } else {
      type = "pl";
    }

    const hashedPassword = password;

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/login?user=" +
          username +
          "&pw=" +
          hashedPassword +
          "&cfr=" +
          turnstile.current?.getResponse()
      );

      if (response.ok) {
        const accountResponse = await response.text();
        const lines = accountResponse.split("\n");
        const uuid = lines[0].split(": ")[1];
        const twoFactorAuthStatus: boolean = lines[1].split(": ")[1] === "true";

        if (twoFactorAuthStatus) {
          try {
            const response = await fetch(
              import.meta.env.VITE_API_BASE_URL +
                "/api/two-factor-auth?" +
                "user=" +
                username +
                "&pw=" +
                hashedPassword +
                "&type=" +
                type
            );
            // if (response.ok) {
            //   console.log(response.text());
            // }
          } catch (error) {
            console.error(error);
          }
          setIsTwoFactorAuth(true);
        } else {
          //console.log("User logged in, navigate to user home");
          Cookies.set("userID", uuid);
          Cookies.set("mode", "none");
          if (Cookies.get("goingTo")) {
            console.log("Going to session: " + Cookies.get("goingTo"));
            const sessionCode = Cookies.get("goingTo") || "";
            Cookies.remove("goingTo");
            navigate(`/join?code=${sessionCode}`);
            return;
          }
          fetch(import.meta.env.VITE_API_BASE_URL + "/api/is-session", {
            headers: {
              sessionID: Cookies.get("sessionID")!,
            },
          }).then((res) => {
            res.text().then((text) => {
              if (text === "false") {
                console.log("Resetti spaghetti");
                Cookies.remove("sessionID");
              }
            })
          })
          navigate("/user-home");
        }
      } else {
        const errorMessage = await response.text();
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitAuthentication = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    // Handle confirmation code submission
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/verify-code?user=" +
          username +
          "&code=" +
          authCode
      );

      if (response.ok) {
        const creds = await response.text();
        Cookies.set("userID", creds);
        Cookies.set("mode", "none");
        if (Cookies.get("goingTo")) {
          Cookies.set("sessionID", Cookies.get("goingTo") || "");
          Cookies.remove("goingTo");
          navigate("/session");
        }
        navigate("/user-home");
      } else {
        const errorMessage = await response.text();
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      console.error(error);
      showSnackbar("ERROR: Please try again later", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: 2,
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Login to your Pass The Aux Account
        </Typography>
        <form
          onSubmit={isTwoFactorAuth ? onSubmitAuthentication : onSubmitLogin}
        >
          {!isTwoFactorAuth ? (
            <>
              <TextField
                label="Enter Email or Phone Number"
                type="text"
                id="enter-username"
                name="enter-username"
                required
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                label="Enter Password"
                type="password"
                id="enter-password"
                name="enter-password"
                required
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Turnstile
                siteKey={
                  import.meta.env.VITE_API_BASE_URL.includes("localhost")
                    ? "1x00000000000000000000AA"
                    : "0x4AAAAAAAztIPO7QXN1j8EB"
                }
                ref={turnstile}
                style={{ marginTop: "16px" }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Log in
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                A code was sent to the email/phone number if valid
              </Typography>
              <TextField
                label="Enter Authentication Code"
                type="text"
                id="authentication-code"
                name="authentication-code"
                required
                fullWidth
                margin="normal"
                value={authCode}
                onChange={(e) => setAuthCode(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Confirm
              </Button>
            </>
          )}
        </form>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
