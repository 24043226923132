import React from 'react';
// import { useMediaDevices } from 'react-media-devices';
// import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';

// const constraints: MediaStreamConstraints = {
//   video: {
//     facingMode: 'environment',
//   },
//   audio: false
// };


const QrCodeScanner = ({ onScan }: { onScan: (data: string | null) => void }) => {
  // const deviceId = devices?.[0]?.deviceId;
  const { ref } = useZxing({
    // paused: !deviceId,
    // deviceId,
    onDecodeResult(result) {
      const text = result.getText();
      console.log('QR Code Data:', text);
      window.location.href = text;
    },
    onError(error) {
      console.error("ZXing error", error);
    }
  });
  
  

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto' }}>
      <video ref={ref} style={{ width: '100%' }} />
    </div>
  );
};

export default QrCodeScanner;
