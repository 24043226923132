import React, { useEffect, useRef, useState } from 'react';
import PTASpotifyPlayer from '../components/PTASpotifyPlayer';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import PTAAppleMusicPlayer from '../components/PTAAppleMusicPlayer';
import PTATidalPlayer from '../components/PTATidalPlayer';
import Cookies from 'js-cookie';
import PTAYTPlayer from '../components/PTAYTPlayer';

export default function SearchTestPage() {
	const [searchQuery, setSearchQuery] = useState('');
	const [result, setResult] = useState('');
	function submitSearchQuery(query: string) {
		fetch(import.meta.env.VITE_API_BASE_URL + "/api/search?query=" + query).then(res => {
			res.json().then((val) => {
				if (res.status !== 200) {
					setResult("Error: " + val.error);
				} else {
					setResult(val.map((song: any) =>
						<button onClick={() => ref.current?.playSong(song.songId)}>{song.artist} - {song.title}</button>
					));
				}
			});
		});
	}
	const ref = useRef<PTAPlayerHandle | null>(null);
	const [comp, setComp] = useState(<></>);
	const [mode, setMode] = useState('none');
	useEffect(() => {
		if (mode === "spotify") setComp(<PTASpotifyPlayer ref={ref} />);
		else if (mode === "apple") setComp(<PTAAppleMusicPlayer ref={ref} />);
		else if (mode === "tidal") setComp(<PTATidalPlayer ref={ref} />);
		else if (mode === "yt") setComp(<PTAYTPlayer ref={ref} />);
		else if (mode === "none") {
			setComp(<></>);
			ref.current = null;
		}
	}, [mode]);
	useEffect(() => {
		const mod = Cookies.get("mode");
		if (mod === "spotify" && mode !== "spotify") setMode("spotify");
		else if (mod === "apple" && mode !== "apple") setMode("apple");
		else if (mod === "tidal" && mode !== "tidal") setMode("tidal");
		else if (mod === "yt" && mode !== "yt") setMode("yt");
		else if (mod === "none" && mode !== "none") setMode("none");
	});


	return <>
		<h1>Search test page</h1>
		<label>
			Search query:
			<textarea value={searchQuery} onChange={e => setSearchQuery(e.target.value)} autoFocus={true} name="searchQuery" rows={1} cols={40} />
		</label>
		<button onClick={() => {submitSearchQuery(searchQuery)}}>Search</button><br></br>
		<p>{result}</p><br></br>
		{comp}
	</>
}
