import React, { useEffect, useState } from 'react';
import PTAAppleMusicPlayer from './PTAAppleMusicPlayer';
import { Button } from '@mui/material';
import Cookies from 'js-cookie';

// const checkAppleMusic = () => {
//   const img = new Image();
//   img.onload = () => console.info('Apple Music is accessible');
//   img.onerror = (res) => console.error('Apple Music may not be accessible', res);
//   img.src = 'http://init.itunes.apple.com/bag.xml'; // Or another known resource
// }

export default function AppleMusicLoginButton() {
	const [am, setAm] = useState(<></>);
	const [available, setAvailable] = useState(false);
	useEffect(() => {
		// fetch("http://init.itunes.apple.com/").then((res) => { if (res.status === 503) setAvailable(false); });
		fetch("https://init.itunes.apple.com/bag.xml", { mode: 'no-cors' } ).then((res) => {
			setAvailable(true);
		}).catch((res) => {
			console.info("Apple Music is not available");
			setAvailable(false);
		});
		// checkAppleMusic();
	}, []);
	const [isValid, setIsValid] = useState(false);
	useEffect(() => {
		if (Cookies.get("mode") === "apple") setIsValid(true);
		else setIsValid(false);
	}, [document.cookie]);

	return (
		<>
		  {available ? <><Button
			onClick={() => {
			  setAm(<PTAAppleMusicPlayer />);
			}}
			variant="contained"
			color="primary"
			sx={{ textTransform: 'none' }}
			// Uncomment and adjust the following lines if you have an image for Apple Music
			// startIcon={
			//   <Box
			//     component="img"
			//     src="/apple_music_logo.svg"
			//     alt="Apple Music logo"
			//     sx={{ width: 24, height: 24 }}
			//   />
			// }
		  >
			{isValid ? "Using Apple Music" : "Apple Music Login"}
		  </Button>
		  {am}</> : <></>}
		</>
	  );
}
