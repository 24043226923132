import React, { useState } from 'react';

export default function RecommendationsTestPage() {
	const [result, setResult] = useState('');
	function getRecommendations() {
		fetch(import.meta.env.VITE_API_BASE_URL + "/api/recommendations").then(res => {
			res.json().then((val) => {
				if (res.status !== 200) {
					setResult("Error: " + val.error);
				} else {
					let str = "";
					for (const song of val) {
						str += song.artist + " - " + song.title + ", ";
					}
					setResult(str);
					console.log(val);
				}
			});
		});
	}

	return <>
		<h1>Search test page</h1>
		<button onClick={getRecommendations}>Get recommendations</button><br></br>
		<p>{result}</p>
	</>
}
