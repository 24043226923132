import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SessionSettings from "./SessionSettings"; // Import SessionSettings component
import InviteOptions from "./InviteOptions";

interface SessionDetailsProps {
  onClose: () => void;
}

const SessionDetails: React.FC<SessionDetailsProps> = ({ onClose }) => {
  interface Guest {
    nickName: string;
    userID: string;
  }

  interface SessionData {
    sessionId: string;
    sessionName: string;
    hostUserId: string;
    guests: { [key: string]: Guest };
    allowExplicit: boolean;
    allowedGenres: string[];
    mode: string;
    url: string;
    startTime: number[];
    public: boolean;
    location?: {
      latitude: number;
      longitude: number;
    };
    distanceRadius?: number;
    distanceUnit?: string;
    notificationSettings: { [key: string]: boolean };
    repeatSongWaitPeriod: number;
    isStudyMode: boolean;
  }

  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [host, setHost] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [colorThemeMode, setColorThemeMode] = useState<string>("standard");
  const navigate = useNavigate();
  const [isSessionSettingsOpen, setSessionSettingsOpen] = useState(false);

  const openSessionSettings = () => {
    setSessionSettingsOpen(true);
  };

  const closeSessionSettings = () => {
    setSessionSettingsOpen(false);
  };

  const [isInviteOptionsOpen, setInviteOptionsOpen] = useState(false);

  const openInviteOptions = () => {
    setInviteOptionsOpen(true);
  };

  const closeInviteOptions = () => {
    setInviteOptionsOpen(false);
  };

  useEffect(() => {
    const sessionID = Cookies.get("sessionID");
    const userID = Cookies.get("userID");
    setUser(userID);

    if (!sessionID || !userID) {
      console.error("Session ID or User ID not found in cookies.");
      navigate("/"); // Redirect to home or appropriate page
      return;
    }

    // Read colorThemeMode from cookies
    const savedTheme = Cookies.get("colorThemeMode") || "standard";
    Cookies.set("colorThemeMode", savedTheme);
    setColorThemeMode(savedTheme);

    let pollingInterval: NodeJS.Timeout;

    const fetchData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-session",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              sessionID: sessionID,
              userID: userID,
            },
          }
        );

        if (response.ok) {
          const sessionData = await response.json();
          console.log("Fetched session data:", sessionData);
          setSessionData(sessionData);
          setSessionID(sessionData.sessionId);

          // Check if the user is still in the guests map
          if (!sessionData.guests || !sessionData.guests[userID]) {
            console.warn(
              "User ID not found in guests list. Redirecting to exit page."
            );
            Cookies.remove("sessionID");
            navigate("/exit-session");
            return;
          }

          // Set host user data from guests map
          const hostData = sessionData.guests[sessionData.hostUserId];
          setHost(hostData);
        } else {
          const errorText = await response.text();
          console.error("Failed to fetch session:", errorText);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    // Initial fetch
    fetchData();

    // Set up polling every 20 seconds
    pollingInterval = setInterval(fetchData, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(pollingInterval);
    };
  }, [navigate]);

  const handleLeaveSession = async () => {
    const sessionID = Cookies.get("sessionID");
    const userID = Cookies.get("userID");

    if (!sessionID || !userID) {
      console.error("Session ID or User ID not found in cookies.");
      navigate("/"); // Redirect to home or appropriate page
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/remove-guest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
            userID: userID,
          },
        }
      );

      if (response.ok) {
        console.log(`User ${userID} left the session successfully.`);
        // Optionally, clear session-related cookies
        Cookies.remove("sessionID");
        // Redirect to exit landing page
        navigate("/exit-session");
      } else {
        const errorText = await response.text();
        console.error("Failed to leave session:", errorText);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error leaving session:", error);
      // Handle error as needed
    }
  };

  const handleChangeNotificationSettings = async (newSetting: boolean) => {
    const sessionID = Cookies.get("sessionID");
    const userID = Cookies.get("userID");

    if (!sessionID || !userID) {
      console.error("Session ID or User ID not found in cookies.");
      navigate("/"); // Redirect to home or appropriate page
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/change-notif-setting",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
            userID: userID,
            setting: newSetting.toString(),
          },
        }
      );

      if (response.ok) {
        const sessionData = await response.json();
        console.log("Fetched session data:", sessionData);
        setSessionData(sessionData);
        console.log(`User ${userID} notification setting changed.`);
      } else {
        const errorText = await response.text();
        console.error("Failed to change setting:", errorText);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error changing setting:", error);
      // Handle error as needed
    }
  };

  const formatStartTime = (startTime: number[]) => {
    if (!Array.isArray(startTime) || startTime.length < 6) {
      return "Invalid start time";
    }

    const [year, month, day, hour, minute, second] = startTime;

    // Format the date as "YYYY-MM-DD HH:MM:SS"
    return (
      `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")} ` +
      `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}:${second.toString().padStart(2, "0")}`
    );
  };

  const handleThemeChange = (event: SelectChangeEvent) => {
    const selectedTheme = event.target.value;
    setColorThemeMode(selectedTheme);
    Cookies.set("colorThemeMode", selectedTheme);
  };

  if (!sessionData) {
    return (
      <div className="loading-overlay">
        <img
          src="/loading.gif"
          alt="Loading session data..."
          className="loading-gif"
        />
      </div>
    );
  }

  if (!host) {
    return (
      <div className="loading-overlay">
        <img
          src="/loading.gif"
          alt="Loading host data..."
          className="loading-gif"
        />
      </div>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: 4,
        borderRadius: 2,
        maxWidth: 600,
        margin: "0 auto",
        position: "relative", // Added position relative for loading overlay
        zIndex: 1, // Ensure content is above any other elements
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Session: {sessionData.sessionName}
      </Typography>

      <Typography variant="body1">
        <strong>Host:</strong> {host.nickName}
      </Typography>

      <Typography variant="body1">
        <strong>Guests:</strong>{" "}
        {Object.values(sessionData.guests)
          .map((guest) => guest.nickName)
          .join(", ") || "None"}
      </Typography>

      <Typography variant="body1">
        <strong>Allowed Genres:</strong>{" "}
        {sessionData.allowedGenres.length > 0
          ? sessionData.allowedGenres.join(", ")
          : "None"}
      </Typography>

      <Typography variant="body1">
        <strong>Allow Explicit Content:</strong>{" "}
        {sessionData.allowExplicit ? "Yes" : "No"}
      </Typography>

      <FormControlLabel
        control={
          <Switch
            checked={sessionData.notificationSettings[user]}
            onChange={(e) => handleChangeNotificationSettings(e.target.checked)}
          />
        }
        label="Toggle Notifications"
        sx={{ mt: 2 }}
      />

      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel>Color Theme</InputLabel>
        <Select
          value={colorThemeMode}
          onChange={handleThemeChange}
          label="Color Theme"
        >
          <MenuItem value="standard">Standard Mode</MenuItem>
          <MenuItem value="dynamic">Dynamic Mode</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 2 }}>
        <Button variant="contained" color="primary" onClick={openInviteOptions}>
          Invite Options
        </Button>

        {Cookies.get("userID") === sessionData.hostUserId && (
          <Button
            variant="contained"
            color="primary"
            onClick={openSessionSettings} // Open Session Settings modal
          >
            Session Settings
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={onClose} // Close Session Details modal
        >
          Back to Session
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLeaveSession}
        >
          Leave Session
        </Button>
      </Box>
      {/* Session Settings Modal within Session Details */}
      <Dialog
        open={isSessionSettingsOpen}
        onClose={closeSessionSettings}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Session Settings</DialogTitle>
        <DialogContent>
          <SessionSettings onClose={closeSessionSettings} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isInviteOptionsOpen}
        onClose={closeInviteOptions}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Invite Options</DialogTitle>
        <DialogContent>
          <InviteOptions onClose={closeInviteOptions} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SessionDetails;
