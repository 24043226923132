import React, { createContext, useContext, useRef, useState } from "react";
import Cookies from "js-cookie";
import getTheme from "./theme";
import { Theme } from "@mui/material";

interface ThemeContextProps {
  mode: "light" | "dark";
  toggleMode: () => void;
  globalTheme: React.MutableRefObject<Theme>;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mode, setMode] = useState<"light" | "dark">(() => {
    const cookieMode = Cookies.get("themeMode");
    return cookieMode === "dark" ? "dark" : "light"; // Default to "light" mode
  });
  const theme = useRef<Theme>(getTheme("light"));

  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    Cookies.set("themeMode", newMode, { expires: 365 });
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleMode, globalTheme: theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }
  return context;
};