import React from 'react';
import { useSearchParams } from 'react-router-dom';
import '../styles/ErrorPage.css';

export default function ErrorSpotify() {
  const [searchParams] = useSearchParams();
  const error: string | null = searchParams.get("error");
  const state: string | null = searchParams.get("state");
  return (
    <div className='container'>
      <h2>Spotify error</h2>
      <footer>
        <table>
          <tr>
            <th>Error</th>
            <th>State</th>
          </tr>
          <tr>
            <td>{error === null ? "Unknown" : error}</td>
            <td>{state === null ? "Unknown" : state}</td>
          </tr>
        </table>
      </footer>
    </div>
  );
}
