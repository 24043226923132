// src/pages/Session.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import Queue, { QueueHandle } from "../components/Queue";
import SearchBar, { SearchBarHandle } from "../components/SearchBar";
import LastFewSongsPlayed from "../components/LastFewSongsPlayed";
import CurrentSong, { CurrentSongHandle } from "../components/CurrentSong";
import Cookies from "js-cookie";
import StudyModeControl from "../components/StudyModeControl"; // Import the new StudyModeControl component
import PTAAppleMusicPlayer from "../components/PTAAppleMusicPlayer";
import PTASpotifyPlayer from "../components/PTASpotifyPlayer";
import PTATidalPlayer from "../components/PTATidalPlayer";
import { PTAPlayerHandle } from "../PTAPlayerHandle";
import PTAYTPlayer from "../components/PTAYTPlayer";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  ToggleButtonGroup,
  ToggleButton,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Table,
  Theme,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import globalTheme from "../theme";
import AlbumIcon from '@mui/icons-material/Album';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useThemeContext } from "../ThemeContext";
import { ThemeColors } from "../kmeansclustering";

import SessionDetails from "./SessionDetails"; // Import SessionDetails component
import SessionSettings from "./SessionSettings"; // Import SessionSettings component

interface UserAnalytics {
  sessionId: string;
  recommendedSongs: string[];
}

interface User {
  id: string;
  name: string;
  voteCount: number;
  isPopular: boolean;
}

type ThemeMode = "default" | "album" | "auto";

const invertHex = (hex: string) => { // modified from https://stackoverflow.com/a/54569758
  return "#" + (Number(`0x1${hex.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
};

// Vibing
const getVibe = async (): Promise<string> => {
  try {
    const sessionId = Cookies.get("sessionID");
    const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-vibe", {
      headers: {
        "sessionID" : sessionId || "",
      }
    });

    if (response.ok) {
      const lastFewSongsPlayedObject = await response.json();
      console.log(lastFewSongsPlayedObject.vibe)
      if (lastFewSongsPlayedObject.vibe) {
        return lastFewSongsPlayedObject.vibe;
      } else {
        console.log("failed to get a valid object response");
        return "";
      }
    }
    return "";
  } catch (error) {
    console.error("Error fetching vibe: " + error);
    return "";
  }
};

const getVibeThemeColors = (vibe: string): ThemeColors => {
  if (vibe === "Pop") {
    return {
      primary: "#FF69B4", // Pink
      primaryAlt: invertHex("#FF69B4"),
      secondary: "#FFD700", // Yellow
      secondaryAlt: invertHex("#FFD700"),
      background: "#87CEEB", // Sky Blue
      backgroundPaper: invertHex("#87CEEB"),
      text: "#E6E6FA", // Lavender
      textSecondary: invertHex("#E6E6FA")
    };
  } else if (vibe === "Hip-Hop") {
    return {
      primary: "#000000", // Black
      primaryAlt: invertHex("#000000"),
      secondary: "#FFD700", // Gold
      secondaryAlt: invertHex("#FFD700"),
      background: "#8B0000", // Deep Red
      backgroundPaper: invertHex("#8B0000"),
      text: "#708090", // Slate Gray
      textSecondary: invertHex("#708090")
    };
  } else if (vibe === "Rock") {
    return {
      primary: "#DC143C", // Crimson
      primaryAlt: invertHex("#DC143C"),
      secondary: "#36454F", // Charcoal Gray
      secondaryAlt: invertHex("#36454F"),
      background: "#7DF9FF", // Electric Blue
      backgroundPaper: invertHex("#7DF9FF"),
      text: "#301934", // Dark Purple
      textSecondary: invertHex("#301934")
    };
  } else if (vibe === "Jazz") {
    return {
      primary: "#800020", // Burgundy
      primaryAlt: invertHex("#800020"),
      secondary: "#1B3A57", // Deep Blue
      secondaryAlt: invertHex("#1B3A57"),
      background: "#FFD700", // Gold
      backgroundPaper: invertHex("#FFD700"),
      text: "#FFFFF0", // Ivory
      textSecondary: invertHex("#FFFFF0")
    };
  } else if (vibe === "Classical") {
    return {
      primary: "#FFFFFF", // White
      primaryAlt: invertHex("#FFFFFF"),
      secondary: "#F9E79F", // Light Gold
      secondaryAlt: invertHex("#F9E79F"),
      background: "#228B22", // Forest Green
      backgroundPaper: invertHex("#228B22"),
      text: "#F5F5DC", // Soft Beige
      textSecondary: invertHex("#F5F5DC")
    };
  } else if (vibe === "Electronic") {
    return {
      primary: "#39FF14", // Neon Green
      primaryAlt: invertHex("#39FF14"),
      secondary: "#7DF9FF", // Electric Blue
      secondaryAlt: invertHex("#7DF9FF"),
      background: "#FF69B4", // Hot Pink
      backgroundPaper: invertHex("#FF69B4"),
      text: "#000000", // Black
      textSecondary: invertHex("#000000")
    };
  } else if (vibe === "Country") {
    return {
      primary: "#A0522D", // Brown
      primaryAlt: invertHex("#A0522D"),
      secondary: "#808000", // Olive Green
      secondaryAlt: invertHex("#808000"),
      background: "#FFD700", // Mustard Yellow
      backgroundPaper: invertHex("#FFD700"),
      text: "#87CEEB", // Sky Blue
      textSecondary: invertHex("#87CEEB")
    };
  } else if (vibe === "R&B") {
    return {
      primary: "#4B0082", // Deep Purple
      primaryAlt: invertHex("#4B0082"),
      secondary: "#191970", // Midnight Blue
      secondaryAlt: invertHex("#191970"),
      background: "#B76E79", // Rose Gold
      backgroundPaper: invertHex("#B76E79"),
      text: "#FAD6A5", // Champagne
      textSecondary: invertHex("#FAD6A5")
    };
  } else if (vibe === "Reggae") {
    return {
      primary: "#FF0000", // Rasta Red
      primaryAlt: invertHex("#FF0000"),
      secondary: "#FFD700", // Bright Yellow
      secondaryAlt: invertHex("#FFD700"),
      background: "#008000", // Forest Green
      backgroundPaper: invertHex("#008000"),
      text: "#00CED1", // Caribbean Blue
      textSecondary: invertHex("#00CED1")
    };
  } else if (vibe === "Blues") {
    return {
      primary: "#000080", // Navy Blue
      primaryAlt: invertHex("#000080"),
      secondary: "#4682B4", // Steel Gray
      secondaryAlt: invertHex("#4682B4"),
      background: "#4B0082", // Indigo
      backgroundPaper: invertHex("#4B0082"),
      text: "#F8F8FF", // Dusty White
      textSecondary: invertHex("#F8F8FF")
    };
  } else {
    return {
      primary: "#000000", // Default: Black
      primaryAlt: invertHex("#000000"),
      secondary: "#FFFFFF", // Default: White
      secondaryAlt: invertHex("#FFFFFF"),
      background: "#808080", // Default: Gray
      backgroundPaper: invertHex("#808080"),
      text: "#000000", // Default: Black
      textSecondary: invertHex("#000000")
    };
  }
};

const getThemeFromThemeColors = (themeColors: ThemeColors): Theme => {
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: themeColors.primary,
        contrastText: themeColors.primaryAlt,
      },
      secondary: {
        main: themeColors.secondary,
        contrastText: themeColors.secondaryAlt,
      },
      background: {
        default: themeColors.background,
        paper: themeColors.backgroundPaper,
      },
      text: {
        primary: themeColors.text,
        secondary: themeColors.textSecondary,
      },
    },
    typography: {
      fontFamily: 'CustomFont, Arial, sans-serif',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
      fontWeightBold: 900,
      h1: {
        fontWeight: 900,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 400,
      },
      h6: {
        fontWeight: 400,
      },
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      button: {
        fontWeight: 700,
        textTransform: 'none',
      },
    },
  });
};

const getVibeTheme = async (): Promise<Theme> => {
  return getThemeFromThemeColors(getVibeThemeColors(await getVibe()));
};

const Session: React.FC = () => {
  const queueRef = useRef<QueueHandle | null>(null);
  const currentSongRef = useRef<CurrentSongHandle | null>(null);
  const searchBarRef = useRef<SearchBarHandle | null>(null);
  const [isHost, setIsHost] = useState(false);
  const [sessionData, setSessionData] = useState<any>(null);
  const [showUsers, setShowUsers] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [pastSongs, setPastSongs] = useState<{ [key: string]: UserAnalytics }>({});
  const [showPastSongs, setShowPastSongs] = useState(false);
  const navigate = useNavigate();

  const { globalTheme } = useThemeContext();
  const [theme, setTheme] = useState<Theme>(globalTheme.current);
  const [albumTheme, setAlbumTheme] = useState(globalTheme.current);
  const [autoTheme, setAutoTheme] = useState(globalTheme.current);
  const [viewLyrics, setViewLyrics] = useState("false");
  const [colorThemeMode, setColorThemeMode] = useState<string>('standard');

  // New state variables for modals
  const [isSessionDetailsOpen, setSessionDetailsOpen] = useState(false);

  // Function to open Session Details modal
  const openSessionDetails = () => {
    setSessionDetailsOpen(true);
  };

  // Function to close Session Details modal
  const closeSessionDetails = () => {
    setSessionDetailsOpen(false);
    const themeModeCookie = Cookies.get('colorThemeMode') || 'standard';
    setColorThemeMode(themeModeCookie);
  };

  useEffect(() => {
    const themeModeCookie = Cookies.get('colorThemeMode') || 'standard';
    setColorThemeMode(themeModeCookie);
  }, []);

  useEffect(() => {
    if (queueRef.current !== null && searchBarRef.current !== null) {
      searchBarRef.current.addClickHandler(queueRef.current.fetchSession);
      console.warn("Added handler");
    }
  }, [queueRef, searchBarRef]);

  const ref = useRef<PTAPlayerHandle | null>(null);
  const [comp, setComp] = useState(<></>);
  const [mode, setMode] = useState("none");
  const [musicVolume, setMusicVolume] = useState(1);
  const [isStudyMode, setIsStudyMode] = useState(false);

  useEffect(() => {
    if (isHost) {
      console.warn("Setting comp");
      if (mode === "spotify") setComp(<PTASpotifyPlayer ref={ref} />);
      else if (mode === "apple") setComp(<PTAAppleMusicPlayer ref={ref} />);
      else if (mode === "tidal") setComp(<PTATidalPlayer ref={ref} />);
      else if (mode === "yt") setComp(<PTAYTPlayer ref={ref} />);
      else if (mode === "none") {
        setComp(<></>);
        ref.current = null;
      }
    }
  }, [mode, isHost]);

  useEffect(() => {
    const mod = Cookies.get("mode");
    if (mod === "spotify" && mode !== "spotify") setMode("spotify");
    else if (mod === "apple" && mode !== "apple") setMode("apple");
    else if (mod === "tidal" && mode !== "tidal") setMode("tidal");
    else if (mod === "yt" && mode !== "yt") setMode("yt");
    else if (mod === "none" && mode !== "none") setMode("tidal");
  }, [mode]);

  useEffect(() => {
    handleIsHost();
    handleViewLyrics();
  }, []);

  useEffect(() => {
    const fetchSessionData = async () => {
      const sessionId = Cookies.get("sessionID");
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/session/" + sessionId
        );
        if (response.ok) {
          const sessionData = await response.json();
          setIsStudyMode(sessionData.isStudyMode);
        } else {
          console.error("Failed to fetch session data.");
        }
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, []);

  const handleIsHost = useCallback(async () => {
    try {
      const sessionId = Cookies.get("sessionID");
      const userId = Cookies.get("userID");
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/isHostView",
        {
          method: "POST",
          headers: {
            sessionID: sessionId || "",
            userID: userId || "",
          },
        }
      );
      if (response.ok) {
        const h = (await response.text()) === "true";
        setIsHost(h);
      } else {
        console.error("Failed to check host status: ", response);
      }
    } catch (error) {
      console.error("Error checking host status:", error);
    }
  }, []);

  const handleViewLyrics = async () => {
    console.log("Check lyrics status");
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/get-lyrics-status?sessionId=" +
          Cookies.get("sessionID")
      );

      if (response.ok) {
        const lyricsStatus = await response.text();
        setViewLyrics(lyricsStatus);
      } else {
        console.error("Failed to check lyrics status: ", response);
      }
    } catch (error) {
      console.error("Error checking lyrics status: ", error);
    }
  };

  const fetchSessionUsers = async () => {
    const sessionID = Cookies.get("sessionID");

    if (!sessionID) {
      console.error("Session ID not found in cookies.");
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/session/users",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
          },
        }
      );

      if (response.ok) {
        const usersData = await response.json();
        console.log("Fetched session users:", usersData);
        setUsers(usersData);
      } else {
        const errorText = await response.text();
        console.error("Failed to fetch session users:", errorText);
      }
    } catch (error) {
      console.error("Error fetching session users:", error);
    }
  };

  const toggleUserDropdown = () => {
    if (!showUsers) fetchSessionUsers();
    setShowUsers(!showUsers);
  };

  const viewPastSongs = async () => {
    const username = Cookies.get("userID");
    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/get-past-songs?user=" +
          username
      );

      if (response.ok) {
        const pastSongsData = await response.json();
        console.log(pastSongsData);
        setPastSongs(pastSongsData);
        setShowPastSongs(true);
      } else {
        console.error("Failed to fetch past songs:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Theming
  const [themeMode, setThemeMode] = useState<ThemeMode>("default");
  const handleThemeMode = (
    event: React.MouseEvent<HTMLElement>,
    newThemeMode: ThemeMode,
  ) => {
    setThemeMode(newThemeMode);
  };

  useEffect(() => {
    if (colorThemeMode === 'dynamic') {
      if (themeMode === "default") setTheme(globalTheme.current);
      else if (themeMode === "album") setTheme(albumTheme);
      else if (themeMode === "auto") {
        getVibeTheme().then((vibeTheme) => {
          setTheme(vibeTheme);
        });
      } else setTheme(globalTheme.current);
    } else {
      setTheme(globalTheme.current);
    }
  }, [themeMode, albumTheme, colorThemeMode]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 2 }}>
        <Button
          variant="contained"
          sx={{
            position: 'absolute',
            top: 80, // Adjust this value to move the button below the nav bar
            right: 16,
          }}
          onClick={openSessionDetails}
        >
          Session Menu
        </Button>
        <CurrentSong player={ref} ref={currentSongRef} setTheme={setAlbumTheme} viewLyrics={viewLyrics} />
        <LastFewSongsPlayed />

        <Queue
          ref={queueRef}
          player={ref}
          currentSongHandle={currentSongRef.current!}
          viewLyrics={viewLyrics}
        />
        {colorThemeMode === 'dynamic' && (
          <ToggleButtonGroup
            value={themeMode}
            exclusive
            onChange={handleThemeMode}
            aria-label="theme mode"
          >
            <ToggleButton value="album" aria-label="album theme mode">
              <AlbumIcon />
            </ToggleButton>
            <ToggleButton value="auto" aria-label="auto-vibe theme mode">
              <AutoAwesomeIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <SearchBar ref={searchBarRef} queueHandle={queueRef.current!} />
        {isHost && <StudyModeControl player={ref} />}
        {comp}

        <Button variant="contained" onClick={toggleUserDropdown} sx={{ mt: 2 }}>
          {showUsers ? "Hide Users" : "View Session Users"}
        </Button>

        {isHost && showUsers && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Session Users</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Vote Count</b>
                  </TableCell>
                  <TableCell>
                    <b>Popularity</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.voteCount}</TableCell>
                    <TableCell>
                      {user.isPopular ? (
                        <span
                          style={{
                            color: "gold",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          title={`${user.voteCount} votes`}
                        >
                          ⭐
                        </span>
                      ) : (
                        "—"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
        {/* Session Details Modal */}
        <Dialog
          open={isSessionDetailsOpen}
          onClose={closeSessionDetails}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Session Details</DialogTitle>
          <DialogContent>
            <SessionDetails
              onClose={closeSessionDetails}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Session;