import React, { Dispatch, SetStateAction, useCallback, useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { AccessToken, SpotifyApi } from '@spotify/web-api-ts-sdk';
import SpotifyPlayer, { SpotifyWebPlaybackErrorType } from 'spotify-web-playback';
import { useSearchParams } from 'react-router-dom';
import { useSpotify } from '../useSpotify';
import { PTAPlayerHandle } from '../PTAPlayerHandle';
import { NOT_PLAYING_SONG, PTASong } from '../PTASong';
import { PlaybackMode, updatePlaybackState } from '../PlaybackState';
import Cookies from 'js-cookie';

// declare global {
//   interface Window {
// 		onSpotifyWebPlaybackSDKReady: any;
//   }
// }

const clientId = 'c70437d889f141af9a5d7f10d9bfa636';
const redirectUri = import.meta.env.VITE_API_BASE_URL + '/';

const authUrl = new URL("https://accounts.spotify.com/authorize")

const scope = "streaming playlist-read-private playlist-read-collaborative user-library-read user-read-email user-read-private";
const scopes = scope.split(" ");

const PTASpotifyPlayer = forwardRef<PTAPlayerHandle>(function PTASpotifyPlayer(props, ref) {
  const [searchParams] = useSearchParams();
	// const [sdk, setSdk]: [SpotifyApi | null, Dispatch<SetStateAction<SpotifyApi | null>>] = useState<SpotifyApi | null>(null);
	const [accessToken, setAccessToken] = useState('');
	const [expiresAt, setExpiresAt] = useState(0);
	const [player, setPlayer]: [SpotifyPlayer | null, Dispatch<SetStateAction<SpotifyPlayer | null>>] = useState<SpotifyPlayer | null>(null);
	const currentSong = useRef<PTASong>(NOT_PLAYING_SONG);
	const lastTimeEnded = useRef(0);
	const timerID = useRef<number | undefined>(undefined);
	const sessionID = useRef<string | null>(null);

	useEffect(() => {
		const sessID = Cookies.get("sessionID");
		if (sessID !== undefined) sessionID.current = sessID;
	}, [document.cookie]);

	const generateRandomString = useCallback((length: number) => {
		return "A".repeat(length);
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const values = crypto.getRandomValues(new Uint8Array(length));
		return values.reduce((acc, x) => acc + possible[x % possible.length], "");
	}, []);

	const sha256 = useCallback(async (plain: string) => {
		const encoder = new TextEncoder()
		const data = encoder.encode(plain)
		return window.crypto.subtle.digest('SHA-256', data)
	}, []);

	const base64encode = useCallback((input: ArrayBuffer) => {
		return btoa(String.fromCharCode(...new Uint8Array(input)))
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_');
	}, []);

	const getNewAuth = useCallback(() => {
		const codeVerifier  = generateRandomString(64);
		sha256(codeVerifier).then((hashed) => {
			const codeChallenge = base64encode(hashed);
			localStorage.setItem('code_verifier', codeVerifier);
			const params = {
				response_type: 'code',
				client_id: clientId,
				scope,
				redirect_uri: import.meta.env.VITE_API_BASE_URL + '/callback/spotify',
				code_challenge_method: "S256",
				code_challenge: codeChallenge
			};
			authUrl.search = new URLSearchParams(params).toString();
			window.location.href = authUrl.toString();
		});
	}, []);

	useEffect(() => {
		if (accessToken.length !== 0 && player !== null) {
			if (!player.ready) player.connect(accessToken);
			console.log("Adding listener");
			player.removeListener('state');
			player.addListener('state', (event) => {
				if (sessionID.current !== null && event !== null) {
					if (
						event.position === 0 &&
						event.paused === true &&
						event.track_window.previous_tracks?.findIndex((track) => track.id === event.track_window.current_track.id) !== -1
					) {
						if ((Date.now() - lastTimeEnded.current) > 700) {
							// Track finished or skipped, play next track
							updatePlaybackState({
								sessionID: sessionID.current,
								state: {
									current: NOT_PLAYING_SONG,
									currentTimeMillis: 0,
									playing: PlaybackMode.STOP
								}
							});
							currentSong.current = NOT_PLAYING_SONG
							lastTimeEnded.current = Date.now();
						}
					} else {
						if (event.position !== 0 || !event.paused) {
							console.log(event.position, event.paused, event.track_window.previous_tracks?.findIndex((track) => track.id === event.track_window.current_track.id));
							updatePlaybackState({
								sessionID: sessionID.current,
								state: {
									current: currentSong.current,
									currentTimeMillis: event.position * 1000,
									playing: event.paused ? PlaybackMode.PAUSE : PlaybackMode.PLAY
								}
							});
						}
					}
				}
			});
			clearInterval(timerID.current);
			setInterval(() => {
				if (sessionID.current !== null && window.location.pathname.includes("/session")) {
					updatePlaybackState({
						sessionID: sessionID.current!,
						state: {
							current: currentSong.current,
							currentTimeMillis: player.position,
							playing: currentSong.current.songId === "NotPlaying" ? PlaybackMode.STOP : (player.playing ? PlaybackMode.PLAY : PlaybackMode.PAUSE)
						}
					});
				}
			}, 1000);
			console.log("Added listener DONE");
		}
	}, [accessToken, player]);

	useEffect(() => {
		if (player === null) {
			setPlayer(new SpotifyPlayer("PassTheAux", 1.0));
		}
		const at = searchParams.get('at');
		if (at !== null) {
			setAccessToken(at);
			setExpiresAt(parseInt(searchParams.get('ea')!, 10));
			localStorage.setItem('access_token', searchParams.get('at')!);
			localStorage.setItem('refresh_token', searchParams.get('rt')!);
			localStorage.setItem('expires_at', searchParams.get('ea')!);
			Cookies.set("mode", "spotify");
		} else {
			const lAT = localStorage.getItem('access_token');
			const lRT = localStorage.getItem('refresh_token');
			const lEA = localStorage.getItem('expires_at');
			if (lAT !== null && Date.now() < Number(lEA)) {
				// Have a previously acquired token that is valid
				setAccessToken(lAT);
				setExpiresAt(Number(lEA));
				fetch(import.meta.env.VITE_API_BASE_URL + "/api/spotifytoken", {
					method: "POST",
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						access_token: lAT,
						refresh_token: lRT,
						expires_at: lEA
					})
				}).then(() => {
					Cookies.set("mode", "spotify");
				})
			} else {
				// Need to do new auth
				console.log("YOU get a NEW AUTH and YOU get a NEW AUTH and YOU get a NEW AUTH");
				getNewAuth();
			}
		}
	}, [searchParams, player]);

	const getRefreshToken = useCallback(async () => {

		// refresh token that has been previously stored
		console.log("Refreshing token");
		const refreshToken = localStorage.getItem('refresh_token');
 
		if (refreshToken !== null) {
			const payload = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					grant_type: 'refresh_token',
					refresh_token: refreshToken,
					client_id: clientId
				}),
			}
			const body = await fetch("https://accounts.spotify.com/api/token", payload);
			const response = await body.json();
	
			if (!response.error) {
				if (response.access_token) {
					localStorage.setItem('access_token', response.access_token);
					setAccessToken(response.access_token);
				}
				if (response.refreshToken) {
					localStorage.setItem('refresh_token', response.refreshToken);
					localStorage.setItem('expires_at', String((Number(response.expires_in) * 1000) + Date.now()));
					setExpiresAt((Number(response.expires_in) * 1000) + Date.now());
				}
				console.log("Refresh attempt complete");
				console.log(response);
			} else {
				console.error("Refresh token revoked?");
				console.error(response);
			}
		}
	}, []);

	const checkToken = useCallback(() => {
		return;
		// if (Date.now() >= expiresAt) return getRefreshToken();
		// else return async () => { return; }
	}, []);

	const playSong = useCallback(async (newSong: PTASong) => {
		await checkToken();
		if (player !== null) {
			player.play("spotify:track:" + newSong.songId);
			if (sessionID.current !== null) {
				updatePlaybackState({
					state: {
						currentTimeMillis: 0,
						playing: PlaybackMode.PLAY,
						current: newSong
					},
					sessionID: sessionID.current
				});
			}
			currentSong.current = newSong;
			console.log("Playing song " + newSong.songId);
			return;
		}
	}, [player, expiresAt, sessionID]);
	const isPlaying = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			return player.playing;
		} else return false;
	}, [player, expiresAt]);
	const togglePlaying = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			if (player.playing) {
				return player.pause().then(() => { return false; });
			} else {
				return player.play().then(() => { return true; });
			}
		} else return false;
	}, [player, expiresAt]);
	const play = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			player.play();
		}
	}, [player, expiresAt]);
	const pause = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			player.pause();
		}
	}, [player, expiresAt]);
	const seek = useCallback(async (position: number) => {
		await checkToken();
		if (player !== null) {
			player.seek(position);
		}
	}, [player, expiresAt]);
	const next = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			player.next();
		}
	}, [player, expiresAt]);
	const previous = useCallback(async () => {
		await checkToken();
			if (player !== null) {
				player.previous();
			}
	}, [player, expiresAt]);
	const getPosition = useCallback(async () => {
		await checkToken();
		if (player !== null) {
			return player.position * 1000;
		} else return -1;
	}, [player, expiresAt]);
	const getCurrentSong = useCallback(() => {
		return currentSong.current;
	}, []);
	const setVolume = useCallback(async (level: number) => {
		if (level !== volume.current) {
			volume.current = level;
			player?.setVolume(level);
		}
	}, [player]);
	const volume = useRef(100.0);
	const getVolume = useCallback(() => {
		return volume.current;
	}, []);

	useImperativeHandle(ref, () => {
		return {
			playSong,
			isPlaying,
			togglePlaying,
			play,
			pause,
			seek,
			next,
			previous,
			getPosition,
			getCurrentSong,
			setVolume,
			getVolume
		};
	});

	return <></>;
});

export default PTASpotifyPlayer;
