import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaSearch } from "react-icons/fa";
import Cookies from "js-cookie"; // Assuming you are storing session and user info in cookies
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PTASong } from "../PTASong";
import Queue, { QueueHandle } from "./Queue";

type AlertSeverity = "error" | "info" | "success" | "warning";

export type SearchBarHandle = {
  addClickHandler: (clickHandler: () => void) => void;
};

// CODE IM TRYING
type SearchBarProps = {
  queueHandle?: QueueHandle;
};

interface SongWaitTimeResponse {
  songID: string;
  waitTime: number;
  lastPlayed: string;
  timeLeft: number;
}

const SearchBar = forwardRef<SearchBarHandle, SearchBarProps>(
  ({ queueHandle }, ref) => {
    const [input, setInput] = useState<string>("");
    const [artistInput, setArtistInput] = useState<string>("");
    const [results, setResults] = useState<PTASong[]>([]); // Updated to store song objects
    const [clickHandlers, setClickHandlers] = useState<(() => void)[]>([]);
    const [isHost, setIsHost] = useState<boolean>(false);
    const [blockedSongs, setBlockedSongs] = useState<PTASong[]>([]);
    const modeRef = useRef("MANUAL");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] =
      useState<AlertSeverity>("success");
    /*
    const addClickHandler = useCallback((clickHandler: (() => void)) => {
      clickHandlers.push(clickHandler);
      console.warn(clickHandler);
      console.warn("Added click handler");
    }, [clickHandlers]);
    */
    const addClickHandler = useCallback((clickHandler: () => void) => {
      setClickHandlers((prevHandlers) => [...prevHandlers, clickHandler]); // Update to correctly append handlers
    }, []);

    // Fetch blcoked songs every 5 seconds
    useEffect(() => {
      const intervalId = setInterval(() => {
        getBlockedSongs();
      }, 1000); // 1 second interval

      return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, []); // This effect will only run once (when the component mounts)

    useEffect(() => {
      // Check host status only once when the component mounts
      (async () => {
        getMode(); // Store the result in the state
        getBlockedSongs(); // Get blocked songs
        handleIsHost();
      })();
    }, []); // This effect will also run once (when the component mounts)

    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };

    const showSnackbar = (message: string, severity: AlertSeverity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };

    const submitSearchQuery = useCallback(
      (query: string) => {
        console.warn(modeRef.current);
        setResults(() => []);
        if (modeRef.current === "AUTOMATIC") {
          // Existing API call for "AUTOMATIC" mode
          fetch(
            import.meta.env.VITE_API_BASE_URL + "/api/search?query=" + query
          )
            .then((res) => res.json())
            .then((val) => {
              if (val.error) {
                setResults([]);
              } else {
                setResults(val);
                // Assuming the API returns a list of songs
              }
            })
            .catch((err) => {
              console.error("Error during search:", err);
            });
        } else if (modeRef.current === "MANUAL") {
          // New API call for "MANUAL" mode using "discog-search"
          const queryParams = new URLSearchParams();
          queryParams.append("song", input || ""); // Append song query
          queryParams.append("artist", artistInput || "");
          fetch(
            import.meta.env.VITE_API_BASE_URL +
              "/api/discogs-search?" +
              queryParams.toString()
          )
            .then((res) => res.json())
            .then((val) => {
              if (val.error) {
                setResults([]);
              } else {
                setResults(val); // Assuming the API returns a list of songs
              }
            })
            .catch((err) => {
              console.error("Error during Discogs search:", err);
            });
        }
      },
      [input, artistInput]
    );

    useEffect(() => {
      submitSearchQuery("");
    }, []);

    const getMode = useCallback(async () => {
      try {
        const sessionId = Cookies.get("sessionID");
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-session",
          {
            headers: {
              sessionID: sessionId || "",
              userID: Cookies.get("userID") || "",
            },
          }
        );
        if (response.ok) {
          const sessionData = await response.json();
          if (sessionData && sessionData.mode) {
            modeRef.current = sessionData.mode;
            console.log(sessionData.mode);
          } else {
            console.log("Cant get session mode");
          }
          return;
        } else {
          console.log("Cant get session from fetch sesh");
        }
        return;
      } catch (error) {
        console.error("Error fetching queue:", error);
        return;
      }
    }, []);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        setInput(value);
        setArtistInput(value);
        //submitSearchQuery(value); // Trigger search on input change
      },
      []
    );

    const handleManualModeInputChange = useCallback(
      (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        field: "song" | "artist"
      ) => {
        const value = e.target.value;
        if (field === "song") {
          console.log("setting song value from search: " + value);
          setInput(value);
        } else {
          console.log("setting artist value from search");
          setArtistInput(value);
        }
      },
      []
    );

    const handleIsHost = useCallback(async () => {
      try {
        const sessionId = Cookies.get("sessionID");
        const userId = Cookies.get("userID");
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/isHostView",
          {
            method: "POST", // Add this line to use POST method
            headers: {
              sessionID: sessionId || "",
              userID: userId || "",
            },
          }
        );
        if (response.ok) {
          const h = (await response.text()) === "true";
          setIsHost(h);
        } else {
          console.error("Failed to check host status");
        }
      } catch (error) {
        console.error("Error checking host status:", error);
      }
    }, []);

    // Function to add song to the backend
    const handleAddSong = useCallback(
      async (song: PTASong) => {
        try {
          const sessionId = Cookies.get("sessionID"); // Assuming cookies store session ID
          const userId = Cookies.get("userID");

          const response = await fetch(
            import.meta.env.VITE_API_BASE_URL + "/api/get-song-wait-time",
            {
              method: "GET",
              headers: {
                sessionID: sessionId || "",
                songID: song.songId,
              },
            }
          );
          console.log("checking response");
          if (response.ok) {
            const data: SongWaitTimeResponse = await response.json();
            console.log("Song Wait Time Response:", data);
            console.log(data.timeLeft);
            console.log(data.lastPlayed);
            if (data.timeLeft > 0 && !isHost) {
              showSnackbar(
                `Please wait ${data.timeLeft} more minutes before playing this song again.\nThis song was last played at ${data.lastPlayed}`,
                "warning"
              );
            } else {
              try {
                const requestBody = {
                  sessionId: sessionId,
                  userId: userId,
                  songId: song.songId,
                  title: song.title,
                  artist: song.artist,
                  genre: song.genre,
                  url: song.url,
                  albumArtURL: song.albumArtURL,
                  durationMs: song.durationMs,
                };

                const response = await fetch(
                  import.meta.env.VITE_API_BASE_URL + "/api/add-song",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestBody),
                  }
                );

                if (response.ok) {
                  console.log("Song added successfully!");
                  // ADD HERE
                  clickHandlers.forEach((item) => {
                    item();
                  });
                  queueHandle?.fetchSession();
                  // Optionally, fetch the updated queue or trigger a re-render
                } else {
                  showSnackbar("Song already in Queue", "warning");
                  console.error("Failed to add song", await response.text());
                }
              } catch (error) {
                console.error("Error during song addition:", error);
              }
            }
          } else {
            console.error("failed to fetch songwait time object!");
          }
        } catch (error) {
          console.error("Unexpected error:", error);
        }
      },
      [isHost]
    );

    const handleBlockSong = useCallback(async (song: PTASong) => {
      try {
        const sessionId = Cookies.get("sessionID");
        const userId = Cookies.get("userID");

        const requestBody = {
          sessionId: sessionId,
          userId: userId,
          songId: song.songId,
          title: song.title,
          artist: song.artist,
          genre: song.genre,
          url: song.url,
          albumArtURL: song.albumArtURL,
          durationMs: song.durationMs,
        };

        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/block-song",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          console.log("Song blocked successfully!");
          await getBlockedSongs();
        } else {
          console.error("Failed to block song", await response.text());
        }
      } catch (error) {
        console.error("Error during song blocking:", error);
      }
    }, []);

    const handleUnblockSong = useCallback(async (song: PTASong) => {
      try {
        const sessionId = Cookies.get("sessionID");
        const userId = Cookies.get("userID");

        const requestBody = {
          sessionId: sessionId,
          userId: userId,
          songId: song.songId,
          title: song.title,
          artist: song.artist,
          genre: song.genre,
          url: song.url,
          albumArtURL: song.albumArtURL,
          durationMs: song.durationMs,
        };

        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/unblock-song",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          console.log("Song unblocked successfully!");
          await getBlockedSongs();
        } else {
          console.error("Failed to unblock song", await response.text());
        }
      } catch (error) {
        console.error("Error during song unblocking:", error);
      }
    }, []);

    const getBlockedSongs = useCallback(async () => {
      try {
        const sessionId = Cookies.get("sessionID");
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-blocked-songs",
          {
            headers: {
              sessionID: sessionId || "",
            },
          }
        );
        if (response.ok) {
          const blockedSongs = await response.json();
          if (blockedSongs && blockedSongs.blockedSongs) {
            setBlockedSongs(blockedSongs.blockedSongs);
          }
        } else {
          console.error("Failed to fetch blocked songs");
        }
      } catch (error) {
        console.error("Error fetching blocked songs:", error);
      }
    }, []);

    useImperativeHandle(ref, () => {
      return {
        addClickHandler,
      };
    });

    const isSongBlocked = (song: PTASong): boolean => {
      return blockedSongs.some(
        (blockedSong) => blockedSong.songId === song.songId
      );
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        console.log("Searchquery: " + input);
        submitSearchQuery(input); // Call handleChange when Enter is pressed
      }
    };

    return (
      <Box sx={{ width: "100%", maxWidth: 600, mx: "auto", mt: 2 }}>
        {modeRef.current === "MANUAL" ? (
          <>
            <TextField
              fullWidth
              placeholder="Search song..."
              value={input}
              onChange={(e) => handleManualModeInputChange(e, "song")}
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              placeholder="Search artist..."
              value={artistInput}
              onChange={(e) => handleManualModeInputChange(e, "artist")}
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => submitSearchQuery("")}
            >
              Search
            </Button>
          </>
        ) : (
          <TextField
            fullWidth
            placeholder="Search to add a song..."
            value={input}
            onChange={(e) => handleChange(e)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
            }}
          />
        )}
        <List>
          {results.map((song: any) => (
            <ListItem key={song.songId} divider>
              <ListItemText primary={`${song.artist} - ${song.title}`} />
              {isHost ? (
                isSongBlocked(song) ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleUnblockSong(song)}
                  >
                    Unblock Song
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleAddSong(song);
                        clickHandlers.forEach((item) => {
                          item();
                        });
                      }}
                      sx={{ mr: 1 }}
                    >
                      Add Song
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleBlockSong(song)}
                    >
                      Block Song
                    </Button>
                  </>
                )
              ) : isSongBlocked(song) ? (
                <Typography variant="body2" color="textSecondary">
                  Song is blocked
                </Typography>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleAddSong(song);
                    clickHandlers.forEach((item) => {
                      item();
                    });
                  }}
                >
                  Add Song
                </Button>
              )}
            </ListItem>
          ))}
        </List>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
);

export default SearchBar;
