import { createTheme } from "@mui/material/styles";

const getTheme = (mode: "light" | "dark") =>
  createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#38386b", // Delft Blue
        contrastText: "#ffffff", // White text on primary buttons
      },
      secondary: {
        main: "#b07bac", // African Violet
        contrastText: "#ffffff", // White text on secondary buttons
      },
      background: {
        default: mode === "light" ? "#f3eaf4" : "#121212", // Magnolia for light, dark gray for dark
        paper: mode === "light" ? "#ffffff" : "#1e1e1e", // White for light, darker gray for dark
      },
      text: {
        primary: mode === "light" ? "#1e1d1e" : "#ffffff", // Eerie Black for light, white for dark
        secondary: mode === "light" ? "#38386b" : "#b0bec5", // Delft Blue for light, soft gray-blue for dark
      },
    },
    typography: {
      fontFamily: "CustomFont, Arial, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400, // Standard
      fontWeightMedium: 700,  // Bold
      fontWeightBold: 900,    // Black (Extra Bold)
      h1: {
        fontWeight: 900, // Use Black font weight
      },
      h2: {
        fontWeight: 700, // Use Bold font weight
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 400,
      },
      h6: {
        fontWeight: 400,
      },
      body1: {
        fontWeight: 400,
      },
      body2: {
        fontWeight: 400,
      },
      button: {
        fontWeight: 700,
        textTransform: "none", // Keep button text case as-is
      },
    },
  });

export default getTheme;
