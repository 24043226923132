import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import React from "react";
import { Box } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

type PieChartData = {
  label: string;
  value: number;
};

interface PieChartProps {
  data: PieChartData[];
}

const PieChart: React.FC<PieChartProps> = ({ data }) => {
  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#FF9F40",
          "#9966FF",
          "#FF66B2",
          "#FF3333",
          "#33FF99",
          "#6699FF",
          "#FFCC33",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#FF9F40",
          "#9966FF",
          "#FF66B2",
          "#FF3333",
          "#33FF99",
          "#6699FF",
          "#FFCC33",
        ],
      },
    ],
  };

  return (
    <Box>
      <Pie data={chartData} />
    </Box>
  );
};

export default PieChart;
