import React, { useEffect, useState } from 'react';
import '../styles/LoginButton.css'
import { Button } from '@mui/material';
import Cookies from 'js-cookie';

function YTLoginHandler() {
  console.info("Authing!");
  window.location.href = import.meta.env.VITE_API_BASE_URL + "/api/yt";
}

export default function YouTubeLoginButton() {
	const [isValid, setIsValid] = useState(false);
	useEffect(() => {
		if (Cookies.get("mode") === "yt") setIsValid(true);
		else setIsValid(false);
	}, [document.cookie]);
  return (
    <Button
      onClick={YTLoginHandler}
      variant="contained"
      color="primary"
      sx={{ textTransform: 'none' }}
    >
    {isValid ? "Using YouTube" : "YouTube Login"}
    </Button>
  );
}
