import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import GenreChart from "../components/PieChart";
import Leaderboard from "../components/Leaderboard";

type Song = {
  recommender: string;
  voteCount: number;
  songTitle: string;
  played: boolean;
};

type SessionStatistic = {
  sessionId: string;
  queuedSongs: Song[];
  playedSongs: Song[];
  topSongs: Song[];
  guests: string[];
  startTime: string;
  endTime: string;
  sessionTime: number;
  genreDistribution: Record<string, number>;
  leaderboard: string[] | null;
};

type StatisticsData = SessionStatistic[];

export default function SessionStatistics() {
  const navigate = useNavigate();
  const [data, setData] = useState<StatisticsData>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);
  const [showQueued, setShowQueued] = useState(false);
  const [showSessionData, setShowSessionData] = useState(true);
  const [showPlayed, setShowPlayed] = useState(false);
  const username = Cookies.get("userID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL +
            "/api/get-session-statistics" +
            "?user=" +
            username
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();

        if (!result || Object.keys(result).length === 0) {
          setData([]);
        } else {
          const sessions = Object.keys(result).map((sessionId) => {
            const sessionData = result[sessionId];
            return {
              sessionId,
              queuedSongs: sessionData.queuedSongs,
              playedSongs: sessionData.playedSongs,
              topSongs: sessionData.topSongs,
              guests: sessionData.guests,
              startTime: sessionData.startTime,
              endTime: sessionData.endTime,
              sessionTime: sessionData.sessionTime,
              genreDistribution: sessionData.genreDistribution,
              leaderboard: sessionData.leaderboard || [],
            };
          });

          setData(sessions);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError("Failed to fetch data: " + error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const goToNextSession = () => {
    setCurrentSessionIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const goToPreviousSession = () => {
    setCurrentSessionIndex(
      (prevIndex) => (prevIndex - 1 + data.length) % data.length
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        Loading...
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        {error}
      </Box>
    );
  }

  if (data.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        <Typography variant="h4">Your Session Analytics</Typography>
        <Typography sx={{ mt: 2 }} variant="body1">
          Looks like you have no session analytics...
        </Typography>
        <Typography variant="body1">
          Host a session to get some analytics!
        </Typography>

        <Button
          sx={{ mt: 1 }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/user-home");
          }}
        >
          Create a Session!
        </Button>

        <Button
          sx={{ mt: 5 }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/user-statistics");
          }}
        >
          User Analytics
        </Button>
      </Box>
    );
  }

  const currentSession = data[currentSessionIndex];
  const leaderboardData = currentSession?.leaderboard || [];

  const parsedLeaderboardData = leaderboardData.map((entry, index) => {
    const [songsPlayed, name] = entry.split(", ");
    return {
      songsPlayed: Number(songsPlayed),
      name,
      rank: index + 1,
    };
  });
  // .sort((a, b) => b.songsPlayed - a.songsPlayed);

  const pieData = Object.keys(currentSession.genreDistribution).map(
    (genre) => ({
      label: genre,
      value: currentSession.genreDistribution[genre],
    })
  );

  const mostPopularGenre = Object.keys(currentSession.genreDistribution).reduce(
    (maxGenre, genre) => {
      return currentSession.genreDistribution[genre] >
        currentSession.genreDistribution[maxGenre]
        ? genre
        : maxGenre;
    },
    Object.keys(currentSession.genreDistribution)[0]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Your Session Analytics
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        <IconButton onClick={goToPreviousSession}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={goToNextSession}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>

      <Paper sx={{ padding: 3, width: "100%", maxWidth: 600 }}>
        <Typography variant="h5" gutterBottom>
          Session: {currentSession.sessionId}
        </Typography>
        <Typography variant="body1">
          <strong>Guests:</strong> {currentSession.guests.join(", ")}
        </Typography>
        <Typography variant="body1">
          <strong>Start Time:</strong>{" "}
          {new Date(currentSession.startTime).toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <strong>End Time:</strong>{" "}
          {new Date(currentSession.endTime).toLocaleString()}
        </Typography>
        <Typography variant="body1">
          <strong>Session Duration:</strong>{" "}
          {Math.floor(currentSession.sessionTime / 1000)} seconds
        </Typography>

        {leaderboardData.length > 0 ? (
          <Leaderboard data={parsedLeaderboardData} />
        ) : (
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            The leaderboard is empty
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mt: 5,
            justifyContent: "center",
          }}
        >
          <Button
            variant={showSessionData ? "contained" : "outlined"}
            onClick={() => {
              setShowSessionData(true);
              setShowQueued(false);
              setShowPlayed(false);
            }}
          >
            Session Data
          </Button>
          <Button
            variant={showQueued ? "contained" : "outlined"}
            onClick={() => {
              setShowQueued(true);
              setShowSessionData(false);
              setShowPlayed(false);
            }}
          >
            Queued Songs
          </Button>
          <Button
            variant={showPlayed ? "contained" : "outlined"}
            onClick={() => {
              setShowQueued(false);
              setShowSessionData(false);
              setShowPlayed(true);
            }}
          >
            Played Songs
          </Button>
        </Box>

        <Typography variant="h5" sx={{ mt: 2 }}>
          {showQueued
            ? "Total Songs Queued: "
            : showPlayed
            ? "Total Songs Played: "
            : "Most Popular Genre: "}
          {showQueued
            ? currentSession.queuedSongs.length
            : showPlayed
            ? currentSession.playedSongs.length
            : mostPopularGenre}
        </Typography>

        <Box sx={{ mt: 3 }}>
          {showQueued
            ? currentSession.queuedSongs.map((song, index) => (
                <Paper
                  key={index}
                  sx={{
                    padding: 2,
                    mb: 2,
                    backgroundColor: "background.default",
                  }}
                >
                  <Typography variant="h6">
                    {song.songTitle}
                    {song.played ? (
                      <Tooltip title="This Song Was Played!" placement="top">
                        <MusicNoteIcon
                          sx={{ fontSize: 25, color: "primary.main" }}
                        />
                      </Tooltip>
                    ) : null}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Recommender:</strong> {song.recommender}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          song.voteCount > 0
                            ? "success.main"
                            : "text.secondary",
                      }}
                    >
                      ↑
                    </Typography>
                    <Typography>{song.voteCount}</Typography>
                    <Typography
                      sx={{
                        color:
                          song.voteCount < 0 ? "error.main" : "text.secondary",
                      }}
                    >
                      ↓
                    </Typography>
                  </Box>
                </Paper>
              ))
            : showPlayed
            ? currentSession.playedSongs?.map((song, index) => (
                <Paper
                  key={index}
                  sx={{
                    padding: 2,
                    mb: 2,
                    backgroundColor: "background.default",
                  }}
                >
                  <Typography variant="h6">{song.songTitle}</Typography>
                  <Typography variant="body2">
                    <strong>Recommender:</strong> {song.recommender}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color:
                          song.voteCount > 0
                            ? "success.main"
                            : "text.secondary",
                      }}
                    >
                      ↑
                    </Typography>
                    <Typography>{song.voteCount}</Typography>
                    <Typography
                      sx={{
                        color:
                          song.voteCount < 0 ? "error.main" : "text.secondary",
                      }}
                    >
                      ↓
                    </Typography>
                  </Box>
                </Paper>
              ))
            : showSessionData && (
                <>
                  <GenreChart data={pieData} />

                  <Typography variant="h5" sx={{ mt: 2 }}>
                    Highest Voted Songs:
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      flexWrap: "wrap",
                      mt: 2,
                    }}
                  >
                    {currentSession.topSongs.map((song, index) => (
                      <Paper
                        key={index}
                        sx={{
                          padding: 2,
                          backgroundColor: "background.default",
                          minWidth: 180,
                        }}
                      >
                        <Typography variant="h6">
                          {song.songTitle}
                          {song.played ? (
                            <Tooltip
                              title="This Song Was Played!"
                              placement="top"
                            >
                              <MusicNoteIcon
                                sx={{ fontSize: 25, color: "primary.main" }}
                              />
                            </Tooltip>
                          ) : null}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Recommender:</strong> {song.recommender}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                song.voteCount > 0
                                  ? "success.main"
                                  : "text.secondary",
                            }}
                          >
                            ↑
                          </Typography>
                          <Typography>{song.voteCount}</Typography>
                          <Typography
                            sx={{
                              color:
                                song.voteCount < 0
                                  ? "error.main"
                                  : "text.secondary",
                            }}
                          >
                            ↓
                          </Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </>
              )}
        </Box>
      </Paper>

      <Button
        sx={{ mt: 3 }}
        variant="contained"
        color="primary"
        onClick={() => {
          navigate("/user-statistics");
        }}
      >
        User Analytics
      </Button>
    </Box>
  );
}
