import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
  SelectChangeEvent,
  Snackbar,
  Alert,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type AlertSeverity = "error" | "info" | "success" | "warning";

const genresList = [
  "Pop",
  "Hip-Hop",
  "Rock",
  "Jazz",
  "Classical",
  "Electronic",
  "Country",
  "R&B",
  "Reggae",
  "Blues",
];

export default function CreateSession() {
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<string>("public");
  const [distanceUnit, setDistanceUnit] = useState<string>("miles");
  const [distanceRadius, setDistanceRadius] = useState<string>("");
  const [songWaitPeriod, setSongWaitPeriod] = useState<string>(""); // New field for waiting period
  const [error, setError] = useState<string | null>(null);
  const [friendGroups, setFriendGroups] = useState<{ [key: string]: string[] }>(
    {}
  );
  const [selectedFriendGroups, setSelectedFriendGroups] = useState<string[]>(
    []
  );
  const [sessionName, setSessionName] = useState<string>("");
  // State variables for dialogs
  const [sessionTypeInfoOpen, setSessionTypeInfoOpen] = useState(false);
  const [genresInfoOpen, setGenresInfoOpen] = useState(false);
  const [sessionModeInfoOpen, setSessionModeInfoOpen] = useState(false);
  const [distanceRadiusInfoOpen, setDistanceRadiusInfoOpen] = useState(false);
  const [songWaitPeriodInfoOpen, setSongWaitPeriodInfoOpen] = useState(false);
  const [inviteFriendGroupsInfoOpen, setInviteFriendGroupsInfoOpen] =
    useState(false);
  const [lyricsToggle, setLyricsToggle] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("AUTOMATIC");
  const [isStudyMode, setIsStudyMode] = useState<boolean>(false); // New state for Study Mode
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  useEffect(() => {
    const userId = Cookies.get("userID");
    if (userId) {
      const fetchFriendGroups = async () => {
        try {
          const response = await fetch(
            import.meta.env.VITE_API_BASE_URL + "/api/get-friend-groups",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                userID: userId,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            setFriendGroups(data);
          } else {
            console.error("Failed to fetch friend groups");
          }
        } catch (error) {
          console.error("Error fetching friend groups:", error);
        }
      };
      fetchFriendGroups();
    } else {
      console.error("User ID not found in cookies");
    }
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleModeChange = (event: SelectChangeEvent<string>) => {
    const selectedMode = event.target.value;
    setMode(selectedMode);
    if (selectedMode !== "AUTOMATIC") {
      setIsStudyMode(false); // Disable Study Mode if not in Automatic mode
    }
  };

  const handleGenreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const genre = event.target.value;
    setSelectedGenres(
      event.target.checked
        ? [...selectedGenres, genre]
        : selectedGenres.filter((g) => g !== genre)
    );
  };

  const handleFriendGroupChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const groupName = event.target.value;
    setSelectedFriendGroups(
      event.target.checked
        ? [...selectedFriendGroups, groupName]
        : selectedFriendGroups.filter((g) => g !== groupName)
    );
  };

  const getLocation = (): Promise<{
    latitude: number;
    longitude: number;
  } | null> => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          (error) => {
            console.error("Error getting location:", error);
            resolve(null);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        resolve(null);
      }
    });
  };

  const inviteFriend = (friendCredentials: string, sessionID: string) => {
    console.log(`Invite sent to ${friendCredentials}`);

    const sendInvite = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/send-notification",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              credentials: friendCredentials,
              message: `Join your friend in their Pass The Aux Session! Use the following link:\nhttp://localhost:8080/join?code=${sessionID}`,
              subject: "Pass The Aux Session Invite",
            }),
          }
        );

        if (response.ok) {
          console.log(`Invited ${friendCredentials}!`);
        } else {
          const errorText = await response.text();
          console.error("Failed to invite friend:", errorText);
        }
      } catch (error) {
        console.error("Failed to invite friend: ", error);
      }
    };

    sendInvite();
  };

  const handleCreateSession = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const allowExplicit = formData.get("allowExplicit") === "true";
    const modeValue = formData.get("mode") as string;

    console.log(modeValue);

    if (
      modeValue === "AUTOMATIC" &&
      (!Cookies.get("mode") || Cookies.get("mode") === "none")
    ) {
      showSnackbar("You must sign in to a music service first", "warning");
      setIsLoading(false);
      return; // Prevent form submission
    }

    const isPublicBool = isPublic === "public";

    const location = isPublicBool ? await getLocation() : null;

    // Build the request body
    const requestBody = {
      hostUserId: Cookies.get("userID"),
      sessionName: sessionName,
      isPublic: isPublicBool,
      allowExplicit: allowExplicit,
      allowedGenres: selectedGenres,
      mode: modeValue,
      isStudyMode: mode === "AUTOMATIC" && isStudyMode,
      latitude: location?.latitude ?? null,
      longitude: location?.longitude ?? null,
      distanceUnit: isPublicBool ? distanceUnit : null,
      distanceRadius: isPublicBool ? parseFloat(distanceRadius) : null,
      repeatSongWaitPeriod: parseInt(songWaitPeriod, 10) || 0, // Add waiting period in minutes
      viewLyrics: lyricsToggle,
    };

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/create-session",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const sessionID = await response.text();
        Cookies.set("sessionID", sessionID);

        // Collect all friend credentials from selected friend groups
        const allFriendCredentials = new Set<string>();

        selectedFriendGroups.forEach((groupName) => {
          const friendsInGroup = friendGroups[groupName];
          friendsInGroup.forEach((friendCredential: string) => {
            allFriendCredentials.add(friendCredential);
          });
        });

        // Invite each friend, avoiding duplicates
        allFriendCredentials.forEach((friendCredential) => {
          inviteFriend(friendCredential, sessionID);
        });

        navigate("/session");
      } else {
        console.error("Failed to create session:", await response.text());
      }
    } catch (error) {
      console.error("Error creating session:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleLyrics = (checked: boolean) => {
    //console.log(checked ? "on" : "off");
    setLyricsToggle(checked); // Update the switch state
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "100vh",
        padding: 2,
        position: "relative",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <img
            src="/loading.gif"
            alt="Loading..."
            style={{ width: "80px", height: "80px" }}
          />
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: "background.paper",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 600,
          width: "100%",
          zIndex: isLoading ? 0 : 1,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Create a New Session
        </Typography>
        <form onSubmit={handleCreateSession}>
          <TextField
            label="Session Name"
            type="text"
            id="sessionName"
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            placeholder="Enter session name"
            required
            fullWidth
            margin="normal"
          />

          {/* Session Type with Info Icon */}
          <Box display="flex" alignItems="center">
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="session-type-label">Session Type</InputLabel>
              <Select
                id="isPublic"
                name="isPublic"
                value={isPublic}
                onChange={(e) => setIsPublic(e.target.value)}
                label="Session Type"
                labelId="session-type-label"
              >
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="private">Private</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              size="small"
              onClick={() => setSessionTypeInfoOpen(true)}
              aria-label="Session Type Info"
              sx={{ mt: 2 }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Allow Explicit Content</InputLabel>
            <Select
              id="allowExplicit"
              name="allowExplicit"
              defaultValue="true"
              label="Allow Explicit Content"
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>

          {/* Allowed Genres with Info Icon */}
          <FormControl component="fieldset" margin="normal">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">Allowed Genres:</Typography>
              <IconButton
                size="small"
                onClick={() => setGenresInfoOpen(true)}
                aria-label="Allowed Genres Info"
                sx={{ ml: 1 }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
            <FormGroup>
              {genresList.map((genre) => (
                <FormControlLabel
                  key={genre}
                  control={
                    <Checkbox
                      id={genre}
                      name="allowedGenres"
                      value={genre}
                      onChange={handleGenreChange}
                    />
                  }
                  label={genre}
                />
              ))}
            </FormGroup>

            <FormControlLabel
              control={
                <Switch
                  checked={lyricsToggle}
                  onChange={(e) => toggleLyrics(e.target.checked)}
                />
              }
              label="View Lyrics"
              sx={{ mt: 2 }}
            />
          </FormControl>

          {/* Session Mode with Info Icon */}
          <Box display="flex" alignItems="center">
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="session-mode-label">Session Mode</InputLabel>
              <Select
                id="mode"
                name="mode"
                defaultValue="AUTOMATIC"
                label="Session Mode"
                onChange={handleModeChange}
              >
                <MenuItem value="AUTOMATIC">Automatic</MenuItem>
                <MenuItem value="MANUAL">Manual</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              size="small"
              onClick={() => setSessionModeInfoOpen(true)}
              aria-label="Session Mode Info"
              sx={{ mt: 2 }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>

          {mode === "AUTOMATIC" && (
            <FormControlLabel
              control={
                <Checkbox
                  id="studyMode"
                  name="studyMode"
                  checked={isStudyMode}
                  onChange={(e) => setIsStudyMode(e.target.checked)}
                />
              }
              label="Study Mode (Automatically adjusts volume during conversations)"
              sx={{ mt: 2 }}
            />
          )}
          {isPublic === "public" && (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel>Distance Unit</InputLabel>
                <Select
                  id="distanceUnit"
                  value={distanceUnit}
                  onChange={(e) => setDistanceUnit(e.target.value)}
                  label="Distance Unit"
                >
                  <MenuItem value="ft">Feet</MenuItem>
                  <MenuItem value="yards">Yards</MenuItem>
                  <MenuItem value="miles">Miles</MenuItem>
                </Select>
              </FormControl>

              {/* Distance Radius with Info Icon */}
              <Box display="flex" alignItems="center">
                <TextField
                  label="Distance Radius"
                  type="number"
                  id="distanceRadius"
                  value={distanceRadius}
                  onChange={(e) => setDistanceRadius(e.target.value)}
                  placeholder="Enter distance"
                  required
                  fullWidth
                  margin="normal"
                  inputProps={{ min: 1 }}
                />
                <IconButton
                  size="small"
                  onClick={() => setDistanceRadiusInfoOpen(true)}
                  aria-label="Distance Radius Info"
                  sx={{ mt: 2 }}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Box>
            </>
          )}

          {/* Song Wait Period with Info Icon */}
          <Box display="flex" alignItems="center">
            <TextField
              label="Wait Period for Repeat Songs (Minutes)"
              type="number"
              id="songWaitPeriod"
              value={songWaitPeriod}
              onChange={(e) => setSongWaitPeriod(e.target.value)}
              placeholder="Enter minutes"
              fullWidth
              margin="normal"
              inputProps={{ min: 0 }}
            />
            <IconButton
              size="small"
              onClick={() => setSongWaitPeriodInfoOpen(true)}
              aria-label="Song Wait Period Info"
              sx={{ mt: 2 }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>

          {/* Invite Friend Groups with Info Icon */}
          <FormControl component="fieldset" margin="normal">
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">Invite Friend Groups:</Typography>
              <IconButton
                size="small"
                onClick={() => setInviteFriendGroupsInfoOpen(true)}
                aria-label="Invite Friend Groups Info"
                sx={{ ml: 1 }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
            <FormGroup>
              {Object.keys(friendGroups).length > 0 ? (
                Object.keys(friendGroups).map((groupName) => (
                  <FormControlLabel
                    key={groupName}
                    control={
                      <Checkbox
                        id={groupName}
                        name="friendGroups"
                        value={groupName}
                        onChange={handleFriendGroupChange}
                      />
                    }
                    label={groupName}
                  />
                ))
              ) : (
                <Typography variant="body2">
                  No friend groups available.
                </Typography>
              )}
            </FormGroup>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : "Create Session"}
          </Button>
        </form>

        {/* Dialogs for Info Icons */}
        {/* Session Type Info Dialog */}
        <Dialog
          open={sessionTypeInfoOpen}
          onClose={() => setSessionTypeInfoOpen(false)}
          aria-labelledby="session-type-info-dialog-title"
        >
          <DialogTitle id="session-type-info-dialog-title">
            Session Type Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Private sessions can only be joined through invite via code or
              link, but public sessions can be joined by anyone within a
              distance radius that you set or anyone that is friends with
              someone else in the session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSessionTypeInfoOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Allowed Genres Info Dialog */}
        <Dialog
          open={genresInfoOpen}
          onClose={() => setGenresInfoOpen(false)}
          aria-labelledby="genres-info-dialog-title"
        >
          <DialogTitle id="genres-info-dialog-title">
            Allowed Genres Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              The host can restrict which genres of songs session guests can see
              when searching and recommending songs. If no genres are selected,
              all genres will be allowed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setGenresInfoOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Session Mode Info Dialog */}
        <Dialog
          open={sessionModeInfoOpen}
          onClose={() => setSessionModeInfoOpen(false)}
          aria-labelledby="session-mode-info-dialog-title"
        >
          <DialogTitle id="session-mode-info-dialog-title">
            Session Mode Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Automatic mode is for the standard host playing music from their
              device or a connected speaker and requires being linked up to a
              music service. The website will automatically play the music and
              manage the queue accordingly. Manual mode is meant for DJs or
              session hosts with an external music player, so the host must
              manage the queue manually and play songs from their own music
              player.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSessionModeInfoOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Distance Radius Info Dialog */}
        <Dialog
          open={distanceRadiusInfoOpen}
          onClose={() => setDistanceRadiusInfoOpen(false)}
          aria-labelledby="distance-radius-info-dialog-title"
        >
          <DialogTitle id="distance-radius-info-dialog-title">
            Distance Radius Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Since the session is public, the distance radius and unit will
              determine how far someone can be from the current location to
              still join the session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDistanceRadiusInfoOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Song Wait Period Info Dialog */}
        <Dialog
          open={songWaitPeriodInfoOpen}
          onClose={() => setSongWaitPeriodInfoOpen(false)}
          aria-labelledby="song-wait-period-info-dialog-title"
        >
          <DialogTitle id="song-wait-period-info-dialog-title">
            Song Wait Period Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Guests will have to wait this many minutes to recommend a song
              that has already been played.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSongWaitPeriodInfoOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Invite Friend Groups Info Dialog */}
        <Dialog
          open={inviteFriendGroupsInfoOpen}
          onClose={() => setInviteFriendGroupsInfoOpen(false)}
          aria-labelledby="invite-friend-groups-info-dialog-title"
        >
          <DialogTitle id="invite-friend-groups-info-dialog-title">
            Invite Friend Groups Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              The groups listed below are the friend groups the account holder
              configured in their friends tab, and any group selected will have
              notifications sent to all of them to join the session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setInviteFriendGroupsInfoOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
