import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";

type AlertSeverity = "error" | "info" | "success" | "warning";

export default function Signup() {
  const navigate = useNavigate();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isNickname, setIsNickname] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [carrier, setCarrier] = useState("");
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const turnstile = useRef<TurnstileInstance | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const onSubmitSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const confirmPassword = form["confirm-password"].value;
    var type = "";

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    // Check if valid credentials
    if (!emailRegex.test(username) && !phoneRegex.test(username)) {
      showSnackbar("Email address or phone number is invalid", "warning");
      return;
    }

    if (password !== confirmPassword) {
      showSnackbar("Passwords do not match", "warning");
      return;
    }

    if (emailRegex.test(username)) {
      type = "es";
    } else {
      type = "ps";
    }

    const hashedPassword = password;

    localStorage.setItem("username", username);
    localStorage.setItem("password", hashedPassword);
    localStorage.setItem("two-factor", twoFactorAuthEnabled.toString());

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/signup?" +
          "user=" +
          username +
          "&pw=" +
          hashedPassword +
          "&type=" +
          type +
          "&carrier=" +
          carrier +
          "&tfa=" +
          twoFactorAuthEnabled +
          "&cfr=" +
          turnstile.current?.getResponse()
      );
      if (response.ok) {
        setIsConfirmed(true);
      }
    } catch (error) {
      showSnackbar("ERROR: Please try again later", "error");
    }
  };

  const onSubmitConfirmation = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const form = event.currentTarget;
    const confirmationCode = form["confirmation-code"].value;
    const retrievedUsername = localStorage.getItem("username");

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/verify-code?user=" +
          retrievedUsername +
          "&code=" +
          confirmationCode
      );

      if (response.ok) {
        setIsNickname(true);
      } else {
        const errorMessage = await response.text();
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      showSnackbar("ERROR: Please try again later", "error");
    }
  };

  const onSubmitNickname = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const nickname = form["nickname-entry"].value;
    const creds = localStorage.getItem("username");
    const pw = localStorage.getItem("password");
    const tfa = localStorage.getItem("two-factor");

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/create?" +
          "user=" +
          creds +
          "&pw=" +
          pw +
          "&nn=" +
          nickname +
          "&tfa=" +
          tfa +
          "&carrier=" +
          carrier
      );

      if (response.ok) {
        showSnackbar("Login Successful", "success");
        navigate("/login");
      } else {
        const errorMessage = await response.text();
        showSnackbar(errorMessage, "error");
      }
    } catch (error) {
      showSnackbar("ERROR: Please try again later", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: 2,
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {isConfirmed
            ? isNickname
              ? "Choose Your Nickname for Your"
              : "Confirm Your Email"
            : "Sign up for a"}{" "}
          Pass The Aux Account
        </Typography>

        {isConfirmed && !isNickname ? (
          <form onSubmit={onSubmitConfirmation}>
            <Typography variant="body1" gutterBottom>
              A code was sent to the email/phone number if valid
            </Typography>
            <TextField
              label="Enter Confirmation Code"
              type="text"
              id="confirmation-code"
              name="confirmation-code"
              required
              fullWidth
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Confirm
            </Button>
          </form>
        ) : isNickname ? (
          <form onSubmit={onSubmitNickname}>
            <TextField
              label="Enter Nickname"
              type="text"
              id="nickname-entry"
              name="nickname-entry"
              required
              fullWidth
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        ) : (
          <form onSubmit={onSubmitSignup}>
            <TextField
              label="Email or Phone Number"
              type="text"
              id="username"
              name="username"
              required
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              id="password"
              name="password"
              required
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              id="confirm-password"
              name="confirm-password"
              required
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Select Your Mobile Carrier</InputLabel>
              <Select
                id="carrier"
                name="carrier"
                value={carrier}
                onChange={(e) => setCarrier(e.target.value)}
                label="Select Your Mobile Carrier"
              >
                <MenuItem value="">--Select Carrier--</MenuItem>
                <MenuItem value="tmob">T-Mobile</MenuItem>
                <MenuItem value="verizon">Verizon</MenuItem>
                <MenuItem value="email">N/A</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={twoFactorAuthEnabled}
                  onChange={(e) => setTwoFactorAuthEnabled(e.target.checked)}
                />
              }
              label="Enable Two-Factor Authentication"
            />
            <Turnstile
              siteKey={
                import.meta.env.VITE_API_BASE_URL.includes("localhost")
                  ? "1x00000000000000000000AA"
                  : "0x4AAAAAAAztIPO7QXN1j8EB"
              }
              ref={turnstile}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Sign Up
            </Button>
          </form>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
