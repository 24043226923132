import React, { useState, useEffect, useRef, useCallback } from "react";
import Cookies from "js-cookie"; // Assuming you are storing session and user info in cookies
import { PTAPlayerHandle } from "../PTAPlayerHandle";

interface StudyModeControlProps {
  player: React.MutableRefObject<PTAPlayerHandle | null>
}

const StudyModeControl: React.FC<StudyModeControlProps> = (props) => {
  //const [isStudyMode, setIsStudyMode] = useState(false);
  const audioContext = useRef<AudioContext | null>(null);
  const microphoneStream = useRef<MediaStream | null>(null);
  const analyserNode = useRef<AnalyserNode | null>(null);
  const scriptProcessorNode = useRef<ScriptProcessorNode | null>(null);
  const [isStudyMode, setIsStudyMode] = useState<boolean>(false);  // New state for Study Mode
  const lastVolumeChangeRef = useRef<number>(Date.now());
  const cooldownTimeRef = useRef<number>(Date.now());
  const currentVolumeRef = useRef<number>(100);
  const RATE_LIMIT_MS = 1000;
  const COOL = 3000;
  const { player, ...otherProps } = props;

  useEffect(() => {
    (async () => {
      getStudy();
    })();
}, []); 

  useEffect(() => {
    if (isStudyMode) {
      startMicrophone();
    }
    else {
      console.log("not study mode");
    }

    return () => {
      // Cleanup on component unmount
      if (microphoneStream.current) {
        microphoneStream.current.getTracks().forEach(track => track.stop());
      }
      if (scriptProcessorNode.current) {
        scriptProcessorNode.current.disconnect();
      }
    };
  }, [isStudyMode]);

  const setLastVolumeChange = (value: number) => {
    lastVolumeChangeRef.current = value;
  };

  const setcooldownTime = (value: number) => {
    cooldownTimeRef.current = value;
  };
  
  const setCurrentVolume = (value: number) => {
    currentVolumeRef.current = value;
  };

  const getStudy = useCallback(async () => {
    console.log("Checkign wether is study mode");
    try {
      const sessionId = Cookies.get("sessionID");
      const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-session", {
        headers: {
          sessionID: sessionId || "",
          userID: Cookies.get("userID") || "",
        },
      });
      if (response.ok) {
        const sessionData = await response.json();
        if (sessionData && sessionData.mode) {
          setIsStudyMode(sessionData.isStudyMode);
          console.log("study mode:");
          console.log(sessionData.isStudyMode);
        } else {
          console.log("Cant get isstudymode")
        }
        return;
      } else {
        console.log("Cant get session from fetch sesh")
      }
      return;
    } catch (error) {
      console.error("Error fetching queue:", error);
      return;
    }
  }, []);

  const startMicrophone = () => {
    // Use standard AudioContext or fallback to webkitAudioContext (for legacy browsers)
    const AudioContextConstructor = window.AudioContext || (window as any).webkitAudioContext;

    // Ensure AudioContextConstructor is a valid constructor
    if (!AudioContextConstructor) {
      console.error('Your browser does not support the Web Audio API.');
      return;
    }

    // Create an instance of AudioContext using the valid constructor
    audioContext.current = new AudioContextConstructor();

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        microphoneStream.current = stream;
        const sourceNode = audioContext.current!.createMediaStreamSource(stream);
        
        analyserNode.current = audioContext.current!.createAnalyser();
        analyserNode.current.smoothingTimeConstant = 0;
        analyserNode.current.fftSize = 2048;

        sourceNode.connect(analyserNode.current);
        
        scriptProcessorNode.current = audioContext.current!.createScriptProcessor(2048, 1, 1);
        scriptProcessorNode.current.connect(audioContext.current!.destination);

        
    scriptProcessorNode.current.onaudioprocess = () => {
        const bufferLength = analyserNode.current!.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        analyserNode.current!.getByteFrequencyData(dataArray);
  
        // Calculate the average volume
        let total = 0;
        for (let i = 0; i < bufferLength; i++) {
          total += dataArray[i];
        }
        const averageVolume = total / bufferLength;
        // console.log("Average VOL:");
        // console.log(averageVolume);

  
        // Check if enough time has passed since last volume change
        const now = Date.now();
        if (now - lastVolumeChangeRef.current >= RATE_LIMIT_MS) {
          // Adjust music volume based on microphone volume
          if (averageVolume > 25) { // Threshold for when there's talking
            player.current?.setVolume(10);  // 20% volume
            setLastVolumeChange(now);
            // console.debug("Study mode: Low volume");
          } else if (now - lastVolumeChangeRef.current >= COOL) {
            
            // console.debug("Study mode: High volume");
            player.current?.setVolume(100); // 100% volume
          }
          
        }
      };
      })
      .catch((err) => {
        console.error("Error accessing microphone", err);
      });
  };

  return (
    <div>
    </div>
  );
};

export default StudyModeControl;

