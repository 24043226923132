import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MusicNoteIcon from "@mui/icons-material/MusicNote";

type RecommendedSong = {
  recommender: string;
  voteCount: number;
  songTitle: string;
  played: boolean;
};

type Session = {
  sessionId: string;
  recommendedSongs: RecommendedSong[];
};

type StatisticsData = Session[];

export default function Statistics() {
  const navigate = useNavigate();
  const [data, setData] = useState<StatisticsData>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0);

  const username = Cookies.get("userID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL +
            "/api/get-user-statistics" +
            "?user=" +
            username
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();

        if (!result || Object.keys(result).length === 0) {
          setData([]);
        } else {
          const sessions = Object.keys(result).map((sessionId) => {
            const sessionData = result[sessionId];
            return {
              sessionId,
              recommendedSongs: sessionData.recommendedSongs,
            };
          });

          setData(sessions);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError("Failed to fetch data: " + error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username]);

  const goToNextSession = () => {
    setCurrentSessionIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const goToPreviousSession = () => {
    setCurrentSessionIndex(
      (prevIndex) => (prevIndex - 1 + data.length) % data.length
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        Loading...
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        {error}
      </Box>
    );
  }

  if (data.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        <Typography variant="h4">Your User Analytics</Typography>
        <Typography sx={{ mt: 2 }} variant="body1">
          Looks like you have no analytics...
        </Typography>
        <Typography variant="body1">
          Join or create a session and interact with it to get some analytics!
        </Typography>

        <Button
          sx={{ mt: 1 }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/user-home");
          }}
        >
          Join or Create a Session!
        </Button>

        <Button
          sx={{ mt: 5 }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/session-statistics");
          }}
        >
          Session Analytics
        </Button>
      </Box>
    );
  }

  const currentSession = data[currentSessionIndex];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Your User Statistics
      </Typography>
      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        <IconButton onClick={goToPreviousSession}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={goToNextSession}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>

      <Paper sx={{ padding: 3, width: "100%", maxWidth: 600 }}>
        <Typography variant="h5" gutterBottom>
          Session: {currentSession.sessionId}
        </Typography>

        <Box sx={{ mt: 3 }}>
          {currentSession.recommendedSongs.map((song, index) => (
            <Paper
              key={index}
              sx={{ padding: 2, mb: 2, backgroundColor: "background.default" }}
            >
              <Typography variant="h6">
                {song.songTitle}
                {song.played ? (
                  <Tooltip title="This Song Was Played!" placement="top">
                    <MusicNoteIcon
                      sx={{ fontSize: 25, color: "primary.main" }}
                    />
                  </Tooltip>
                ) : null}
              </Typography>

              <Typography variant="body2">
                <strong>Recommender:</strong> {song.recommender}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color:
                      song.voteCount > 0 ? "success.main" : "text.secondary",
                  }}
                >
                  ↑
                </Typography>
                <Typography>{song.voteCount}</Typography>
                <Typography
                  sx={{
                    color: song.voteCount < 0 ? "error.main" : "text.secondary",
                  }}
                >
                  ↓
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/session-statistics")}
        sx={{ mt: 3 }}
      >
        Session Analytics
      </Button>
    </Box>
  );
}
