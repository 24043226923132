import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider as MuiThemeProvider, CssBaseline, GlobalStyles } from '@mui/material';
import { ThemeProvider, useThemeContext } from './ThemeContext'; // Custom ThemeContext
import getTheme from './theme'; // Dynamic theme generator
import './fonts.css'; // Import custom fonts

const InnerApp: React.FC = () => {
  const { mode } = useThemeContext(); // Get the current mode (light or dark) from context
  const theme = getTheme(mode); // Dynamically generate the theme

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            minHeight: '100vh',
            margin: 0,
            padding: 0,
            backgroundAttachment: 'fixed',
          },
        }}
      />
      <App />
    </MuiThemeProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider> {/* Custom ThemeContext for managing mode */}
      <InnerApp />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();