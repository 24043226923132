import Cookies from "js-cookie";
import { NOT_PLAYING_SONG, PTASong } from "./PTASong";

export const updatePlaybackState = async (playbackState: PlaybackStateRequest): Promise<boolean> => {
	const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/update-playback", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(playbackState),
	});

	if (response.ok) {
		return true;
	} else {
		console.error("Failed to update playback state on server", await response.text());
		return false;
	}
}

export const getPlaybackState = async (sessionID: string): Promise<PlaybackState | null> => {
	const response = await fetch(import.meta.env.VITE_API_BASE_URL + "/api/get-playback?sessionID=" + sessionID, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		}
	});

	if (response.ok) {
		return response.json()
	} else {
		console.error("Failed to get playback state from server", await response.text());
		return null;
	}
}

export enum PlaybackMode {
	PLAY = "PLAY",
	PAUSE = "PAUSE",
	STOP = "STOP"
}

export type PlaybackState = {
	current: PTASong;
	currentTimeMillis: number;
	playing: PlaybackMode;
};

export type PlaybackStateRequest = {
	state: PlaybackState;
	sessionID: string;
}

export const NOT_PLAYING_STATE: PlaybackState = {
	current: NOT_PLAYING_SONG,
	currentTimeMillis: 0,
	playing: PlaybackMode.STOP
}
