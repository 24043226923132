import React from "react";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import LyricsIcon from "@mui/icons-material/Lyrics";
import { useState } from "react";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { NOT_PLAYING_SONG } from "../PTASong";

interface CurrentSongHostProps {
  song: {
    songId: string;
    title: string;
    artist: string;
    albumArtURL: string;
  };
  nextSong: () => void;
  viewLyrics: string;
  manualMode: boolean;
}

const CurrentSongHost: React.FC<CurrentSongHostProps> = ({
  song,
  nextSong,
  viewLyrics,
  manualMode,
}) => {
  const [openLyricsModal, setOpenLyricsModal] = useState<string | null>(null);
  const [selectedSong, setSelectedSong] = useState<string | null>(null);
  const [lyrics, setLyrics] = useState<string | null>(null);
  const [lyricsHtml, setLyricsHtml] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleOpenLyrics = async (
    title: string,
    artist: string,
    songId: string
  ) => {
    setSelectedSong(title);
    setOpenLyricsModal(songId);
    setLoading(true);
    setLyrics(null);
    setLyricsHtml(null);
    setError(null);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/embed-lyrics?" +
          "song=" +
          title +
          "&artist=" +
          artist
      );

      if (!response.ok) {
        throw new Error("Failed to fetch lyrics");
      }

      const data = await response.text();
      setLyricsHtml(data);
    } catch (error) {
      setError("Failed to load lyrics. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "background.paper",
        borderRadius: 2,
        boxShadow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" component="h2">
        {song.title}
        {song.songId !== NOT_PLAYING_SONG.songId && viewLyrics == "true" && (
          <Tooltip title="View Lyrics" placement="top">
            <IconButton
              onClick={() =>
                handleOpenLyrics(song.title, song.artist, song.songId)
              }
            >
              <LyricsIcon />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
      <Typography variant="subtitle1" component="h3">
        {song.artist}
      </Typography>

      <Dialog
        open={openLyricsModal === song.songId}
        onClose={() => setOpenLyricsModal(null)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{song.title} Lyrics</DialogTitle>
        <DialogContent>
          {loading && (
            <Typography variant="body1">Loading lyrics...</Typography>
          )}

          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}

          {lyricsHtml && (
            <iframe
              srcDoc={`
                        <html>
                          <head>
                            <style>
                              @font-face {
                                font-family: 'YourCustomFont';
                                src: url('../fonts/circular-std-med.ttf') format('truetype');
                              }
                              body {
                                font-family: 'YourCustomFont', sans-serif;
                                margin: 0;
                                padding: 0;
                              }
                            </style>
                          </head>
                          <body>${lyricsHtml}</body>
                        </html>
                      `}
              style={{ width: "100%", height: "500px", border: "none" }}
              title={`${song.title} Lyrics`}
            />
          )}

          {!loading && !error && !lyricsHtml && (
            <Typography variant="body1">
              Sorry, lyrics for "{song.title}" are unavailable right now...
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLyricsModal(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component="img"
        src={song.albumArtURL}
        alt={`${song.title} album art`}
        sx={{
          width: 150,
          height: 150,
          objectFit: "cover",
          borderRadius: 1,
          marginBottom: 2,
        }}
      />

      {manualMode && (
        <Button
        variant="contained"
        color="primary"
        startIcon={<SkipNextIcon />}
        onClick={nextSong}
        sx={{ marginTop: 2 }}
        >
        Skip Song
        </Button>
      )}
    </Box>
  );
};

export default CurrentSongHost;
