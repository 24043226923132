// JoinSessionButton.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/LoginButtonsTestPage.css";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

export default function JoinSessionButton() {
  const [showDialog, setShowDialog] = useState(false);
  const [sessionCode, setSessionCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleJoinSession = () => {
    setShowDialog(true);
  };

  const handleJoinClick = () => {
    if (sessionCode) {
      // Navigate to the JoinSessionPage with the session code as a query parameter
      navigate(`/join?code=${sessionCode}`);
    } else {
      setErrorMessage("Please enter a session code.");
    }
  };

  const buttonStyle = {
    fontSize: "0.875rem",
    padding: "8px 16px",
    width: "100%",
    maxWidth: "300px",
    alignSelf: "center",
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleJoinSession}
        sx={{
          fontSize: "0.875rem",
          padding: "8px 16px",
          width: "100%",
          maxWidth: "300px",
          alignSelf: "center",
        }}
      >
        Join Session with code
      </Button>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <TextField
            type="text"
            label="Enter Session Code"
            fullWidth
            value={sessionCode}
            onChange={(e) => setSessionCode(e.target.value)}
            margin="normal"
          />
          {errorMessage && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleJoinClick} color="primary">
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
