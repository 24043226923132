import React, { useState, ChangeEvent, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
type SettingType = "email" | "phone" | "password";
type AlertSeverity = "error" | "info" | "success" | "warning";

export const handleSignup = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault(); // Prevent the default form submission
};

const AccountManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<SettingType>("email");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  const [formData, setFormData] = useState<{
    [key in SettingType]: { old: string; new: string };
  }>({
    email: { old: "", new: "" },
    phone: { old: "", new: "" },
    password: { old: "", new: "" },
  });

  const userId = Cookies.get("userID");

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleInputChange = (
    type: SettingType,
    field: "old" | "new",
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: {
        ...prevData[type],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (
    type: SettingType,
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const { old, new: newValue } = formData[type];

    if (type === "password") {
      // Verify if the old password is correct
      try {
        const passwordHash = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-password",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              userId: userId || "",
            },
          }
        );
        passwordHash.text().then((test: string) => {
          // Compare the old password with the fetched hash
          if (old === test) {
            // If the password is valid, update password
            fetch(
              import.meta.env.VITE_API_BASE_URL +
                "/api/update-creds?userId=" +
                userId +
                "&credential=" +
                "" +
                "&password=" +
                newValue
            ).then((res) => {
              if (res.ok) {
                //console.log("submitted update password");
                showSnackbar("Your password has been changed", "success");
                setFormData((prevData) => ({
                  ...prevData,
                  [type]: { old: "", new: "" },
                }));
              }
            });
          } else {
            console.error("Old password does not match.");
            showSnackbar("Old password is incorrect", "error");
          }
        });
      } catch (error) {
        console.error("Error verifying password:", error);
      }
    } else {
      const credResponse = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/get-credential",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            userId: userId || "",
          },
        }
      );
      credResponse.text().then((test: string) => {
        // Compare the old credential with the fetched credential
        if (old === test) {
          // If the credential is valid, update credentials
          fetch(
            import.meta.env.VITE_API_BASE_URL +
              "/api/update-creds?userId=" +
              userId +
              "&credential=" +
              newValue +
              "&password=" +
              ""
          ).then((res) => {
            if (res.ok) {
              //console.log("submitted update cred");
              showSnackbar("Your credentials have been changed", "success");
              setFormData((prevData) => ({
                ...prevData,
                [type]: { old: "", new: "" },
              }));
            }
          });
        } else {
          console.error("Old credential does not match.");
          showSnackbar("Old credential is incorrect", "error");
        }
      });
    }
  };

  const renderForm = (type: SettingType) => {
    const placeholders: Record<SettingType, string[]> = {
      email: ["Old Email", "New Email"],
      phone: ["Old Phone Number", "New Phone Number"],
      password: ["Old Password", "New Password"],
    };

    return (
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(type, e)}
        sx={{ mt: 2 }}
      >
        <TextField
          label={placeholders[type][0]}
          type={type === "password" ? "password" : "text"}
          value={formData[type].old}
          onChange={(e) => handleInputChange(type, "old", e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={placeholders[type][1]}
          type={type === "password" ? "password" : "text"}
          value={formData[type].new}
          onChange={(e) => handleInputChange(type, "new", e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 600, mx: "auto" }}>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Change Email" value="email" />
        <Tab label="Change Phone Number" value="phone" />
        <Tab label="Change Password" value="password" />
      </Tabs>
      <Box sx={{ padding: 2 }}>{renderForm(activeTab)}</Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AccountManagement;
