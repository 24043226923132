export type ThemeColors = {
  primary: string,
  primaryAlt: string,
  secondary: string,
  secondaryAlt: string,
  background: string,
  backgroundPaper: string,
  text: string
  textSecondary: string
};

const distance = (x: number[], y: number[]): number => {
  return Math.sqrt(((y[0] * y[0]) - (x[0] * x[0])) + ((y[1] * y[1]) - (x[1] * x[1])) + ((y[2] * y[2]) - (x[2] * x[2])));
};

const componentToHex = (c: number) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

const rgbToHex = (rgb: number[]) => {
  return "#" + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

const invertHex = (hex: string) => { // modified from https://stackoverflow.com/a/54569758
  return "#" + (Number(`0x1${hex.substring(1)}`) ^ 0xFFFFFF).toString(16).substring(1).toUpperCase()
};

// modified from https://www.geeksforgeeks.org/explain-the-differences-between-for-in-and-for-of-statement-in-javascript/
export const kMeansClustering = (data: number[][]): ThemeColors => {
  let centroids: number[][] = [];
  centroids.push(data[Math.floor(Math.random() * data.length)]);
  for (const centroidIdx of Array(3).keys()) {
    let distances: number[] = [];
    for (const i of Array(data.length).keys()) {
      const point = data[i];
      let d = Number.MAX_VALUE
      for (const j of Array(centroids.length).keys()) {
        d = Math.min(d, distance(data[i], centroids[j]));
      }
      distances.push(d);
    }
    centroids.push(data[data.reduce((maxIndex, currentValue, currentIndex, arr) => currentValue > arr[maxIndex] ? currentIndex : maxIndex, 0)]);
    distances = [];
  }
  const baseColors = centroids.map(rgbToHex);
  const invertColors = baseColors.map(invertHex).map((val) => "#" + val);
  return {
    primary: baseColors[0],
    primaryAlt: invertColors[0],
    secondary: baseColors[1],
    secondaryAlt: invertColors[1],
    background: baseColors[2],
    backgroundPaper: invertColors[2],
    text: baseColors[3],
    textSecondary: invertColors[3]
  };
};
