// src/pages/NewHome.tsx
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box, Button, Typography } from '@mui/material';
import AppleMusicLoginButton from '../components/AppleMusicLoginButton';
import SpotifyLoginButton from '../components/SpotifyLoginButton';
import TidalLoginButton from '../components/TidalLoginButton';

export default function NewHome() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const q = searchParams.get("ptaroute");
    if (q !== null) {
      navigate("/" + decodeURI(q));
      return;
    }
    if (Cookies.get("userID") !== undefined) {
      console.info("Checking if session is valid");
      if (Cookies.get("sessionID") !== undefined) {
        fetch(`${import.meta.env.VITE_API_BASE_URL}/api/is-session`, {
          headers: {
            sessionID: Cookies.get("sessionID")!,
          },
        }).then((res) => {
          res.text().then((text) => {
            if (text === "true") {
              console.log("Go to session");
              navigate("/session?" + searchParams.toString());
            } else {
              console.log("Reset to user-home");
              Cookies.remove("sessionID");
              Cookies.remove("mode");
              navigate("/user-home?" + searchParams.toString());
            }
          });
        });
      } else {
        navigate("/user-home?" + searchParams.toString());
        Cookies.remove("mode");
      }
    }
  }, [searchParams, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          textAlign: 'center',
          maxWidth: 400,
          width: '100%',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to Pass The Aux!!
        </Typography>
        <Box
          component="img"
          src="/logo.svg"
          alt="PassTheAux logo"
          sx={{ maxWidth: 80, height: 'auto', marginBottom: 3 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/about')}
          >
            About Pass The Aux
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/join-session')}
          >
            Join Session
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/signup')}
          >
            Sign Up
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/login')}
          >
            Log In
          </Button>
        </Box>
      </Box>
    </Box>
  );
}