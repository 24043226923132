import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "../styles/SongStats.css";
import { SongAnalytics } from "./Queue";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@mui/material";

export interface SongStatsHandle {
  handleOpenModal: () => void;
}
export interface SongStatsProps {
  songAnalytics: SongAnalytics;
}

const formatList = (voters: string[]) => {
  return voters.join(", ");
};

const formatRecommendedTime = (recTime: string) => {
  const date = new Date(recTime);

  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleString("en-US", options);

  return formattedDate.replace(",", " at");
};

export const SongStats = forwardRef<SongStatsHandle, SongStatsProps>(
  (props, ref) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
      setModalOpen(true);
    };

    const handleCloseModal = () => {
      setModalOpen(false);
    };

    

    useImperativeHandle(ref, () => {
      return { handleOpenModal };
    });

    return (
      <Box>
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle>{props.songAnalytics.songTitle} Statistics</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Queued by {props.songAnalytics.recommender} on{" "}
              {formatRecommendedTime(props.songAnalytics.recTime)}
            </Typography>
            <br />
            <Typography variant="body1">
              Genre: {props.songAnalytics.songGenre}
            </Typography>
            <Typography variant="body1">
              Vote count: {props.songAnalytics.voteCount}
            </Typography>
            <Typography variant="body1">
              Likes: {formatList(props.songAnalytics.upVoters)}
            </Typography>
            <Typography variant="body1">
              Dislikes: {formatList(props.songAnalytics.downVoters)}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
);

export default SongStats;
