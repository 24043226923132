// Import necessary MUI components
import {
  Box,
  Typography,
} from '@mui/material';
import AccountManagement from '../components/AccountManagement';

export default function AccountSettings() {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Account Settings
      </Typography>
      <AccountManagement />
    </Box>
  );
}

