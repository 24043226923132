import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

export default function JoinSession() {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // State variables for nickname dialog
  const [showNicknameDialog, setShowNicknameDialog] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string>("");

  useEffect(() => {
    const codeFromURL: string | null = searchParams.get("code");
    if (codeFromURL) {
      const userID = Cookies.get("userID");
      if (userID) {
        // Proceed to verify session
        verifyAndSetSession(codeFromURL, userID, "");
      } else {
        // Show nickname dialog and stop loading
        setShowNicknameDialog(true);
        setLoading(false);
      }
    } else {
      setErrorMessage("Invalid session code or no code provided.");
      setLoading(false);
    }
  }, [searchParams]);

  const handleSaveNickname = () => {
    if (nickname.trim() !== "") {
      const nickName = nickname.trim();
      Cookies.set("nickname", nickName);
      setShowNicknameDialog(false);

      // Set loading to true before verifying session
      setLoading(true);

      // After setting the userID, proceed to verify session
      const codeFromURL: string | null = searchParams.get("code");
      if (codeFromURL) {
        verifyAndSetSession(codeFromURL, "", nickName);
      } else {
        setErrorMessage("Invalid session code or no code provided.");
        setLoading(false);
      }
    } else {
      setErrorMessage("Please enter a nickname.");
    }
  };

  const verifyAndSetSession = async (
    code: string,
    userID: string,
    nickname: string
  ) => {
    try {
      const response = await fetch("/api/verify-session/", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          sessionID: code, // Make sure this matches what the backend expects
          userID: userID,
          nickname: nickname,
        },
      });

      if (response.ok) {
        const returnValues = await response.json();
        // Save the session code and userID to cookies
        Cookies.set("sessionID", returnValues.sessionID);
        Cookies.set("userID", returnValues.userID);
        Cookies.set("mode", returnValues.apimode);
        console.log("Session code saved to cookies:", code);

        // Redirect to the session details page
        navigate(`/session`);
      } else {
        const message = await response.text();
        setErrorMessage(message);
      }
    } catch (error) {
      console.error("Error verifying session:", error);
      setErrorMessage("Failed to verify session. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {showNicknameDialog ? (
        <Dialog
          open={showNicknameDialog}
          onClose={() => setShowNicknameDialog(false)}
          aria-labelledby="nickname-dialog-title"
        >
          <DialogTitle id="nickname-dialog-title">
            Please enter a nickname to join the session as a guest:
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nickname"
              type="text"
              fullWidth
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
                setErrorMessage("");
              }}
            />
            {errorMessage && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleSaveNickname}
              color="primary"
              variant="contained"
            >
              Save Guest Nickname
            </Button>
            <Button
              onClick={() => {
                const codeFromURL = searchParams.get("code");
                if (codeFromURL) {
                  Cookies.set("goingTo", codeFromURL);
                }
                navigate("/login");
              }}
              color="primary"
              variant="contained"
            >
              Log Into Account Instead
            </Button>
          </DialogActions>
        </Dialog>
      ) : loading ? (
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Box
            component="img"
            src="/loading.gif"
            alt="Loading..."
            sx={{ width: 24, height: 24, mr: 2 }}
          />
          <Typography>Loading...</Typography>
        </Box>
      ) : errorMessage ? (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ mt: 2 }}>
          Redirecting to session details...
        </Typography>
      )}
    </Box>
  );
}
