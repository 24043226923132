import React, { useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "../styles/JoinSessionByLocationButton.css";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  CardActions,
  SelectChangeEvent,
} from "@mui/material";

type SessionLocation = {
  latitude: number;
  longitude: number;
};

// Define the Session type based on your Java class
type Session = {
  sessionId: string;
  hostUserId: string;
  guests: string[];
  public: boolean;
  allowExplicit: boolean;
  allowedGenres: string[] | null;
  mode: string | null; // Could be an enum if needed
  url: string;
  startTime: string; // Use string for date, LocalDateTime can be parsed in JS as string
  location: SessionLocation | null; // Can be null
};

type SessionWithHost = Session & {
  hostNickname: string | null;
};

export default function JoinSessionByLocationButton() {
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [distanceUnit, setDistanceUnit] = useState("ft");
  const [distance, setDistance] = useState("");
  const [sessions, setSessions] = useState<SessionWithHost[]>([]); // List of sessions with host nicknames
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLocationButtonClick = () => {
    setShowLocationDialog(true); // Show location dialog
  };

  const handleDistanceUnitChange = (event: SelectChangeEvent<string>) => {
    setDistanceUnit(event.target.value);
  };

  const handleDistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDistance(e.target.value);
  };

  const fetchHostNickname = async (hostUserId: string) => {
    try {
      const response = await fetch(`/api/get-user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          userID: hostUserId,
        },
      });
      const hostData = await response.json();
      return hostData.nickName;
    } catch (error) {
      console.error("Error fetching host nickname:", error);
      return null;
    }
  };

  const handleSubmit = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        try {
          const response = await fetch(
            `/api/sessions/nearby?latitude=${latitude}&longitude=${longitude}&distance=${distance}&unit=${distanceUnit}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            const nearbySessions: Session[] = await response.json();

            if (nearbySessions.length > 0) {
              const sessionsWithHostNames = await Promise.all(
                nearbySessions.map(async (session) => {
                  const hostNickname = await fetchHostNickname(
                    session.hostUserId
                  );
                  return { ...session, hostNickname };
                })
              );

              setSessions(sessionsWithHostNames); // Update the state with sessions that include host nicknames
            } else {
              setErrorMessage("No sessions found nearby.");
            }
          } else {
            const message = await response.text();
            setErrorMessage(message);
          }
        } catch (error) {
          console.error("Error fetching nearby sessions:", error);
          setErrorMessage("Failed to find sessions. Please try again.");
        }

        setShowLocationDialog(false); // Close the dialog after fetching
      });
    } else {
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  };

  const buttonStyle = {
    fontSize: "0.875rem",
    padding: "8px 16px",
    width: "100%",
    maxWidth: "300px",
    alignSelf: "center",
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLocationButtonClick}
        sx={{ textTransform: "none", mb: 2, buttonStyle }}
      >
        Find Session Near Me
      </Button>

      <Dialog
        open={showLocationDialog}
        onClose={() => setShowLocationDialog(false)}
      >
        <DialogTitle>Find Session Near Me</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Distance Unit</InputLabel>
            <Select
              value={distanceUnit}
              onChange={handleDistanceUnitChange}
              label="Distance Unit"
            >
              <MenuItem value="ft">Feet</MenuItem>
              <MenuItem value="miles">Miles</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Distance"
            type="number"
            placeholder="Enter distance"
            value={distance}
            onChange={handleDistanceChange}
            fullWidth
            margin="normal"
            inputProps={{ min: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowLocationDialog(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Find Session
          </Button>
        </DialogActions>
      </Dialog>

      {sessions.length > 0 ? (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Nearby Sessions
          </Typography>
          {sessions.map((session) => (
            <Card key={session.sessionId} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  <strong>Host Nickname:</strong>{" "}
                  {session.hostNickname || "Unknown"}
                </Typography>
                <Typography variant="body1">
                  <strong>Session ID:</strong> {session.sessionId}
                </Typography>
                <Typography variant="body1">
                  <strong>Public:</strong> {session.public ? "Yes" : "No"}
                </Typography>
                <Typography variant="body1">
                  <strong>Location:</strong> {session.location?.latitude},{" "}
                  {session.location?.longitude}
                </Typography>
              </CardContent>
              {session.public && (
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/join?code=${session.sessionId}`)}
                  >
                    Go to Session
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </Box>
      ) : errorMessage ? (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      ) : null}
    </Box>
  );
}
