import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";

interface InviteOptionsProps {
  onClose: () => void;
}

type AlertSeverity = "error" | "info" | "success" | "warning";

const InviteOptions: React.FC<InviteOptionsProps> = ({ onClose }) => {
  const [sessionData, setSessionData] = useState<any>(null);
  const [friendsList, setFriendsList] = useState<any[]>([]);
  const [isFriendsLoading, setIsFriendsLoading] = useState<boolean>(true); // Added loading state
  const [invitedFriends, setInvitedFriends] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Load session data and friends list from cookies
  useEffect(() => {
    // Load session data
    const sessionID = Cookies.get("sessionID");
    if (!sessionID) {
      console.error("Session ID not found in cookies.");
      // Optionally, redirect to a different page or show a message
      return;
    }

    const fetchSessionData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-session",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              sessionID: sessionID,
              userID: Cookies.get("userID") || "",
            },
          }
        );

        if (response.ok) {
          const sessionData = await response.json();
          setSessionData(sessionData); // No need to parse again
        } else {
          const errorText = await response.text();
          console.error("Failed to fetch session:", errorText);
        }
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    fetchSessionData();

    const userId = Cookies.get("userID");
    if (!userId) {
      console.error("User ID not found in cookies. Please log in.");
      // Optionally, redirect to login page
      return;
    }

    // Fetch friends list from API
    const fetchFriendsList = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-friends",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              userId: userId,
            },
          }
        );

        if (response.ok) {
          const friendsData = await response.json();

          // Check if the fetched data is an empty array or null
          if (!friendsData || friendsData.length === 0) {
            setFriendsList([]);
            Cookies.set("friendsList", JSON.stringify([]));
            console.log("No friends found, setting friendsList to empty.");
          } else {
            setFriendsList(friendsData);
            Cookies.set("friendsList", JSON.stringify(friendsData));
            console.log("Friends:", friendsData);
          }
        } else {
          // Handle non-200 responses by setting an empty friends list
          setFriendsList([]);
          Cookies.set("friendsList", JSON.stringify([]));
          const errorText = await response.text();
          console.error("Failed to fetch friends list:", errorText);
        }
      } catch (error) {
        // Handle network or other unexpected errors
        setFriendsList([]);
        Cookies.set("friendsList", JSON.stringify([]));
        console.error("Error fetching friends list:", error);
      } finally {
        setIsFriendsLoading(false); // Set loading to false when done
      }
    };

    fetchFriendsList();
  }, []);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showSnackbar("Copied to clipboard!", "success");
  };

  const downloadQRCode = () => {
    if (!sessionData) return;
    const canvas = document.getElementById("qr-code") as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${sessionData.sessionId}_QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // Function to handle inviting a friend
  const inviteFriend = (friend: any) => {
    console.log(`Invite sent to ${friend.nickName}`);

    const sendInvite = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/send-notification",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              credentials: friend.credentials,
              message: `Join your friend in their Pass The Aux Session! Use the following link:\n${sessionData.url}`,
              subject: "Pass The Aux Session Invite",
            }),
          }
        );

        if (response.ok) {
          console.log(`Invited ${friend.nickName}!`);
          setInvitedFriends((prevInvited) => [...prevInvited, friend.nickName]);
        } else {
          const errorText = await response.text();
          console.error("Failed to invite friend:", errorText);
        }
      } catch (error) {
        console.error("Failed to invite friend: ", error);
      }
    };

    sendInvite();
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Join my session on PassTheAux",
          text: `Hey! Join my session using this code: ${sessionData.sessionId}`,
          url: sessionData.url,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      showSnackbar("Your browser does not support the share feature", "error");
    }
  };

  if (!sessionData) {
    return <div>No session data found!</div>;
  }

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: 4,
        borderRadius: 2,
        maxWidth: 600,
        margin: "0 auto",
        position: "relative", // Added position relative for loading overlay
        zIndex: 1, // Ensure content is above any other elements
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Invite Options
      </Typography>
      <Typography variant="body1">
        <strong>Join Code:</strong> {sessionData.sessionId}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => copyToClipboard(sessionData.sessionId)}
        sx={{ mt: 1 }}
      >
        Copy Join Code
      </Button>
      <Typography variant="body1" sx={{ mt: 2 }}>
        <strong>Session URL:</strong> {sessionData.url}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => copyToClipboard(sessionData.url)}
        >
          Copy Session URL
        </Button>
        <Button variant="contained" color="primary" onClick={handleShare}>
          Share Session
        </Button>
      </Box>

      <Box sx={{ mt: 4, textAlign: "center" }}>
        <QRCodeCanvas
          id="qr-code"
          value={sessionData.url}
          size={200}
          style={{ margin: "0 auto" }}
        />
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={downloadQRCode}>
            Download QR Code
          </Button>
        </Box>
      </Box>

      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Invite Friends:
      </Typography>
      {isFriendsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : friendsList.length > 0 ? (
        <List>
          {friendsList.map((friend, index) => (
            <ListItem
              key={index}
              secondaryAction={
                invitedFriends.includes(friend.nickName) ? (
                  <Typography variant="body2" color="textSecondary">
                    Invited!
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => inviteFriend(friend)}
                  >
                    Invite
                  </Button>
                )
              }
            >
              <ListItemText primary={friend.nickName} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">You have no friends to invite.</Typography>
      )}

      <Button
        variant="contained"
        color="secondary"
        onClick={onClose}
        sx={{ mt: 4 }}
      >
        Back
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InviteOptions;
