import React, { useEffect, useState } from 'react';
import '../styles/LoginButton.css'
import { Button } from '@mui/material';
import Cookies from 'js-cookie';

function tidalLoginHandler() {
  console.info("Authing!");
  window.location.href = import.meta.env.VITE_API_BASE_URL + "/api/tidal";
}

export default function TidalLoginButton() {
	const [isValid, setIsValid] = useState(false);
	useEffect(() => {
		if (Cookies.get("mode") === "tidal") setIsValid(true);
		else setIsValid(false);
	}, [document.cookie]);
  return (
    <Button
      onClick={tidalLoginHandler}
      variant="contained"
      color="primary"
      sx={{ textTransform: 'none' }}
    >
    {isValid ? "Using Tidal" : "Tidal Login"}
    </Button>
  );
}
