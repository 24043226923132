import "../styles/Confirmation.css";

export default function Confirmation() {
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  return (
    <div className="confirmation-container">
      <div className="confirmation">
        <h1>Confirm Your Email</h1>
        <p>A code was sent to the email/phone number if valid</p>
        <br></br>
        <form action="#" method="POST">
          <div className="form-group">
            <label htmlFor="confirmation-code">Enter Confirmation Code:</label>
            <input
              type="text"
              id="confirmation-code"
              name="confirmation-code"
              required
            />
          </div>
          <button type="submit">Confirm</button>
        </form>
      </div>
    </div>
  );
}
