import React from "react";
import Navbar from "./Navbar"; // Import your Navbar component

interface LayoutProps {
  children: React.ReactNode; // Allows passing child components
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <div style={{ padding: "20px" }}>
        {children} {/* Render child components here */}
      </div>
    </>
  );
};

export default Layout;