import { useRef, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme } from "@mui/material";
import AppleMusicLoginButton from "../components/AppleMusicLoginButton";
import SpotifyLoginButton from "../components/SpotifyLoginButton";
import TidalLoginButton from "../components/TidalLoginButton";
import YouTubeLoginButton from "../components/YouTubeLoginButton";
import PTAAppleMusicPlayer from "../components/PTAAppleMusicPlayer";
import PTASpotifyPlayer from "../components/PTASpotifyPlayer";
import PTATidalPlayer from "../components/PTATidalPlayer";
import PTAYTPlayer from "../components/PTAYTPlayer";
import { PTAPlayerHandle } from "../PTAPlayerHandle";

export default function UserHome() {
  const navigate = useNavigate();
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Add your submission logic here
  };
  const theme = useTheme();
  const ref = useRef<PTAPlayerHandle | null>(null);
  const [comp, setComp] = useState(<></>);
  const [mode, setMode] = useState("none");
  useEffect(() => {
    if (mode === "spotify") setComp(<PTASpotifyPlayer ref={ref} />);
    else if (mode === "apple") setComp(<PTAAppleMusicPlayer ref={ref} />);
    else if (mode === "tidal") setComp(<PTATidalPlayer ref={ref} />);
    else if (mode === "yt") setComp(<PTAYTPlayer ref={ref} />);
    else if (mode === "none") {
      setComp(<></>);
      ref.current = null;
    }
  }, [mode]);
  useEffect(() => {
    const mod = Cookies.get("mode");
    if (mod === "spotify" && mode !== "spotify") setMode("spotify");
    else if (mod === "apple" && mode !== "apple") setMode("apple");
    else if (mod === "tidal" && mode !== "tidal") setMode("tidal");
    else if (mod === "yt" && mode !== "yt") setMode("yt");
    else if (mod === "none" && mode !== "none") setMode("none");
  }, [mode]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        padding: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          textAlign: "center",
          maxWidth: 500,
          width: "100%",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome!
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/about')}
          >
            About Pass The Aux
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/join-session')}
          >
            Join Session
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/create-session")}
          >
            Create Session
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/accountsettings")}
          >
            Account Management
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/friends")}
          >
            Manage Friends
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              navigate("/user-statistics");
            }}
          >
            Analytics
          </Button>

          <SpotifyLoginButton />
          <AppleMusicLoginButton />
          <TidalLoginButton />
          <YouTubeLoginButton />
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              Cookies.remove("userID");
              navigate("/new-home");
            }}
          >
            Log Out
          </Button>
          {/* Player Component */}
          {comp}
        </Box>
      </Box>
    </Box>
  );
}
