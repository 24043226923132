import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import exp from "constants";

interface UserObject {
  nickName?: string;
  // Add other user properties if needed
}

interface SessionData {
  guests: { [userId: string]: UserObject };
  // Add other session properties if needed
}

const genresList = [
  "Pop",
  "Hip-Hop",
  "Rock",
  "Jazz",
  "Classical",
  "Electronic",
  "Country",
  "R&B",
  "Reggae",
  "Blues",
];

interface SessionSettingsProps {
  onClose: () => void;
}

const SessionSettings: React.FC<SessionSettingsProps> = ({ onClose }) => {
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPublic, setIsPublic] = useState<string>("public");
  const [allowExplicit, setAllowExplicit] = useState<string>("true");
  const [mode, setMode] = useState<string>("AUTOMATIC");
  const [isStudyMode, setIsStudyMode] = useState<boolean>(false); // New state for Study Mode
  const [distanceUnit, setDistanceUnit] = useState<string>("miles");
  const [distanceRadius, setDistanceRadius] = useState<string>("");
  const [songWaitPeriod, setSongWaitPeriod] = useState<string>(""); // New field for waiting period
  const [sessionName, setSessionName] = useState<string>("");
  const navigate = useNavigate();
  // State variables for dialogs
  const [sessionTypeInfoOpen, setSessionTypeInfoOpen] = useState(false);
  const [genresInfoOpen, setGenresInfoOpen] = useState(false);
  const [sessionModeInfoOpen, setSessionModeInfoOpen] = useState(false);
  const [distanceRadiusInfoOpen, setDistanceRadiusInfoOpen] = useState(false);
  const [songWaitPeriodInfoOpen, setSongWaitPeriodInfoOpen] = useState(false);

  useEffect(() => {
    const sessionID = Cookies.get("sessionID");
    if (!sessionID) {
      setError("Session ID not found. Please create a session first.");
      return;
    }

    let pollingInterval: NodeJS.Timeout;

    const fetchSessionData = async () => {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/get-session",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              sessionID: sessionID,
              userID: Cookies.get("userID") || "",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched session data:", data);
          setSessionData(data);
          setSessionName(data.sessionName);
          setIsPublic(data.isPublic ? "public" : "private");
          setAllowExplicit(data.allowExplicit ? "true" : "false");
          setMode(data.mode || "AUTOMATIC");
          setIsStudyMode(data.isStudyMode);
          setSelectedGenres(data.allowedGenres || []);
          setDistanceUnit(data.distanceUnit || "miles");
          setDistanceRadius(
            data.distanceRadius ? data.distanceRadius.toString() : ""
          );
          setSongWaitPeriod(
            data.repeatSongWaitPeriod
              ? data.repeatSongWaitPeriod.toString()
              : "0"
          );
        } else {
          setError("Failed to fetch session data.");
        }
      } catch (error) {
        setError("An error occurred while fetching session data.");
      }
    };

    fetchSessionData();
  }, []);

  const getLocation = (): Promise<{
    latitude: number;
    longitude: number;
  } | null> => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) =>
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          (error) => {
            console.error("Error getting location:", error);
            resolve(null);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        resolve(null);
      }
    });
  };

  const handleUpdateSession = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);

    const isPublicBool = isPublic === "public";
    const allowExplicitBool = allowExplicit === "true";
    const userId = Cookies.get("userID") || "";
    const sessionID = Cookies.get("sessionID") || "";

    const location = isPublicBool ? await getLocation() : null;

    if (!userId || !sessionID) {
      setError("User ID or Session ID not found.");
      setIsLoading(false);
      return;
    }

    const requestBody = {
      hostUserId: userId,
      sessionName: sessionName,
      isPublic: isPublicBool,
      allowExplicit: allowExplicitBool,
      allowedGenres: selectedGenres,
      mode: mode,
      isStudyMode: mode === "AUTOMATIC" && isStudyMode,
      latitude: location?.latitude ?? null,
      longitude: location?.longitude ?? null,
      distanceUnit: isPublicBool ? distanceUnit : null,
      distanceRadius: isPublicBool ? parseFloat(distanceRadius) : null,
      repeatSongWaitPeriod: parseInt(songWaitPeriod, 10) || 0, // Add waiting period in minutes
    };

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/update-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        onClose();
      } else {
        setError("Failed to update session.");
      }
    } catch (error) {
      setError("An error occurred while updating the session.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveGuest = async (guestUserId: string) => {
    const sessionID = Cookies.get("sessionID") || "";

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/remove-guest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
            userID: guestUserId,
          },
        }
      );

      if (response.ok) {
        console.log(`Guest ${guestUserId} removed successfully.`);
        // Update the sessionData state to remove the guest from the map
        setSessionData((prevSessionData: any) => {
          const updatedGuests = { ...prevSessionData.guests };
          delete updatedGuests[guestUserId];
          return { ...prevSessionData, guests: updatedGuests };
        });
      } else {
        const errorText = await response.text();
        console.error("Failed to remove guest:", errorText);
        setError("Failed to remove guest.");
      }
    } catch (error) {
      console.error("Error removing guest:", error);
      setError("An error occurred while removing the guest.");
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!sessionData) {
    return <div>Loading session data...</div>;
  }

  // Get the host user ID
  const hostUserId = Cookies.get("userID") || "";

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        padding: 4,
        borderRadius: 2,
        maxWidth: 600,
        margin: "0 auto",
        position: "relative", // Added position relative for loading overlay
        zIndex: 1, // Ensure content is above any other elements
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <Box
            component="img"
            src="/loading.gif"
            alt="Loading..."
            sx={{ width: 80, height: 80 }}
          />
        </Box>
      )}
      <Typography variant="h4" component="h1" gutterBottom>
        Edit Session
      </Typography>
      <form onSubmit={handleUpdateSession}>
        <TextField
          label="Session Name"
          type="text"
          id="sessionName"
          value={sessionName}
          onChange={(e) => setSessionName(e.target.value)}
          placeholder="Enter session name"
          required
          fullWidth
          margin="normal"
        />

        {/* Session Type with Info Icon */}
        <Box display="flex" alignItems="center">
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Session Type</InputLabel>
            <Select
              id="isPublic"
              name="isPublic"
              value={isPublic}
              onChange={(e) => setIsPublic(e.target.value)}
              label="Session Type"
            >
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
          <IconButton
            size="small"
            onClick={() => setSessionTypeInfoOpen(true)}
            aria-label="Session Type Info"
            sx={{ mt: 2 }}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>

        <FormControl fullWidth margin="normal" required>
          <InputLabel>Allow Explicit Content</InputLabel>
          <Select
            id="allowExplicit"
            name="allowExplicit"
            value={allowExplicit}
            onChange={(e) => setAllowExplicit(e.target.value)}
            label="Allow Explicit Content"
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>

        {/* Allowed Genres with Info Icon */}
        <FormControl component="fieldset" margin="normal">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1">Allowed Genres:</Typography>
            <IconButton
              size="small"
              onClick={() => setGenresInfoOpen(true)}
              aria-label="Allowed Genres Info"
              sx={{ ml: 1 }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
          <FormGroup>
            {genresList.map((genre) => (
              <FormControlLabel
                key={genre}
                control={
                  <Checkbox
                    id={genre}
                    name="allowedGenres"
                    value={genre}
                    checked={selectedGenres.includes(genre)}
                    onChange={(e) =>
                      setSelectedGenres(
                        e.target.checked
                          ? [...selectedGenres, genre]
                          : selectedGenres.filter((g) => g !== genre)
                      )
                    }
                  />
                }
                label={genre}
              />
            ))}
          </FormGroup>
        </FormControl>

        {mode === "AUTOMATIC" && (
          <div className="form-group">
            <div className="study-mode-checkbox">
              <input
                type="checkbox"
                id="studyMode"
                name="studyMode"
                checked={isStudyMode}
                onChange={(e) => setIsStudyMode(e.target.checked)}
              />
              <label htmlFor="studyMode">
                Study Mode (Automatically adjusts volume during conversations)
              </label>
            </div>
          </div>
        )}

        {isPublic === "public" && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Distance Unit</InputLabel>
              <Select
                id="distanceUnit"
                value={distanceUnit}
                onChange={(e) => setDistanceUnit(e.target.value)}
                label="Distance Unit"
              >
                <MenuItem value="ft">Feet</MenuItem>
                <MenuItem value="yards">Yards</MenuItem>
                <MenuItem value="miles">Miles</MenuItem>
              </Select>
            </FormControl>

            {/* Distance Radius with Info Icon */}
            <Box display="flex" alignItems="center">
              <TextField
                label="Distance Radius"
                type="number"
                id="distanceRadius"
                value={distanceRadius}
                onChange={(e) => setDistanceRadius(e.target.value)}
                placeholder="Enter distance"
                required
                fullWidth
                margin="normal"
                inputProps={{ min: 0 }}
              />
              <IconButton
                size="small"
                onClick={() => setDistanceRadiusInfoOpen(true)}
                aria-label="Distance Radius Info"
                sx={{ mt: 2 }}
              >
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </>
        )}

        {/* Song Wait Period with Info Icon */}
        <Box display="flex" alignItems="center">
          <TextField
            label="Wait Period for Repeat Songs (Minutes)"
            type="number"
            id="songWaitPeriod"
            value={songWaitPeriod}
            onChange={(e) => setSongWaitPeriod(e.target.value)}
            placeholder="Enter minutes"
            fullWidth
            margin="normal"
            inputProps={{ min: 0 }}
          />
          <IconButton
            size="small"
            onClick={() => setSongWaitPeriodInfoOpen(true)}
            aria-label="Song Wait Period Info"
            sx={{ mt: 2 }}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          fullWidth
          sx={{ mt: 2 }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Session"}
        </Button>
      </form>

      <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
        Remove Guests
      </Typography>
      {sessionData &&
      sessionData.guests &&
      Object.keys(sessionData.guests).length > 0 ? (
        <List>
          {Object.entries(sessionData.guests).map(([userId, userObject]) => {
            if (userId === hostUserId) {
              // Skip the host
              return null;
            }
            return (
              <ListItem
                key={userId}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="remove"
                    onClick={() => handleRemoveGuest(userId)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={userObject.nickName || userId} />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography variant="body1">No guests in the session.</Typography>
      )}

      {/* Dialogs for Info Icons */}
      {/* Session Type Info Dialog */}
      <Dialog
        open={sessionTypeInfoOpen}
        onClose={() => setSessionTypeInfoOpen(false)}
        aria-labelledby="session-type-info-dialog-title"
      >
        <DialogTitle id="session-type-info-dialog-title">
          Session Type Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Private sessions can only be joined through invite via code or link,
            but public sessions can be joined by anyone within a distance radius
            that you set or anyone that is friends with someone else in the
            session.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSessionTypeInfoOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Allowed Genres Info Dialog */}
      <Dialog
        open={genresInfoOpen}
        onClose={() => setGenresInfoOpen(false)}
        aria-labelledby="genres-info-dialog-title"
      >
        <DialogTitle id="genres-info-dialog-title">
          Allowed Genres Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The host can restrict which genres of songs session guests can see
            when searching and recommending songs. If no genres are selected,
            all genres will be allowed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGenresInfoOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Distance Radius Info Dialog */}
      <Dialog
        open={distanceRadiusInfoOpen}
        onClose={() => setDistanceRadiusInfoOpen(false)}
        aria-labelledby="distance-radius-info-dialog-title"
      >
        <DialogTitle id="distance-radius-info-dialog-title">
          Distance Radius Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Since the session is public, the distance radius and unit will
            determine how far someone can be from the current location to still
            join the session.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDistanceRadiusInfoOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Song Wait Period Info Dialog */}
      <Dialog
        open={songWaitPeriodInfoOpen}
        onClose={() => setSongWaitPeriodInfoOpen(false)}
        aria-labelledby="song-wait-period-info-dialog-title"
      >
        <DialogTitle id="song-wait-period-info-dialog-title">
          Song Wait Period Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Guests will have to wait this many minutes to recommend a song that
            has already been played.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSongWaitPeriodInfoOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SessionSettings;
