import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  MutableRefObject,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DroppableProvided,
} from "react-beautiful-dnd";
import Cookies from "js-cookie";
import { PTASong, NOT_PLAYING_SONG } from "../PTASong";
import { SongStats, SongStatsHandle } from "./SongStats";
import { PTAPlayerHandle } from "../PTAPlayerHandle";
import {
  PlaybackMode,
  PlaybackState,
  updatePlaybackState,
} from "../PlaybackState";
import { CurrentSongHandle } from "./CurrentSong";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Icon,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import LyricsIcon from "@mui/icons-material/Lyrics";

type AlertSeverity = "error" | "info" | "success" | "warning";

export type SongAnalytics = {
  recommender: string;
  voteCount: number;
  recTime: string;
  upVoters: string[];
  downVoters: string[];
  songTitle: string;
  songGenre: string;
};

export type Vote = {
  user: {
    nickName: string;
  };
  voteType: "UPVOTE" | "DOWNVOTE";
  timestamp: string;
  song: PTASong;
};
interface QueueItem {
  song: PTASong;
  voteCount: number;
  vote: Vote[];
  songAnalytics: SongAnalytics;
}

interface User {
  id: string;
  name: string;
  voteCount: number;
  isPopular: boolean;
}

export type QueueHandle = {
  fetchSession: () => void;
};

const Queue = forwardRef<
  QueueHandle,
  {
    player: React.MutableRefObject<PTAPlayerHandle | null>;
    currentSongHandle: CurrentSongHandle;
    viewLyrics: string;
  }
>((props, ref) => {
  const { player, currentSongHandle, viewLyrics, ...otherProps } = props;
  //console.log("viewLYRICS IN QUEUE: ", viewLyrics);
  const [items, setItems] = useState<QueueItem[]>([]);
  const [userPopularityMap, setUserPopularityMap] = useState<{
    [key: string]: { isPopular: boolean; voteCount: number };
  }>({}); //idk yettt
  const [hasMore, setHasMore] = useState(true);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isUserMapLoading, setIsUserMapLoading] = useState(true);
  const [buttonColors, setButtonColors] = useState<{ [key: string]: number }>(
    {}
  );
  const autoRecMode = useRef(false);
  const [autoRecModeState, setAutoRecModeState] = useState(false);
  const [openLyricsModal, setOpenLyricsModal] = useState<string | null>(null);
  const [isAutoQueueEnabled, setIsAutoQueueEnabled] = useState(false); //for the autoqueue
  const [selectedSong, setSelectedSong] = useState<PTASong | null>(null);
  const [lyrics, setLyrics] = useState<string | null>(null);
  const [lyricsHtml, setLyricsHtml] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertSeverity>("success");

  useEffect(() => {
    autoRecMode.current = autoRecModeState;
  }, [autoRecModeState]);
  const songStatsRefMap = useRef(
    new Map<string, React.RefObject<SongStatsHandle>>()
  );
  // Ensures each song has a unique ref
  items.forEach((item) => {
    if (!songStatsRefMap.current.has(item.song.songId)) {
      songStatsRefMap.current.set(
        item.song.songId,
        React.createRef<SongStatsHandle>()
      );
    }
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message: string, severity: AlertSeverity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleAddSong = useCallback(async (song: PTASong) => {
    try {
      const sessionId = Cookies.get("sessionID"); // Assuming cookies store session ID
      const userId = Cookies.get("userID");

      const requestBody = {
        sessionId: sessionId,
        userId: userId,
        songId: song.songId,
        title: song.title,
        artist: song.artist,
        genre: song.genre,
        url: song.url,
        albumArtURL: song.albumArtURL,
        durationMs: song.durationMs,
      };

      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/add-song",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        //console.log("Song added successfully!");
        // ADD HERE
        // Optionally, fetch the updated queue or trigger a re-render
      } else {
        showSnackbar("Song already in Queue", "warning");
        console.error("Failed to add song", await response.text());
      }
    } catch (error) {
      console.error("Error during song addition:", error);
    }
  }, []);

  // Fetch session data every 5 seconds
  useEffect(() => {
    // Only fetch the queue every 5 seconds

    const intervalId = setInterval(() => {
      fetchSession(); // Periodically update the queue
    }, 1000); // 1 seconds interval

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []); // This effect will only run once (when the component mounts)

  useEffect(() => {
    // Check host status only once when the component mounts
    (async () => {
      const isHost = await handleIsHost();
      //setIsHost(isHost); // Store the result in the state
      fetchSession(); // Fetch the session after checking host status
    })();
  }, []); // This effect will also run once (when the component mounts)
  const isHostRef = useRef(false);
  const mutex = useRef(false);

  const fetchSession = useCallback(async () => {
    try {
      const sessionId = Cookies.get("sessionID");
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/get-session",
        {
          headers: {
            sessionID: sessionId || "",
            userID: Cookies.get("userID") || "",
          },
        }
      );
      if (response.ok) {
        const sessionData = await response.json();
        const userId = Cookies.get("userID");
        if (!sessionData.guests || !userId || !sessionData.guests[userId]) {
          console.warn(
            "User ID not found in guests list. Redirecting to exit page."
          );
          Cookies.remove("sessionID");
          navigate("/exit-session");
          return;
        }
        if (sessionData.songQueue && sessionData.songQueue.queue) {
          const newItems: QueueItem[] = sessionData.songQueue.queue;
          if (sessionData.mode == "AUTOMATIC") {
            if (
              player.current !== null &&
              isHostRef.current &&
              currentSongHandle !== null &&
              currentSongHandle.playbackState.current.playing ==
                PlaybackMode.STOP &&
              !(await player.current?.isPlaying())
            ) {
              if (newItems.length === 0 && autoRecMode.current) {
                fetch(import.meta.env.VITE_API_BASE_URL + "/api/search?query=")
                  .then((res) => res.json())
                  .then(async (val: PTASong[]) => {
                    if (val.length > 0) {
                      const idx: number = Math.floor(
                        Math.random() * val.length
                      );
                      console.info(
                        "QUEUE: Recomending " +
                          val[idx].artist +
                          " - " +
                          val[idx].title
                      );
                      console.debug(currentSongHandle.playbackState.current);
                      await handleAddSong(val[idx]);
                    }
                  });
              }
            }
          } else {
            if (
              currentSongHandle !== null &&
              currentSongHandle.playbackState.current.playing ==
                PlaybackMode.STOP
            ) {
              if (newItems.length > 0) {
                /*if (sessionId != null) {
                  updatePlaybackState({
                    sessionID: sessionId,
                    state: {
                      current: newItems[0].song,
                      currentTimeMillis: 0,
                      playing: PlaybackMode.PLAY,
                    },
                  });
                  console.error("Updating");
                  await handleRemoveSong(newItems[0].song.songId);
                } else {
                  console.log("Session id is null");
                }*/
              } else {
                if (sessionId != null) {
                  updatePlaybackState({
                    sessionID: sessionId,
                    state: {
                      current: NOT_PLAYING_SONG,
                      currentTimeMillis: 0,
                      playing: PlaybackMode.STOP,
                    },
                  });
                  console.error("Updating with null song");
                } else {
                  console.log("Session id is null");
                }
              }
            }
          }
          setItems(newItems);
          setHasMore(newItems.length > 0);
        }
        return;
      } else {
        console.error("Failed to fetch queue");
        navigate("/exit-session");
        return;
      }
    } catch (error) {
      console.error("Error fetching queue:", error);
      navigate("/exit-session");
      return;
    }
  }, [currentSongHandle]);

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || !isHost) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      try {
        const sessionId = Cookies.get("sessionID");
        const userId = Cookies.get("userID");
        console.log("trying to update position");

        // Create request body
        const requestBody = {
          sessionId: sessionId,
          userId: userId,
          songId: result.draggableId,
          newPosition: destinationIndex,
        };

        // Send POST request to the backend to update the position
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/update-song-position",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          // After successful update, fetch the updated session
          await fetchSession();
          return;
        } else {
          console.error("Failed to update position", await response.text());
          return;
        }
      } catch (error) {
        console.error("Error during position update:", error);
        return;
      }
    },
    [isHost, fetchSession]
  );

  useEffect(() => {
    const fetchUsersInterval = setInterval(() => {
      fetchSessionUsers(); // Fetch session users periodically
    }, 5000);
    return () => clearInterval(fetchUsersInterval);
  }, []);

  const fetchSessionUsers = async () => {
    const sessionID = Cookies.get("sessionID");
    if (!sessionID) {
      console.error("Session ID not found in cookies.");
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/session/users",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
          },
        }
      );

      if (response.ok) {
        const users: User[] = await response.json();
        const popularityMap = users.reduce<{
          [key: string]: { isPopular: boolean; voteCount: number };
        }>((map, user) => {
          map[user.name] = {
            isPopular: user.isPopular,
            voteCount: user.voteCount,
          };
          return map;
        }, {});
        setUserPopularityMap(popularityMap);
        // console.log("Updated userPopularityMap:", popularityMap);
      } else {
        console.error("Failed to fetch session users:", await response.text());
      }
    } catch (error) {
      console.error("Error fetching session users:", error);
    }
  };

  useEffect(() => {
    fetchSessionUsers();
    console.log("Fetching session users...");
  }, []);
  // const fetchSessionWrapper = useCallback(async () => {
  //   setTimeout(() => {
  //     fetchSession().finally(() => {
  //       fetchSessionWrapper();
  //       console.warn("STARTING NEW FETCH");
  //     })
  //   }, 1000);
  // }, [fetchSession]);

  // Fetch session data every 5 seconds
  useEffect(() => {
    // Only fetch the queue every 5 seconds

    // fetchSessionWrapper();
    const intervalId = setInterval(async () => {
      await fetchSession(); // Periodically update the queue
    }, 1000); // 5 seconds interval

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [fetchSession]); // This effect will only run once (when the component mounts)

  useEffect(() => {
    // Check host status only once when the component mounts
    (async () => {
      await handleIsHost();
    })();
  }, []); // This effect will also run once (when the component mounts)
  const useScript = (url: string, onLoad: () => void) => {
    useEffect(() => {
      const script = document.createElement("script");

      script.src = url;
      script.async = true;

      // When the script is loaded, invoke the callback
      script.onload = () => {
        onLoad();
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url, onLoad]);
  };

  const handleOpenLyrics = async (song: PTASong) => {
    setSelectedSong(song);
    setOpenLyricsModal(song.songId);
    setLoading(true);
    setLyrics(null);
    setLyricsHtml(null);
    setError(null);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL +
          "/api/embed-lyrics?" +
          "song=" +
          `${song.title}` +
          "&artist=" +
          `${song.artist}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch lyrics");
      }

      const data = await response.text();
      setLyricsHtml(data);
    } catch (error) {
      setError("Failed to load lyrics. Please try another song.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseLyrics = () => {
    setOpenLyricsModal(null);
    setSelectedSong(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleVote = useCallback(
    async (songId: string, voteType: "upvote" | "downvote") => {
      try {
        const sessionId = Cookies.get("sessionID");
        const userId = Cookies.get("userID");

        // Create request body as an object
        const requestBody = {
          sessionId: sessionId,
          userId: userId,
          songId: songId,
          voteType: voteType,
        };

        // Send POST request to the backend
        const response = await fetch(
          import.meta.env.VITE_API_BASE_URL + "/api/vote",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const { color } = await response.json();
          console.log("COLOR:", color);

          // Update the color state for the specific song
          setButtonColors((prevColors) => ({
            ...prevColors,
            [songId]: color,
          }));

          await fetchSession(); // Refresh the session if needed
          return color;
        } else {
          console.error("Failed to cast vote", await response.text()); // Log response body for debugging
        }
      } catch (error) {
        console.error("Error during voting:", error);
      }
    },
    []
  );

  const handleIsHost = useCallback(async () => {
    try {
      const sessionId = Cookies.get("sessionID");
      const userId = Cookies.get("userID");
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/isHostView",
        {
          method: "POST", // Add this line to use POST method
          headers: {
            sessionID: sessionId || "",
            userID: userId || "",
          },
        }
      );
      if (response.ok) {
        const h = (await response.text()) === "true";
        setIsHost(h);
        isHostRef.current = h;
      } else {
        console.error("Failed to check host status");
      }
    } catch (error) {
      console.error("Error checking host status:", error);
    }
  }, []);

  const handleRemoveSong = useCallback(async (songId: string) => {
    try {
      const sessionId = Cookies.get("sessionID");
      const userId = Cookies.get("userID");

      // Create request body
      const requestBody = {
        sessionId: sessionId,
        userId: userId,
        songId: songId,
      };

      // Send POST request to the backend to remove the song
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/remove-song",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // After successful removal, fetch the updated session
        /* await fetchSession(); */
        return;
      } else {
        console.error("Failed to remove song", await response.text()); // Log response for debugging
        return;
      }
    } catch (error) {
      console.error("Error during song removal:", error);
      return;
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      fetchSession,
    };
  });

  const recommendSong = useCallback(() => {
    fetch(import.meta.env.VITE_API_BASE_URL + "/api/search?query=")
      .then((res) => res.json())
      .then(async (val: PTASong[]) => {
        if (val.length > 0) {
          const song = val[Math.floor(Math.random() * val.length)];
          console.info(
            "QUEUE: Recomending " + song.artist + " - " + song.title
          );
          try {
            const sessionId = Cookies.get("sessionID"); // Assuming cookies store session ID
            const userId = "c9e4aa80-8415-4a6a-88df-ae5efc93c638";

            const requestBody = {
              sessionId: sessionId,
              userId: userId,
              songId: song.songId,
              title: song.title,
              artist: song.artist,
              genre: song.genre,
              url: song.url,
              albumArtURL: song.albumArtURL,
              durationMs: song.durationMs,
            };

            const response = await fetch(
              import.meta.env.VITE_API_BASE_URL + "/api/add-song",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
              }
            );

            if (response.ok) {
              //console.log("Song added successfully!");
            } else {
              showSnackbar("AutoQueued song is already in Queue", "warning");
              console.error("Failed to add song", await response.text());
            }
          } catch (error) {
            console.error("Error during song addition:", error);
          }
        }
      });
  }, []);

  const navigate = useNavigate();
  //handleautoqueue toggle button
  const handleAutoQueueToggle = () => {
    setIsAutoQueueEnabled((prev) => !prev);
    if (!isAutoQueueEnabled) {
      recommendSong();
    }
  };

  const handleLeaveSession = useCallback(async () => {
    const sessionID = Cookies.get("sessionID");
    const userID = Cookies.get("userID");

    if (!sessionID || !userID) {
      console.error("Session ID or User ID not found in cookies.");
      navigate("/"); // Redirect to home or appropriate page
      return;
    }

    try {
      const response = await fetch(
        import.meta.env.VITE_API_BASE_URL + "/api/remove-guest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            sessionID: sessionID,
            userID: userID,
          },
        }
      );

      if (response.ok) {
        console.log(`User ${userID} left the session successfully.`);
        // Optionally, clear session-related cookies
        player.current?.pause();
        Cookies.remove("sessionID");
        // Redirect to exit landing page
        navigate("/exit-session");
      } else {
        const errorText = await response.text();
        console.error("Failed to leave session:", errorText);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error leaving session:", error);
      // Handle error as needed
    }
  }, [navigate]);
  {
    // console.log("userPopularityMap:", userPopularityMap);
  }
  {
    /* Log the popularity map */
  }
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Queue of Songs
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAutoRecModeState(!autoRecModeState)}
        >
          Toggle Auto Play
        </Button>
        <FormControlLabel
          control={
            <Switch
              checked={isAutoQueueEnabled}
              onChange={handleAutoQueueToggle}
              color="primary"
            />
          }
          label="Auto Queue "
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLeaveSession}
        >
          Leave Session
        </Button>
      </Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="queue">
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <InfiniteScroll
                dataLength={items.length}
                next={fetchSession}
                hasMore={hasMore}
                loader={<Box sx={{ textAlign: "center", mt: 2 }}></Box>}
                endMessage={
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    <b>Yay! You have seen it all! You should add a song!</b>
                  </Typography>
                }
              >
                {items.map((item, index) => {
                  const songStatsRef = songStatsRefMap.current.get(
                    item.song.songId
                  );
                  const recommender = item.songAnalytics.recommender;
                  const isRecommenderPopular =
                    userPopularityMap[recommender] || false;
                  // console.log(
                  //   `Recommender: ${recommender}, Popular: ${isRecommenderPopular}` // Log each recommender's status
                  // );

                  //console.log(item.song.albumArtURL)
                  return (
                    <Draggable
                      key={item.song.songId}
                      draggableId={item.song.songId}
                      index={index}
                      isDragDisabled={!isHost}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Card
                            key={item.song.songId}
                            sx={{
                              mb: 2,
                              opacity: snapshot.isDragging ? 0.6 : 1,
                              transform: snapshot.isDragging
                                ? "scale(1.02)"
                                : "scale(1)",
                              transition: "all 0.2s",
                            }}
                          >
                            <CardContent>
                              <Typography
                                variant="subtitle1"
                                sx={{ textAlign: "left" }}
                              >
                                <b>
                                  REC: {recommender}{" "}
                                  {userPopularityMap[recommender]
                                    ?.isPopular && (
                                    <Tooltip
                                      title={`Total Votes: ${
                                        userPopularityMap[recommender]
                                          ?.voteCount || 0
                                      }`}
                                      arrow
                                    >
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                          color: "gold",
                                          fontSize: "1.2em",
                                          cursor: "pointer",
                                        }}
                                      >
                                        ⭐
                                      </span>
                                    </Tooltip>
                                  )}
                                </b>
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="h6" component="h3">
                                  {item.song.title}
                                </Typography>

                                <Tooltip title="Song Details" placement="top">
                                  <IconButton
                                    onClick={() => {
                                      songStatsRef?.current?.handleOpenModal();
                                    }}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Typography variant="body1">
                                Artist: {item.song.artist}
                              </Typography>
                              <Typography variant="body2">
                                Votes:{" "}
                                {item.vote &&
                                  item.vote.slice(0, 3).map((voter, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        color:
                                          voter.voteType === "UPVOTE"
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {voter.user.nickName}
                                      {index <
                                        item.vote.slice(0, 3).length - 1 &&
                                        ", "}
                                    </span>
                                  ))}
                                {item.vote && item.vote.length > 3 && "..."}
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Tooltip title="Like Song" placement="top">
                                <IconButton
                                  color={
                                    buttonColors[item.song.songId] === 1
                                      ? "success"
                                      : "default"
                                  }
                                  onClick={() =>
                                    handleVote(item.song.songId, "upvote")
                                  }
                                >
                                  <ThumbUpIcon />
                                </IconButton>
                              </Tooltip>

                              <Typography variant="body1">
                                {item.voteCount}
                              </Typography>

                              <Tooltip title="Dislike Song" placement="top">
                                <IconButton
                                  color={
                                    buttonColors[item.song.songId] === -1
                                      ? "error"
                                      : "default"
                                  }
                                  onClick={() =>
                                    handleVote(item.song.songId, "downvote")
                                  }
                                >
                                  <ThumbDownIcon />
                                </IconButton>
                              </Tooltip>

                              {isHost && (
                                <Tooltip title="Remove Song" placement="top">
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveSong(item.song.songId)
                                    }
                                    color="error"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </CardActions>

                            <Dialog
                              open={openLyricsModal === item.song.songId}
                              onClose={() => setOpenLyricsModal(null)}
                              fullWidth
                              maxWidth="lg"
                            >
                              <DialogTitle>
                                {item.song.title} Lyrics
                              </DialogTitle>
                              <DialogContent>
                                {loading && (
                                  <Typography variant="body1">
                                    Loading lyrics...
                                  </Typography>
                                )}

                                {error && (
                                  <Typography variant="body1" color="error">
                                    {error}
                                  </Typography>
                                )}

                                {lyricsHtml && (
                                  <iframe
                                    srcDoc={`
                        <html>
                          <head>
                            <style>
                              @font-face {
                                font-family: 'YourCustomFont';
                                src: url('../fonts/circular-std-med.ttf') format('truetype');
                              }
                              body {
                                font-family: 'YourCustomFont', sans-serif;
                                margin: 0;
                                padding: 0;
                              }
                            </style>
                          </head>
                          <body>${lyricsHtml}</body>
                        </html>
                      `}
                                    style={{
                                      width: "100%",
                                      height: "500px",
                                      border: "none",
                                    }}
                                    title={`${item.song.title} Lyrics`}
                                  />
                                )}

                                {!loading && !error && !lyricsHtml && (
                                  <Typography variant="body1">
                                    Sorry, lyrics for "{item.song.title}" are
                                    unavailable right now...
                                  </Typography>
                                )}
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setOpenLyricsModal(null)}
                                  color="primary"
                                >
                                  Close
                                </Button>
                              </DialogActions>
                            </Dialog>
                            <SongStats
                              ref={songStatsRef}
                              songAnalytics={item.songAnalytics}
                            />
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </InfiniteScroll>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Queue;
